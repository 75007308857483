import React from "react";
import {StripeProductT} from "@business/products/useStripeProducts";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import {AddCircle} from "@mui/icons-material";
import styled from "styled-components/macro";

export function priceToShow(amount: number) {
  return '$ ' + Number(amount/100.0).toLocaleString(undefined, {minimumFractionDigits: 2});
}

export function getPriceType(orgType: string, product: StripeProductT) {
  // if there are multiple prices - then academic is valid if listed -- otherwise need to use default;
  let priceType = 'default';
  if (orgType === 'academic') {
    if (Object.hasOwn(product.prices, 'academic')) {
      priceType = 'academic';
    }
  }
  return priceType;
}

export function getPrice(product: StripeProductT, priceType: string) {
  if (Object.hasOwn(product.prices, priceType)) {
    return product.prices[priceType];
  }
  return null;
}


export function getShowNameDescription(product: StripeProductT): React.ReactNode {
  return (
      <NameDescription>
        <Title>{product.name}</Title>
        <Description>{product.description}</Description>
      </NameDescription>
  );
}

export function isAnyPriceDiscount(orgType: string, product: StripeProductT) {
  const priceType = getPriceType(orgType, product);
  const availableDiscountPrices = Object.keys(product.prices)
    .filter((v) => v.includes('multi') && v.includes(priceType));
  return availableDiscountPrices.length > 0;
}

export function getDiscountDetails(orgType: string, product: StripeProductT): string {
  const priceType = getPriceType(orgType, product);
  let availableDiscountPrices = Object.keys(product.prices).filter((v) => v.includes('multi') && v.includes(priceType));
  if (availableDiscountPrices.length > 1) {
    console.error('Multiple discount prices - only using first', availableDiscountPrices);
  }
  const price = product.prices[availableDiscountPrices[0]];
  const showPrice = priceToShow(price.unit_amount);
  const threshold = Number(availableDiscountPrices[0].split('_')[3]) + 1;
  return `Order ${threshold} or more compounds ordered at the same time receive a discounted price of - ${showPrice}/compound`;
}

export function getShowPrice(orgType: string, product: StripeProductT) {
  const price = getPrice(product, getPriceType(orgType, product));
  if (price === null) return <RowText></RowText>;
  let value = priceToShow(price.unit_amount);
  if (isAnyPriceDiscount(orgType, product)) {
    return <RowText>{ value } <HoverInfoText text={getDiscountDetails(orgType, product)} variant={'cart'}/></RowText>;
  }
  return <RowText>{value}</RowText>;
}

export function getMinOrderPrice(orgType: string, product: StripeProductT) {
  const price = getPrice(product, getPriceType(orgType, product));
  if (price === null) return '';
  return priceToShow(Number(price.unit_amount) * Number(product?.metadata.unitOrderMinSize));
}

export function getShowUnit(product: StripeProductT) {
  if (Number(product.metadata.unitOrderMinSize) === 1) return product.metadata.unitSize;
  return(
    <Column>
      <RowText>{product.metadata.unitSize}</RowText>
      <MinUnitText>Minimum Order: {product.metadata.unitOrderMinSize}</MinUnitText>
    </Column>
  )
}

export function getAddToCart(orgType: string, product: StripeProductT, onSelect: (product: StripeProductT, priceType: string) => void) {
  const priceType = getPriceType(orgType, product);
  return(
    <Link onClick={()=>onSelect(product, priceType)}>
      <AddCircle />
    </Link>
  )
}


const Link = styled.span`
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentPrimary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const Description = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const RowText = styled.div`
  display: flex;
`;

const MinUnitText = styled(RowText)`
  color: ${(p) => p.theme.palette.accentSecondary};
  white-space: nowrap;
  font-style: italic;
`;