import React from 'react';
import styled from 'styled-components/macro';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import BasicTable from '@as_core/tables/BasicTable';

const overviewTableFields: BasicTableFieldsT[] = [
  { value: 'source', label: 'Source', type: 'string', width: 150 },
  { value: 'compound_count', label: '# Compounds', type: 'number', width: 150 }
];

const databaseFields: {[key: string]: string | number}[] = [
  { 'source': 'Cayman Chemicals', 'compound_count': 500 },
  { 'source': 'Chemspace', 'compound_count': 500 },
]

const debug = false;
const Overview = () => {
  debug && console.log('Overview');

  return (
    <Container>
      <BasicTable
        fields={overviewTableFields}
        rows={databaseFields}
        pillRows={false}
      />
    </Container>
  );
};

export default Overview;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

