import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const BiomimeticsIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorGray = 'gray';
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse stroke={color} strokeWidth={10} strokeMiterlimit={10} cx="303" cy="438.3" rx="7.3" ry="20.2" />
      <path fill={colorGray} opacity={0.2} d="M148.4,278.3l73.4,133.3l34.2-3.7l73.5-139.4c0,0-50.6,21.8-90.2,19.5
      S148.4,278.3,148.4,278.3z" />
      <path fill={colorGray} opacity={0.4} d="M238.4,120.7c0,0-108.4,5-113.1,28.6
      s23.1,129,23.1,129s50.7,11.8,90.9,9.8c40.2-2,90.2-15.5,90.2-15.5s25.6-93.8,24.5-115.7S272,119.6,238.4,120.7z" />
      <ellipse stroke={color} strokeWidth={10} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} cx="238.4" cy="156.8" rx="115.6" ry="36.2" />
      <path stroke={color} strokeWidth={10} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M329.5,268.5c0,10.8-40.4,19.5-90.2,19.5s-84.7-4.6-90.9-14.4" />
      <path stroke={colorHighlight} strokeWidth={10} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M266.8,33.1L265.2,71c0,0,98.4,18.9,89.4,111.8s-96.8,223.8-96.8,223.8l-36,5c0,0-91.6-136.9-101.2-238.7
      c-7.4-78.6,80.2-102.3,80.2-102.3l-2.7-37.4" />
      <ellipse stroke={colorHighlight} strokeWidth={10} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} cx="232.7" cy="33.9" rx="34.6" ry="12.1" />
      <path stroke={color} strokeWidth={10} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M203.7,414.1l65.3-9c0,0,6,6.1,6.3,28.6c0.3,21.1,0.1,33.9-4.6,33.4c-4.4-0.4-66.9-11.9-66.9-11.9L203.7,414.1
      z" />
      <path stroke={color} strokeWidth={10} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10}
            d="M267.2,407.9c0,0-4.9,8.1-4.9,28.2c0,20,8.5,31,8.5,31s5.5-11.5,12.3-12.9c6.9-1.4,12.8-1.5,12.8-1.5" />
      <polyline stroke={color} strokeWidth={10} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} points="222.8,458.6 221.8,496.4 242.7,483.1 242.7,462.2 	" />
      <path stroke={color} strokeWidth={10} strokeMiterlimit={10} d="M269.1,405c0,0,4.8,16.2,12,17c8.3,0.9,16.2,0.6,16.2,0.6" />
    </svg>
  )
}

export default BiomimeticsIcon;