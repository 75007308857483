import React, { useContext } from 'react';
import { viewConfig } from '../view/views.config';
import { TextRegular, TextSmallItalics } from '@as_core/elements/textStyles';
import {
  FlexBanner,
  FlexBannerGroup,
  FlexSpacer,
} from '@as_core/elements/flexStyles';

import MenuButton from '@components/elements/MenuButton';
import StarIcon from '@components/icons/star.icon';
import EditIcon from '@components/icons/edit.icon';
import PinIcon from '@components/icons/pin.icon';
import FilterIcon from '@components/icons/filter.icon';
import CompareIcon from '@components/icons/compare.icon';
import NewCompoundIcon from '@components/icons/newCompound.icon';
import Cart2Icon from '@components/icons/cart2.icon';
import TrashIcon from '@components/icons/trash.icon';
import LegendCellHealth from './LegendCellHealth';
import LegendZebrafish from './LegendZebrafish';
import LegendDefault from './LegendDefault';
import GraphIcon from '@components/icons/graph.icon';

import { useNavigate } from 'react-router-dom';
import { UserContext } from '@stores/UserContext';
import { RootState } from '@src/store';
import { useSelector, useDispatch } from 'react-redux';
import { getCompoundViews } from '@stores/compoundViews';
import useCognito from '@as_core/account/useCognito';
import { getUserSubscriptionType } from '@utils/useUserAppInfo';
import SearchFieldAutocomplete from '@as_core/components/inputs/SearchFieldAutocomplete';
import FilterDropdown from '@as_core/elements/FilterDropdown';
import { CompoundT } from '@src/type'
import ColumnsIcon from '@as_core/icons/columns.icon';

const debug = false;

interface CompoundBannerProps {
  view: string;
  library: string;
  configId: string;
  searchTerm: string;
  handleSearchChange: (val: string) => void;
  searchResults: string[];
  handleSearchSelect: (val: string) => void;
  handlePinSelect: () => void;
  handleAddToList: () => void;
  handleFilterModal: () => void;
  filterActive: boolean;
  handleSettings: () => void;
  libraryId: string;
  isFavorite: boolean;
  favoriteName: string;
  handleDeleteCompounds: () => void;
  loadingText?: string;
  setData: (data: Array<CompoundT>) => void;
  setTotalCompounds: (val: number) => void;
  setLoadedAll: (val: boolean) => void;
  setLoading: (val: boolean) => void;
  setNoLoadMore: (val: boolean) => void;

}

const CompoundBanner = ({
  view,
  library,
  configId,
  searchTerm,
  handleSearchChange,
  searchResults,
  handleSearchSelect,
  handlePinSelect,
  handleAddToList,
  handleFilterModal,
  filterActive,
  handleSettings,
  libraryId,
  isFavorite,
  favoriteName,
  handleDeleteCompounds,
  loadingText = '',
  setData,
  setTotalCompounds,
  setLoadedAll,
  setLoading,
  setNoLoadMore,
}: CompoundBannerProps) => {
  // show parameter values
  debug && console.log('Compound Banner {view}', view);
  debug && console.log('Compound Banner {configId}', configId);
  debug && console.log('Compound Banner {filterActive}', filterActive);
  const { user } = useContext(UserContext);
  const subscriptionLevel = getUserSubscriptionType(user.appInfo);
  const navigate = useNavigate();
  const { getToken } = useCognito();
  const dispatch = useDispatch();

  const compoundViews = useSelector((state: RootState) => state.compoundViews);
  const fieldOptions = Object.values(compoundViews.views).map(({ uuid, title }) => ({ uuid, title }))

  const handleCart = () => {
    if (debug) console.log('handleCart');
  };

  const handleNewCompound = () => {
    navigate('/compounds/user/add');
  };
  const handleCreatePlot = async () => {
    const token = getToken();
    dispatch(getCompoundViews(token));
    let mydata = Object.values(compoundViews.views);

    let favorites = mydata
      .filter((f) => f.uuid === configId)
      .map((v) => ({
        uuid: v.uuid,
        title: v.title,
        created: v.createdOn,
        count: v.compoundCount,
        columns: v.fieldIds.length,
        columns_names: v.fieldIds,
      }));

    const loc = '/plots/create';
    navigate(loc, {
      state: {
        dataId: configId,
        dataType: 'favorites',
        columns: favorites[0].columns_names,
      },
    });
  };

  const getLegend = () => {
    switch (view) {
      case 'zebrafish':
        return <LegendZebrafish />;
      case 'cellHealth':
        return <LegendCellHealth />;
    }
    return <LegendDefault />;
  };

  const getMenuButtonByIndex = (index: React.Key) => {
    if (viewConfig[index]?.restrict?.includes(subscriptionLevel)) return null;
    return (
      <MenuButton
        key={index}
        onClick={() =>
          navigate('/compounds/' + library + '/' + viewConfig[index].path)
        }
        isActive={view === viewConfig[index].path}
        tooltipPlacement={'bottom'}
        icon={viewConfig[index].icon}
        text={viewConfig[index].label}
      />
    );
  };

  const getMenuButtonByPath = (path: string) => {
    const menuItem = viewConfig.findIndex((m) => m.path === path);
    if (menuItem === -1) return <></>;
    return getMenuButtonByIndex(menuItem);
  };

  // banner part of sub- sub-views
  if (view === 'compare') {
    return <></>;
  }

  return (
    <FlexBanner>
      {isFavorite ? (
        <FlexBannerGroup>
          {getMenuButtonByPath('table')}
          <TextRegular>Set: </TextRegular>
          <TextRegular color={'accentSecondary'}>{favoriteName}</TextRegular>
          <FlexSpacer width={20} />
          <TextSmallItalics color={'textSecondary'}>
            ({loadingText})
          </TextSmallItalics>
        </FlexBannerGroup>
      ) : (
        <FlexBannerGroup>
          {viewConfig.map((v, i) => {
            if (debug) console.log('view:', view, ' v.path:', v.path);
            return getMenuButtonByIndex(i);
          })}
          <TextSmallItalics color={'accentSecondary'}>
            {loadingText}
          </TextSmallItalics>
        </FlexBannerGroup>
      )}
      <FlexBannerGroup>{getLegend()}</FlexBannerGroup>
      <FlexBannerGroup>
        {library==="user" && configId === "myCompounds" &&
        
        <FilterDropdown fieldOptions={fieldOptions} setTotalCompounds={setTotalCompounds} setLoadedAll={setLoadedAll} setLoading={setLoading} setData={setData} setNoLoadMore={setNoLoadMore} />
        }
        <SearchFieldAutocomplete
          searchText={searchTerm}
          searchResults={searchResults}
          onChange={handleSearchChange}
          onSelect={handleSearchSelect}
          width={250}
          hideIcon={true}
          hasShowButton
        />
        {!isFavorite && subscriptionLevel !== 'basic' ? (
          <MenuButton
            onClick={handleAddToList}
            icon={<StarIcon />}
            text={'Add To Favorites'}
            tooltipPlacement={'bottom'}
          />
        ) : null}
        {view !== 'tile' ? (
          <MenuButton
            onClick={handlePinSelect}
            icon={<PinIcon />}
            text={'Pin to Top'}
            tooltipPlacement={'bottom'}
          />
        ) : null}
        <MenuButton
          onClick={() =>
            navigate(
              isFavorite
                ? `/favorites/user/compare`
                : `/compounds/${library}/compare`
            )
          }
          icon={<CompareIcon />}
          text={'Compare'}
          tooltipPlacement={'bottom'}
        />
        {!isFavorite ?
          <MenuButton
            onClick={handleFilterModal}
            isActive={filterActive}
            icon={<FilterIcon active={filterActive} />}
            text={'Filter Set'}
            tooltipPlacement={'bottom'}
          />
        :
          <MenuButton
          onClick={handleCreatePlot}
          icon={<GraphIcon />}
          text={'Create a Plot'}
          tooltipPlacement={'bottom'}
          />
        }
        <MenuButton
          onClick={handleCart}
          icon={<Cart2Icon />}
          text={'Order'}
          tooltipPlacement={'bottom'}
        />
        {view === 'table' && (
        <>
          <MenuButton
            onClick={handleSettings}
            icon={<ColumnsIcon />}
            text={'Configure Table Columns'}
            tooltipPlacement={'bottom'}
          />
          { subscriptionLevel !== 'basic' ?
            <MenuButton
              onClick={() => navigate('/fields')}
              icon={<EditIcon />}
              text={'Edit Data Fields Definitions'}
              tooltipPlacement={'bottom'}
            />
          : null}
        </>
        )}
        {libraryId === 'user' && (
        <>
          <MenuButton
          onClick={handleNewCompound}
          icon={<NewCompoundIcon />}
          text={'Add Compound'}
          tooltipPlacement={'bottom'}
          />
          <MenuButton
          onClick={handleDeleteCompounds}
          icon={<TrashIcon />}
          text={'Delete Selected Compounds'}
          tooltipPlacement={'bottom'}
          />
        </>
        )}
        <FlexSpacer width={10} />
      </FlexBannerGroup>
    </FlexBanner>
  );
};

export default CompoundBanner;
