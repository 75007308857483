import React from 'react';
import styled from 'styled-components/macro';
import { Row } from './RowElements';
import { truncateString } from '@utils/misc';

interface Props {
  rows: string[];
  title: string;
  className?: string;
}

const SimpleTable = (props: Props) => {
  const { rows, title } = props;

  const renderCellContent = (item: string) => {
    if (title === 'smiles') {
      return (
        <HoverableCell title={item}>{truncateString(item, 15)}</HoverableCell>
      );
    }
    return <Cell title={item}>{truncateString(item, 25)}</Cell>;
  };

  return (
    <Container className={props?.className}>
      <Table>
        <tbody>
          {rows.map((item, itemIdx) => (
            <Row key={itemIdx}>{renderCellContent(item)}</Row>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default SimpleTable;

const Container = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 10px 0;
  border: 1px solid ${(p) => p.theme.palette.textSecondary};
  overflow: hidden; /* Ensure content stays within the container */
`;

const Table = styled.table`
  width: 100%; /* Ensure table takes full width */
`;

const Cell = styled.td`
  padding-left: ${(p) => p.theme.sizes.xsmall};
  padding-top: 0;
  height: 14px;
  vertical-align: middle;
  text-align: left;
  font-size: ${(p) => p.theme.sizes.xsmall};

  font-weight: ${(p) => p.theme.weights.light};
  overflow: hidden; /* Hide overflow */
  white-space: nowrap; /* Keep text on one line */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
`;

const HoverableCell = styled(Cell)`
  cursor: pointer;
  &:hover::after {
    content: attr(title); /* Display full text on hover */
    position: absolute;
    background: ${(p) => p.theme.palette.backgroundPrimary};
    padding: 5px;
    border-radius: 4px;
    white-space: normal; /* Allow full text display in tooltip */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    top: -5px; /* Adjust positioning as needed */
    left: 100%; /* Position tooltip next to the cell */
  }
`;
