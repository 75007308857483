import React from 'react';
import styled from 'styled-components/macro';
import ProcessStepTile, { StepT } from './ProcessStepTile';

interface PropsI {
  steps: StepT[];
}
const debug = false;
const ProcessSteps = (props:PropsI) => {
  if (debug) console.log('ProcessSteps | props: ', props);
  const { steps } = props;

  return (
    <ProcessContainer>
      { steps.map((step, i) => (
        <ProcessStepTile key={i} step={step} />
      ))}
    </ProcessContainer>
  )
}
export default ProcessSteps;

const ProcessContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;