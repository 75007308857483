import React, {useState} from "react";
import styled from "styled-components/macro";
import {LocalShipping} from "@mui/icons-material";
import TextButton from "@as_core/controls/buttons/TextButton";
import DataLoading from "@as_core/elements/DataLoading";
import StationaryModal from "@as_core/elements/StationaryModal";
import { Row } from '../common/flexStyles';
import useRequestForms, {RequestFormT} from "../hooks/useRequestForms";
import {RequestT, RequestUpdateT} from "../hooks/useRequests";
import ShippingFormUpdates from "./ShippingFormUpdates";
import ShippingFormDetails from "./ShippingFormDetails";
import { useQuery } from '@tanstack/react-query';

interface PropsI {
  viewMode: string;
  active: boolean;
  request: RequestT;
  updateSelectedRequest: (id: string, updates: RequestUpdateT) => Promise<void>;
  setIsTrackingAvailable: (v: boolean) => void;
}

const debug = false;
const ShippingInfoDetails = (props: PropsI) => {
  const {viewMode, active, request, updateSelectedRequest, setIsTrackingAvailable} = props;
  const { getRequestFormsByRequestIdOfType } = useRequestForms();
  const [isShippingConfirmOpen, setIsShippingConfirmOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const getShippingInfo = async (): Promise<RequestFormT> => {
    const forms = await getRequestFormsByRequestIdOfType(request.id, 'shipping_information');
    if (forms.length) {
      setIsTrackingAvailable(true);
      return forms[0];
    }
    setIsTrackingAvailable(false);
    return null;
  }

  // load forms for request.id
  const {isPending, isError, error, data: shippingTracking} = useQuery({
    queryKey: ['requestForms', request.id, 'shipping_information'],
    queryFn: () => getShippingInfo()
  });
  if (isError) console.error('ShippingInfoDetails vis useQuery loading error: ',error.message);

  if (debug) console.log('ShippingInfoDetails | viewMode:', viewMode, ' shippingTracking:', shippingTracking);

  return (
    <Row h_align={'center'} width='max-content'>
      <>
        { !isPending ?
          shippingTracking === null && viewMode === 'user' ?
            <TextButton
              label = 'Confirm Shipping of Compound Plates'
              icon={<ShippingIcon />}
              width={295}
              height={28}
              isDisabled={!active || isShippingConfirmOpen}
              onClick={()=>setIsShippingConfirmOpen(prevState => !prevState)}
            />
            : shippingTracking !== null  ?
              <ShippingFormDetails viewMode={viewMode} form={shippingTracking} />
            : null
          :
          <DataLoading />
        }
      </>
      <StationaryModal
        title={'Confirm Compound Plate\'s Shipping Information'}
        opacity={0.95}
        modalOpen={isShippingConfirmOpen}
        height={200}
        width={500}
        onCloseClick={() => setIsShippingConfirmOpen(false)}
      >
        <ShippingFormUpdates
          key={'request_user_update_shipping_confirm'}
          request={request}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          updateSelectedRequest={updateSelectedRequest}
          handleClose={()=>setIsShippingConfirmOpen(false)}
        />
      </StationaryModal>
    </Row>
  )
};

export default ShippingInfoDetails;

const ShippingIcon = styled(LocalShipping)`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
`;
