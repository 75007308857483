import React from 'react';
import styled from 'styled-components/macro';
import { UseObjectArrayReturnType } from '@as_core/hooks/useObjectArray';
import PushPinIcon from '@components/icons/pushPin.icon';
import { GraphSettingsT } from '../../types';
export const MAX_PINNED = 6;
export function addToPinned(
  pinnedCompounds: UseObjectArrayReturnType,
  id: string,
  position?: string
) {
  if (pinnedCompounds?.data.length > MAX_PINNED) {
    pinnedCompounds.delete(pinnedCompounds.data[0]);
  }
  if (position && position === 'front') {
    pinnedCompounds.addFront(id);
  } else {
    pinnedCompounds.add(id);
  }
}

type MenuOptionT = {
  label: string;
  action: () => void;
};

interface PropsI {
  open: boolean;
  setOpen: (v: boolean) => void;
  position: { x: number; y: number };
  label: string;
  settings: GraphSettingsT;
  selectedCompounds: UseObjectArrayReturnType;
  pinnedCompounds: UseObjectArrayReturnType;
}

const ContextMenu = (props: PropsI) => {
  const {
    open,
    setOpen,
    position,
    label,
    settings,
    selectedCompounds,
    pinnedCompounds,
  } = props;
  const hoveredVertexId = settings?.hoveredVertex
    ? settings.hoveredVertex.id
    : null;

  if (!open) return <></>;

  function handleClick(m: MenuOptionT) {
    m.action();
    setOpen(false);
  }

  const handleAddWithNeighbors = (id: string) => {
    console.log('handleAddWithNeighbors | id:', id);
  };

  const handleDeleteWithNeighbors = (id: string) => {
    console.log('handleDeleteWithNeighbors | id:', id);
  };

  const handlePinSelect = () => {
    if (pinnedCompounds.data.includes(hoveredVertexId)) {
      pinnedCompounds.delete(hoveredVertexId);
    } else {
      addToPinned(pinnedCompounds, hoveredVertexId);
    }
  };

  function getMenu() {
    if (settings?.hoveredVertex) {
      if (selectedCompounds.data.includes(settings.hoveredVertex.id)) {
        return [
          {
            label: '- Compound',
            action: () => selectedCompounds.delete(settings.hoveredVertex.id),
          },
          {
            label: '- Compound & Neighbors',
            action: () => handleDeleteWithNeighbors(settings.hoveredVertex.id),
          },
        ];
      } else {
        return [
          {
            label: '+ Compound',
            action: () => selectedCompounds.add(settings.hoveredVertex.id),
          },
          {
            label: '+ Compound & Neighbors',
            action: () => handleAddWithNeighbors(settings.hoveredVertex.id),
          },
        ];
      }
    } else {
      return [{ label: 'reset view', action: () => {} }];
    }
  }

  return (
    <div>
      <ContextMenuContainer position={position}>
        {label !== '' ? (
          <MenuHeader>
            <Link onClick={handlePinSelect}>
              <PushPinIcon />
            </Link>
            <MenuTitle>{label}</MenuTitle>
          </MenuHeader>
        ) : null}
        <SubMenu>
          {getMenu().map((m, index: number) => (
            <MenuItem key={index} onClick={() => handleClick(m)}>
              {m.label}
            </MenuItem>
          ))}
        </SubMenu>
      </ContextMenuContainer>
    </div>
  );
};
export default ContextMenu;

const ContextMenuContainer = styled.div<{ position: { x: number; y: number } }>`
  z-index: 1000;
  font-size: ${(p) => p.theme.sizes.xsmall};

  position: absolute;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  box-sizing: border-box;
  width: max-content;
  top: ${(p) => p.position.y - 100 - 10}px;
  left: ${(p) => p.position.x - 50 - 5}px;
  ul {
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    list-style: none;
  }
  ul li {
    padding: 2px;
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
    border-radius: 5px;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 10px;
  height: 30px;
  border-bottom: 0.5px solid ${(p) => p.theme.palette.accentSecondary};
`;

const MenuTitle = styled.div`
  display: flex;
  padding: 0 10px;
`;

const SubMenu = styled.ul`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.span`
  cursor: pointer;
  color: ${(p) => p.theme.palette.textSecondary};
  border-radius: 15px;
  :hover {
    color: ${(p) => p.theme.palette.accentPrimary};
  }
`;

const Link = styled.span`
  display: flex;
  cursor: pointer;
  filter: grayscale(1);
  :hover {
    filter: brightness(1);
  }
`;
