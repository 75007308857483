import React from 'react';
import styled from 'styled-components/macro';

type SubscriptionPricePropT = {
  type: 'basic' | 'starter' | 'academic' | 'commercial';
};

type PricePropsT = {
  cost: string;
  period: string;
};
const Price = (props: PricePropsT) => {
  return (
    <Row>
      <AccentText>{props.cost}</AccentText>
      <PrimaryText>- {props.period}</PrimaryText>
    </Row>
  );
};

const SubscriptionPrice = (props: SubscriptionPricePropT) => {
  const getPrice = (t: string) => {
    switch (t) {
      case 'basic':
        return <PrimaryText>Free</PrimaryText>;
      case 'starter':
        return (
          <Column>
            <Price cost={'$79'} period={'billed monthly'} />
            <Price cost={'$899'} period={'billed annually'} />
            <PrimaryText>1 seat per subscription</PrimaryText>
            <PrimaryText>2 data repositories</PrimaryText>
          </Column>
        );
      case 'academic':
        return (
          <Column>
            <Price cost={'$229'} period={'billed monthly'} />
            <Price cost={'$2,400'} period={'billed annually'} />
            <PrimaryText>5 seats per subscription</PrimaryText>
            <PrimaryText>5 data repositories</PrimaryText>
          </Column>
        );
      case 'commercial':
        return (
          <Column>
            <Price cost={'$339'} period={'billed monthly'} />
            <Price cost={'$3,600'} period={'billed annually'} />
            <PrimaryText>1 seat per subscription</PrimaryText>
            <PrimaryText>5 data repositories</PrimaryText>
          </Column>
        );
    }
    return t;
  };
  return (
    <SubscriptionPriceContainer>
      {getPrice(props.type)}
    </SubscriptionPriceContainer>
  );
};

export default SubscriptionPrice;

const SubscriptionPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  height: 18px;
  width: max-content;
  flex-direction: row;
`;

const PrimaryText = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  height: 18px;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const AccentText = styled(PrimaryText)`
  color: ${(p) => p.theme.palette.accentSecondary};
`;
