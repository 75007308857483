import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip, ButtonBase } from '@mui/material';
import ToTableIcon from '@components/icons/toTable.icon';

interface ToTableViewButtonProps {
  text: string;
  tooltip: string;
  onClick?: () => void;
  isActive?: boolean;
  margin?: string;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
}

const ToTableViewButton = (props: ToTableViewButtonProps) => {
  const {
    text,
    tooltip,
    onClick,
    isActive = false,
    margin = '4px 0',
    tooltipPlacement = 'right',
  } = props;
  return (
    <MenuButtonContainer margin={margin}>
      <Tooltip title={tooltip} placement={tooltipPlacement} arrow>
        <IconButton onClick={onClick} className={isActive ? 'active' : ''}>
          {text} <ToTableIcon />
        </IconButton>
      </Tooltip>
    </MenuButtonContainer>
  );
};
export default React.memo(ToTableViewButton);

const MenuButtonContainer = styled.div<{ margin: string }>`
  display: flex;
  margin: ${(p) => p.margin};
  border-radius: 4px;
  padding: 0;
  color: ${(p) => p.theme.palette.textSecondary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  :hover {
    background-color: ${(p) => p.theme.palette.accentPrimary};
  }
`;

const IconButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-size: ${(p) => p.theme.sizes.large};

  width: max-content;
  height: 24px;

  border-radius: 10px;
  :hover {
    filter: grayscale(0) brightness(${(p) => p.theme.menu.active.brightness})
      contrast(${(p) => p.theme.menu.active.contrast});
  }
  &.active {
    filter: grayscale(0) brightness(${(p) => p.theme.menu.active.brightness})
      contrast(${(p) => p.theme.menu.active.contrast});
  }
`;
