import styled from 'styled-components/macro';
import { FlexRow } from '@as_core/elements/flexStyles';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import React from 'react';

interface PropsI {
  label: string;
  completed?: boolean;
  children?: React.ReactNode;
}

const ToDoItem = (props:PropsI) => {
  const {label, completed = false} = props;

  return(
    <Container>
      <FlexRow v_centered>
        <CheckBoxContainer>
          {completed ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </CheckBoxContainer>
        <Label>{label}</Label>
      </FlexRow>
      <FlexRow width={'100%'} h_centered>{props.children}</FlexRow>
    </Container>
  )
}

export default ToDoItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
`

const CheckBoxContainer = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.accentSecondary};
  margin-right: 5px;
`;

const Label = styled.div`
  color: ${(p) => p.theme.palette.textSecondary};
`;