import React, {useContext, useEffect} from 'react';
import styled from 'styled-components/macro';
import useFileImport from '../useFileImport';
import { createDoseResponseDataSetFromExcel } from '@components/dataSets/dataSet.service';
import {Header, Row} from '../shared/RowElements';
import {FlexRow, FlexItem} from '@components/layout/FlexStyles';
import {TextSmall, TextSmallBold} from '@components/elements/TextStyles';
import ImportDoseResponseReviewRow from './ImportDoseResponseReviewRow';
import {UserContext} from "@stores/UserContext";
import { ColumnField, DataSetT } from '@components/dataSets/dataSet.types';
import TextButton from "@components/controls/TextButton";
import ForwardArrowIcon from '@components/icons/forwardArrow.icon';
const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
interface ImportDoseResponseReviewProps {
  columnFields: ColumnField[];
  dataSet: DataSetT;
  setDataSet: (dataSet: DataSetT) => void;
  width?: number;
}
const ImportDoseResponseReview = (props: ImportDoseResponseReviewProps) => {
  const { columnFields, dataSet, setDataSet, width } = props;
  const { context: { workbook, columns, setActiveStep } } = useFileImport();
  const { user } = useContext(UserContext);
  if (debug) console.log('ImportDoseResponseReview | workbook', workbook);
  if (debug) console.log('ImportDoseResponseReview | columns', columns);
  useEffect(()=>{
    if (debug) console.log("ImportDoseResponseReview | useEffect | columns", columns,
        'columnFields', columnFields);
    // process the file and put it in the correct format
    let myColumns = columns;
    for (const col of columnFields) {
      const index = myColumns.findIndex((c) => c.colName === col.matched_column);
      if (index !== -1) myColumns[index].field_id = col.value;
    }
    const dataSet = createDoseResponseDataSetFromExcel(user.authId, workbook, myColumns);
    if (debug) console.log("doseResponseDataSet:", dataSet);
    setDataSet(dataSet);
  }, [workbook, columns]);
  if (debug) console.log('ImportDoseResponseReview | dataSet ', dataSet.dataFormatted);
  return (
    <Container>
      { !dataSet?.dataFormatted ? <></> :
        <>
          <FlexRow width={'100%'} h_centered>
            <FlexItem><TextSmallBold color={'accentSecondary'}>Number of Dose Response Experiments(s) in File:</TextSmallBold></FlexItem>
            <FlexItem><TextSmall color={'primary'}>{dataSet?.dataFormatted?.length}</TextSmall></FlexItem>
          </FlexRow>
          <ScrollContainer>
            <Table width={width}>
              <tbody>
              <Row key={'doseresponseheader_0'}>
                <Header>Compound Ids</Header>
                <Header>Sample Ids</Header>
                <Header>Dose-Response Pairs</Header>
                <Header>Unit</Header>
                <Header>Data Preview</Header>
              </Row>
              {dataSet.dataFormatted.map((data, dataIdx) => {
                if (debug) console.log('ImportDoseResponseReview | row data:', data);
                return(
                    <ImportDoseResponseReviewRow
                        key={'row_' + dataIdx}
                        data={data}
                        dataIdx={dataIdx}
                        onClick={() =>  {
                          setActiveStep((currStep) => currStep -1)
                        }}
                    />
                )
              })}
              </tbody>
            </Table>
          </ScrollContainer>
        </> }
        <ButtonContainer>
                <TextButton
                  text={'Done with review'}
                  height={30}
                  width={200}
                  onClick={() =>
                    setActiveStep((currStep) => currStep + 1)
                  }
                  icon={<ForwardArrowIcon />}
                />
              </ButtonContainer>
    </Container>
  );
};
export default ImportDoseResponseReview;
const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 330px);
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;
const Table = styled.table<{ height?: number; width?: number }>`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;
const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
margin-top: 10px;
`;
