import React from 'react';
import styled from 'styled-components/macro';
import { RemoveCircle } from '@mui/icons-material';
import { UseObjectArrayReturnType } from '@as_core/hooks/useObjectArray';
import HoverTextTruncate from '@as_core/elements/HoverTextTruncate';
import CopyToClipboardIcon from '@as_core/icons/copyToClipboard.icon';
import ButtonText from '@components/elements/buttons/ButtonText';

interface PropsI {
  selectedCompounds: UseObjectArrayReturnType;
}

const SelectedCompoundsPanel = (props: PropsI) => {
  const { selectedCompounds } = props;

  function handleDelete(id: string) {
    selectedCompounds.delete(id);
  }

  function handleClipboard() {
    navigator.clipboard.writeText(selectedCompounds.data.join('\n')).then();
  }

  return (
    <Container>
      <CompoundList>
        <ScrollBox>
          {selectedCompounds.data.sort().map((v, index) => (
            <ListItem key={`selected_compounds_${index}`}>
              <DeleteCompound onClick={() => handleDelete(v)}>
                <RemoveCircle sx={{ fontSize: 20 }} />
              </DeleteCompound>
              <CompoundItem>
                <HoverTextTruncate text={v} characters={36} showRear={false} />
              </CompoundItem>
            </ListItem>
          ))}
        </ScrollBox>
      </CompoundList>
      <ActionContainer>
        <ButtonText
          label={'Copy List to Clipboard'}
          icon={<CopyToClipboardIcon />}
          onClick={handleClipboard}
        />
      </ActionContainer>
    </Container>
  );
};

export default SelectedCompoundsPanel;

const Container = styled.div`
  display: flex;
  width: calc(100% - 7px);
  height: calc(100% - 5px);
  flex-direction: column;
  margin: 5px;
  font-size: ${(p) => p.theme.sizes.large};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const ActionContainer = styled.div`
  width: calc(100% - 7px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  height: 34px;
`;

const CompoundList = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  line-height: 14px;
  font-size: ${(p) => p.theme.sizes.xsmall};

  color: ${(p) => p.theme.palette.textSecondary};
  overflow-x: hidden;
  overflow-y: auto;
`;

const ScrollBox = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
`;

const ListItem = styled.div`
  display: flex;
  max-width: 300px;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const CompoundItem = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.xsmall};

  white-space: nowrap;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const DeleteCompound = styled.div`
  display: flex;
  width: 24px;
  color: ${(p) => p.theme.palette.accentSecondary};
  font-size: ${(p) => p.theme.sizes.small};
  font-weight: bold;
  :hover {
    color: ${(p) => p.theme.palette.accentPrimary};
  }
`;
