import React, { useEffect, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import useRequests, { RequestT, RequestUpdateT } from '../hooks/useRequests';
import BasicTable from '@as_core/tables/BasicTable';
import RequestDetails from '../common/RequestDetails';
import { getRequestsTableRows } from '../utils/renderValues';
import RequestsProcessMap from '../common/RequestsProcessMap';
import RequestProcessMap from '../common/RequestProcessMap';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const tableFields: BasicTableFieldsT[] = [
  { value: 'view', label: '', type: 'view', width: 40 },
  { value: 'status', label: 'Fulfillment Status', type: 'status', width: 175 },
  { value: 'prod_name', label: 'Request Type', align: 'left', width: 230 },
  {
    value: 'name',
    label: 'Name',
    type: 'shortString',
    align: 'left',
    width: 230,
  },
  {
    value: 'created_at',
    label: 'Created',
    type: 'date',
    align: 'left',
    width: 90,
  },
  {
    value: 'quantity',
    label: 'Quantity',
    type: 'quantity',
    align: 'left',
    width: 100,
  },
  {
    value: 'updated_at',
    label: 'Last Updated',
    type: 'datetime',
    align: 'left',
    width: 140,
  },
];

type PropsT = {
  requestType: string;
  requests: RequestT[];
  showProcessMap?: boolean;
  setNumberRequests?: (n: number) => void;
};

const debug = false;

const RequestsTable = ({
  requests,
  requestType,
  showProcessMap = true,
  setNumberRequests,
}: PropsT) => {
  const queryClient = useQueryClient();
  const { updateRequest, getRequestType } = useRequests();
  const [selectedRequest, setSelectedRequest] = useState<RequestT | null>(null);
  const [requestsRows, setRequestsRows] = useState<RequestT[]>([]);

  const sortedRequests = useMemo(() => {
    return [...requests].sort((a, b) => a.name.localeCompare(b.name));
  }, [requests]);

  const mutation = useMutation({
    mutationFn: ({ id, updates }: { id: string; updates: RequestUpdateT }) =>
      updateRequest(id, updates),
    onSuccess: (data) => {
      if (debug)
        console.log('user/RequestsTable | mutation | OnSuccess | data:', data);
      setSelectedRequest(data);
      queryClient.invalidateQueries({ queryKey: ['requests', 'user'] });
      if (selectedRequest) {
        queryClient.invalidateQueries({
          queryKey: ['requests', 'order', selectedRequest.id],
        });
      }
    },
    onError: (error) => {
      console.error('user/RequestsTable | mutation | Error:', error);
    },
  });

  const handleRequestUpdate = useCallback(
    async (id: string, updates: RequestUpdateT) => {
      if (debug)
        console.log(
          'user/RequestsTable | handleRequestUpdate {id, updates}:',
          id,
          updates
        );
      mutation.mutate({ id, updates });
    },
    [mutation]
  );

  const handleClick = useCallback(
    (clickType: string, requestId: string) => {
      if (debug) console.log('user/RequestsTable | handleClick: ', requestId);
      const matched = sortedRequests.find((o) => o.id === requestId);
      if (matched) {
        setSelectedRequest(matched);
      } else {
        console.error(
          `user/RequestsTable | unable to locate request by id ${requestId}`
        );
      }
    },
    [sortedRequests]
  );

  useEffect(() => {
    if (sortedRequests.length > 0) {
      const updatedRequests = sortedRequests.map((r) => ({
        ...r,
        itemType: getRequestType(r).category,
        userEmail: '',
      }));
      setRequestsRows(
        getRequestsTableRows(
          'open',
          'user',
          updatedRequests,
          tableFields,
          handleClick
        )
      );
      if (setNumberRequests) setNumberRequests(sortedRequests.length);
    } else {
      setRequestsRows([]);
      if (setNumberRequests) setNumberRequests(0);
    }
  }, [sortedRequests, getRequestType, handleClick, setNumberRequests]);

  useEffect(() => {
    setSelectedRequest(null);
  }, [requestType]);

  if (debug) console.log('user/RequestsTable | requests:', requests);

  if (requests.length === 0) {
    return (
      <ErrorMessages
        messages={[
          <MessageItem key='emi_no_requests'>
            You do not have any {requestType} requests
          </MessageItem>,
        ]}
      />
    );
  }

  return (
    <RequestTableContainer>
      {selectedRequest ? (
        <>
          {showProcessMap && (
            <RequestProcessMap
              requestType={requestType}
              request={selectedRequest}
            />
          )}
          <RequestDetails
            action='open'
            viewMode='user'
            request={selectedRequest}
            handleRequestUpdate={handleRequestUpdate}
            userEmails={{}}
            handleBack={() => setSelectedRequest(null)}
          />
        </>
      ) : (
        <>
          {showProcessMap && (
            <RequestsProcessMap
              requestType={requestType}
              requests={sortedRequests}
            />
          )}
          <BasicTable fields={tableFields} rows={requestsRows} border={false} />
        </>
      )}
    </RequestTableContainer>
  );
};

export default RequestsTable;

const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
