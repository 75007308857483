import React from 'react';
import styled from 'styled-components/macro';

import { UseCartObjectReturnType } from '@as_core/hooks/useShoppingCart';
import CartIcon from '@components/icons/cart.icon';

interface PropsI {
  cart: UseCartObjectReturnType;
  onClick: () => void;
}

const debug = false;
const OrdersCartIcon = (props: PropsI) => {
  const { cart } = props;
  if (debug) console.log('OrderCart | {props}:', props);

  const noItems = Object.keys(cart.data).length;
  return (
      <CartIconContainer>
        { noItems ? <NotificationCountBubble>{noItems}</NotificationCountBubble> : null }
        <CartIcon size={42}/>
      </CartIconContainer>
  );
};

export default OrdersCartIcon;

const CartIconContainer = styled.div`
  display: flex;
  position: relative;
  width: 100px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
`;

const NotificationCountBubble = styled.div`
  position: absolute;
  font-size: 13px;
  font-weight: bold;
  top: 0;
  left: 15px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.textPrimary };
  background-color:  ${(props) => props.theme.palette.accentPrimary };
  border: 1px solid ${(props) => props.theme.palette.backgroundTertiary};
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;
