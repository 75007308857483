import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TextSmall } from '@as_core/elements/textStyles';
import { Logo } from '@as_core/account/forms/FormStyles';
import { APP_CONFIG } from '@app_config/app';
import { getSubscriptionDetails } from '@business/products/Subscriptions';
import { UserContext, UserT } from '@stores/UserContext';
import {
  getMaxInvites,
  getMaxRepositories,
  getUserSubscriptionType,
  SubscriptionT,
} from '@utils/useUserAppInfo';
import LabeledContainer from '@as_core/elements/LabeledContainer';
import { getObjectValueByKey } from '@utils/misc';
import { FlexSpacer } from '@as_core/elements/flexStyles';

function getDisplayDate(date: string): string {
  const dateUTC = new Date(date);
  return dateUTC.toLocaleDateString() + ' ' + dateUTC.toLocaleTimeString();
}

function getCardRow(
  user: UserT,
  name: string,
  subscriptionDetails: SubscriptionT,
  width = 'max-content'
) {
  switch (name) {
    case 'duration': {
      const start = getObjectValueByKey(subscriptionDetails, 'created', '');
      const end = getObjectValueByKey(subscriptionDetails, 'expires', '');
      let value = getDisplayDate(start);
      let label = end !== '' ? 'Duration:' : 'Created:';
      if (end !== '') {
        value = value + ' - ' + getDisplayDate(end);
      }
      return (
        <Row key={`sv_row_${name}`}>
          <Label>{label}</Label>
          <Value width={width}>{value}</Value>
        </Row>
      );
    }
    case 'invitees': {
      const number = getMaxInvites(user);
      return (
        <Row key={`sv_row_${name}`}>
          <Label>Total Invitees:</Label>
          <Value width={width}>{number}</Value>
        </Row>
      );
    }
    case 'repositories': {
      const repo_number = getMaxRepositories(user);
      return (
        <Row key={`sv_row_${name}`}>
          <Label>Owned Repositories:</Label>
          <Value width={width}>{repo_number}</Value>
        </Row>
      );
    }
    default:
      return null;
  }
}

const debug = false;
const SubscriptionCard = () => {
  const { user } = useContext(UserContext);
  const { subscription } = user.appInfo;
  if (debug) console.log('SubscriptionCard | user:', user);
  const userSubscriptionType = getUserSubscriptionType(user.appInfo);
  const isOwner = subscription.ownerAuthId === user.authId;
  const subscriptionDetails = getSubscriptionDetails(userSubscriptionType);

  if (debug)
    console.log(
      'ProductCard | user:',
      user,
      'subscriptionDetails:',
      subscriptionDetails
    );

  return (
    <LabeledContainer label={'My Current Subscription'}>
      <CardContainer>
        <Row>
          <Logo>
            <img src={APP_CONFIG.logo} alt='logo' />
          </Logo>
        </Row>
        <Row>
          <TextSmall color={'primary'}>{subscriptionDetails?.name}</TextSmall>
          {!isOwner ? (
            <>
              <FlexSpacer width={5} />
              <TextSmall>(shared)</TextSmall>
            </>
          ) : null}
        </Row>
        <Row>
          <Value align={'center'} width={'300px'} color={'accentSecondary'}>
            {subscriptionDetails?.description}
          </Value>
        </Row>
        <Row>
          <Label color={'accent'}>Catalog #:</Label>
          <Value width={'max-content'}>
            {subscriptionDetails?.catalogNumber}
          </Value>
        </Row>
        <Row>
          <Item>{getCardRow(user, 'duration', subscription)}</Item>
        </Row>
        <Row>
          <Item>{getCardRow(user, 'invitees', subscription, '50px')}</Item>
          <Item>{getCardRow(user, 'repositories', subscription, '50px')}</Item>
        </Row>
        {!isOwner ? (
          <Row>
            <Label color={'accent'}>Subscription Owner:</Label>
            <Value>{subscription.ownerAuthEmail}</Value>
          </Row>
        ) : null}
      </CardContainer>
    </LabeledContainer>
  );
};
export default SubscriptionCard;

const CardContainer = styled.div`
  display: flex;
  border-radius: 15px;
  width: 550px;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 3px 0;
  ${(p) => p.width && `width: ${p.width};`}
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div<{ width?: string }>`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  justify-content: end;
  white-space: nowrap;
  width: ${(p) => p.width};
  color: ${(p) => p.theme.palette.textPrimary};
`;
Label.defaultProps = {
  width: 'max-content',
};

const Value = styled(Label)<{ align?: string; color?: string; width?: string }>`
  width: ${(p) => p.width} px;
  padding-left: 5px;
  justify-content: ${(p) => p.align};
  color: ${(p) =>
    p.color === 'accentSecondary'
      ? p.theme.palette.accentSecondary
      : p.theme.palette.textSecondary};
`;
Value.defaultProps = {
  align: 'start',
  color: 'textSecondary',
  width: 'max-content',
};
