import React from 'react';
import styled from 'styled-components/macro';

interface PropsI {
  appTitle: string | React.ReactNode;
  title: string;
}

const PageTitle = (props: PropsI) => {
  const { appTitle, title } = props;
  return (
    <Title>
      <AppTitle>{appTitle}</AppTitle>
      <AppTitle>:</AppTitle>
      <SubTitle>{title}</SubTitle>
    </Title>
  );
};
export default PageTitle;

const Title = styled.div`
  display: flex;
  padding-left: 5px;
  vertical-align: center;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.theme.sizes.medium};
`;

const AppTitle = styled(Title)`
  padding-left: 0;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const SubTitle = styled(Title)`
  padding-left: 4px;
`;
