import {
  FieldsTypeT,
  getObjectValue,
} from '@subApps/admin/components/ObjectDetails';
import {
  AppInfoT,
  UserEventsItemT,
  UserListItemT,
} from '@utils/useUserAppInfo';
import { RepositoryDetailsT } from '@subApps/account/repositoryManagement/useRepos';
import { EventSummaryT } from '@subApps/admin/components/UserEventsList';

export function getTableRows(
  objectList:
    | UserListItemT[]
    | RepositoryDetailsT[]
    | UserEventsItemT[]
    | EventSummaryT[]
    | AppInfoT[],
  columns: FieldsTypeT[],
  handleClick?: (action: string, v: string) => void
) {
  const rows = [];
  objectList.forEach((o) => {
    let row = {};
    columns.forEach((c) => {
      row[c.value] = getObjectValue(o, c, handleClick);
    });
    rows.push(row);
  });
  return rows;
}
