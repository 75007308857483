import styled from 'styled-components/macro';

export const Title = styled.div`
  display: flex;
  font-size: 16px;
`;

export const AccountDetailContainer = styled.div<{ align?: string }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: ${(p) => p.align};
`;
AccountDetailContainer.defaultProps = {
  align: 'flex-start',
};

export const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid #3f51b6;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const Container = styled.div<{
  width?: string;
  height?: string;
  hasBorder?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(p) => (p.width ? p.width : 'max-content')};
  height: ${(p) => (p.height ? p.height : 'max-content')};
  min-width: 500px;
  padding: 10px;
  border-radius: 15px;
  border: ${(p) => (p.hasBorder ? 1 : 0)}px solid
    ${(p) => p.theme.palette.accentSecondary};
`;
Container.defaultProps = {
  hasBorder: false,
};

export const Row = styled.div<{ width?: string; height?: string }>`
  display: flex;
  width: ${(p) => (p.width ? p.width : 'max-content')};
  height: ${(p) => (p.height ? p.height : 'max-content')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.sizes.small};
  margin-bottom: 2px;
`;

export const Label = styled.div<{ width?: number }>`
  display: flex;
  width: ${(p) => p.width}px;
  color: ${(p) => p.theme.palette.textSecondary};
  padding-right: 5px;
`;
Label.defaultProps = {
  width: 140,
};

export const Message = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  font-style: italic;
  height: 20px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;
