import React, { ReactNode } from 'react';
import Overview from '../components/Overview';
import Search from '../components/Search';
import Orders from '../components/Orders';
import PastOrders from '../components/PastOrders';

export const getTitle = (action: string): string => {
  switch (action) {
    case 'overview':
      return 'Chemporium Overview';
    case 'search':
      return 'Chemporium Search';
    case 'orders':
      return 'Chemporium Active Orders';
    case 'past':
      return 'Chemporium Past Orders';
  }
  return 'Default Title';
};

export const getComponent = (view: string): ReactNode => {
  switch (view) {
    case 'overview':
      return <Overview />;
    case 'search':
      return <Search />;
    case 'orders':
      return <Orders />;
    case 'past':
      return <PastOrders />;
  }
  return <div>Unknown Action {view}</div>;
};
