import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { CompoundFieldT } from '@stores/fields';

interface ColumnFieldPickerTableProps {
  setPreviewColumn: (v: string) => void;
  availableFields: string[];
  selectedColumns: Array<string | undefined> | string;
  onColumnPicked: (f: string) => void;
  height: number;
}

const debug: boolean = process.env.REACT_APP_HOST_TYPE === 'dev';
const ColumnFieldPickerTable = (props: ColumnFieldPickerTableProps) => {
  const {
    setPreviewColumn,
    availableFields,
    selectedColumns,
    onColumnPicked,
    height,
  } = props;
  if (debug)
    console.log(
      'ColumnFieldPicker: {availableFields, selectedColumns}',
      availableFields,
      selectedColumns
    );

  // various state handlers
  const handleMouseEnter = (v) => {
    setPreviewColumn(v);
  };
  const handleMouseLeave = () => {
    setPreviewColumn('');
  };
  const handleOnColumnPicked = (f) => {
    onColumnPicked(f);
  };

  return (
    <Container>
      <ScrollContainer height={height}>
        <Table>
          <tbody>
            {availableFields.map((field) => {
              return (
                <Row
                  key={'v_' + field}
                  onMouseEnter={() => handleMouseEnter(field)}
                  onMouseLeave={() => handleMouseLeave()}
                  onClick={() => handleOnColumnPicked(field)}
                >
                  {field}
                </Row>
              );
            })}
          </tbody>
        </Table>
      </ScrollContainer>
    </Container>
  );
};

export default ColumnFieldPickerTable;

const Container = styled.div`
  width: calc(100% - 10px);
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  padding-left: 15px;
  margin: 5px;
  display: block;
  position: relative;
`;

const ScrollContainer = styled.div<{ height: number }>`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  max-height: ${(p) => p.height}px;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }

  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;

const Table = styled.table`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  table-layout: auto;
  min-width: 400px;
`;

interface ExtraProps {
  compound?: CompoundFieldT;
  field_id?: string;
  fieldSrc?: string;
}

const Row = styled.tr<ExtraProps>`
  font-size: ${(p) => p.theme.sizes.small};
  margin-bottom: 3px;
  font-weight: lighter;
  cursor: pointer;
  border-bottom: ${(p) => p.theme.borders.thin};

  &:nth-child(even) {
    background-color: ${({ compound, field_id, theme, fieldSrc }) => {
      const sourceId = _.get(
        compound,
        `${fieldSrc}.${field_id}.source_id`,
        '-'
      ) as string;
      return sourceId === 'new' ? theme.palette.accentSecondary : 'inherit';
    }};
  }

  background-color: ${({ compound, field_id, theme, fieldSrc }) => {
    const sourceId = _.get(
      compound,
      `${fieldSrc}.${field_id}.source_id`,
      '-'
    ) as string;
    return sourceId === 'new' ? theme.palette.accentPrimary : 'inherit';
  }};

  color: ${({ compound, field_id, theme, fieldSrc }) => {
    const sourceId = _.get(
      compound,
      `${fieldSrc}.${field_id}.source_id`,
      '-'
    ) as string;
    return sourceId === 'new' ? theme.palette.textPrimary : 'inherit';
  }};

  :hover {
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
    font-weight: normal;
    color: ${(p) => p.theme.palette.accentPrimary};
  }
`;
