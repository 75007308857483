import React from 'react';
import { OrderT } from '../orders/types';
import { getValueByPath } from '@utils/misc';
import MenuButton from '@components/elements/MenuButton';
import ViewIcon from '@as_core/icons/view.icon';
import {renderOrderCost} from '@subApps/orders/utils/renderValues';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';

const ItemsInfo:string = 'Total number of items that were ordered.';
const StatusInfo: string = 'The available statuses are: success (payment complete and requests initiated) and '
+ 'complete (all requests contained in the order are completed).';

export const OrderTableFields: BasicTableFieldsT[] = [
  { value: 'view', label: '', type: 'computed', width: 40 },
  { value: 'name', label: 'Order Name', align: 'left', width: 200},
  { value: 'created', label: 'Order Date', align: 'left', type: 'date', width: 150 },
  { value: 'type', label: 'Order Type', width: 100 },
  { value: 'line_items', label: '# Line Items', info: ItemsInfo, width: 120},
  { value: 'status', label: 'Status', info: StatusInfo, width: 100},
  { value: 'cost', label: 'Total Cost', align: 'right', width: 80}
];


export const getOrderTableRows = (orders: OrderT[], handleViewClick: (id:string)=>void) => {
  const rows = [];
  orders.forEach((order) => {
    const row = {};
    for (const field of OrderTableFields) {
      if (field?.type && field.type === 'date') {
        const value = getValueByPath(order, field.value, '');
        if (value !== '') {
          const dateObj = new Date(value)
          row[field.value] = dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString();
        } else {
          row[field.value] = '';
        }
      } else if (field.value === 'view') {
        row[field.value] =
        <MenuButton
        onClick={() => handleViewClick(order?.orderId)}
        icon={<ViewIcon />}
        text={'View Order'}
        tooltipPlacement={'bottom'}
        />;
      } else if (field.value === 'status') {
        let value = order.status;
        if (order.open) value = 'In Progress';
        row[field.value] = value;
      } else if (field.value === 'line_items') {
        row[field.value] = Object.values(order.order).length;
      } else if (field.value === 'requests') {
        row[field.value] = order.requests.length;
      } else if (field.value === 'cost') {
        row[field.value] = renderOrderCost(order);
      } else if (field.value === 'payment_date') {
        row[field.value] = Number(getValueByPath(order, 'payment.amount', 0)/100.0).toLocaleString();
      } else {
        row[field.value] = order[field.value];
      }
    }
    rows.push(row);
  });
  return rows;
};