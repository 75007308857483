import React, { ReactNode } from 'react';
import Overview from '@subApps/chosmos/components/Overview';
import Search from '@subApps/chosmos/components/Search';
import PastSearches from '@subApps/chosmos/components/PastSearches';

export const getTitle = (
  action: string
): string => {
  switch(action) {
    case 'overview':
      return 'Overview';
    case 'search':
      return 'Search';
    case 'past':
      return 'Past Searches';
  }
  return 'Default Title';
}

export const getComponent = (
  view: string,
): ReactNode => {
  switch(view) {
    case 'overview':
      return <Overview />
    case 'search':
      return <Search />
    case 'past':
      return <PastSearches />
  }
  return <div>Unknown Action {view}</div>
}