// Editable.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

interface EditableProps {
  childRef?: React.MutableRefObject<HTMLTextAreaElement>;
  text?: string | number | object;
  type?: string;
  placeholder?: string;
  children: React.ReactElement[] | React.ReactElement;
  handleSubmit: () => void;
  handleReset: () => void;
  className?: string;
}

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = ({
  childRef,
  text,
  type,
  placeholder,
  children,
  handleSubmit,
  handleReset,
  ...props
}: EditableProps) => {
  // Manage the state whether to show the label or the input box. By default,
  //   label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props
  //   outside the component
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Event handler while pressing any key while editing
  const handleKeyDown = (event, type) => {
    const { key } = event;
    const isShift = event.getModifierState('Shift');
    /* 
    - For textarea, check only Escape and Tab key and set the state to false
    - For everything else, all three keys will set the state to false
    */
    switch (key) {
      case 'Escape':
        setIsEditing(false);
        handleReset();
        break;
      case 'Enter':
        if (type !== 'textarea' || isShift) {
          setIsEditing(false);
          handleSubmit();
        }
        break;
      case 'Tab':
        if (type !== 'textarea') {
          setIsEditing(false);
          handleSubmit();
        } else {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const onBlur = () => {
    setIsEditing(false);
    handleSubmit();
  };

  useEffect(() => {
    if (childRef?.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);
  /*
- It will display a label is `isEditing` is false
- It will display the children (input or textarea) if `isEditing` is true
- when input `onBlur`, we will set the default non edit mode
Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
*/
  return (
    <Container {...props}>
      {isEditing ? (
        <EditContent onBlur={onBlur} onKeyDown={(e) => handleKeyDown(e, type)}>
          {children}
        </EditContent>
      ) : (
        <Content onClick={() => setIsEditing(true)}>
          {typeof text === 'string' || typeof text === 'number'
            ? text
            : placeholder || '-'}
        </Content>
      )}
    </Container>
  );
};

export default Editable;

const Container = styled.div`
  font-size: ${(p) => p.theme.sizes.xsmall};
`;

const Content = styled.div`
  padding: 0.2rem;
  white-space: pre-line;
  cursor: text;
  word-break: break-word;
`;

const EditContent = styled(Content)`
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;
