import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { FlexItem, FlexColumn, FlexRow } from '@as_core/elements/flexStyles';
import { TextSmall } from '@as_core/elements/textStyles';
import { UserContext } from '@stores/UserContext';
import TextButton from '@components/controls/TextButton/TextButton';
import { useNavigate } from 'react-router-dom';
import { getUserSubscriptionType } from '@utils/useUserAppInfo';

const debug = false;
const UserSubscriptionUpdates = () => {
  const { user } = useContext(UserContext);
  const subscriptionType = getUserSubscriptionType(user.appInfo);
  if (debug)
    console.log(
      'UserSubscriptionUpdates | user',
      user,
      'subscription',
      subscriptionType
    );
  const navigate = useNavigate();

  const getContent = () => {
    switch (subscriptionType) {
      case 'basic':
        return (
          <UserMessage>
            <FlexItem width={'60%'}>
              <FlexColumn>
                <FlexItem>
                  <TextSmall>
                    You are current on the free subscription with limited
                    functionality.
                  </TextSmall>
                </FlexItem>
                <FlexRow v_centered>
                  <TextSmall color={'primary'}>
                    Please consider a trial or starter subscription.
                  </TextSmall>
                  <TextButton
                    label={'Upgrade Subscription'}
                    width={150}
                    height={32}
                    onClick={() => navigate('/subscriptions/order/upgrade')}
                  />
                </FlexRow>
              </FlexColumn>
            </FlexItem>
            <FlexItem width={'40%'}>
              <TextButton
                label={'Invited Pilot Users - Click to Activate via Code'}
                width={310}
                height={32}
                onClick={() => navigate('/subscriptions/order/coupon')}
              />
            </FlexItem>
          </UserMessage>
        );
      default:
        return <></>;
    }
  };

  return (
    <UserSubscriptionUpdatesContents>
      {getContent()}
    </UserSubscriptionUpdatesContents>
  );
};

export default UserSubscriptionUpdates;

const UserSubscriptionUpdatesContents = styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin-left: 10px;
  flex-direction: column;
`;

const UserMessage = styled.div`
  display: flex;
  width: calc(100% - 20px);
  padding: 10px;
  flex-direction: row;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.xsmall};

  color: ${(p) => p.theme.palette.textSecondary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;
