import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import LogoutIcon from '@mui/icons-material/Login';
import MenuButton from '@components/controls/MenuButton';
import useCognito from '@as_core/account/useCognito';
import { UserContext } from '@stores/UserContext';
import { useNavigate } from 'react-router-dom';
import AppDialogHeader from '@as_core/account/components/AppDialogHeader';
import { Row, StyledForm } from '@as_core/account/forms/FormStyles';
import { userFormFields } from '@as_core/account/forms/config';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import {
  fromDotNotation,
  renderAccountInputField,
  isValidField,
  isFormValid,
} from '@as_core/account/utils';
import UserAccountPolicies from '@as_core/account/fields/UserAccountPolicies';
import Button from '@as_core/account/fields/Button';
import { StatusMessage } from '@as_core/account/forms/SignUp';
import useUserRegistrationInfo from '@utils/useUserRegistrationInfo';
import { APP_CONFIG } from '@app_config/app';
import { getUserRegion, getUserCountryCode } from '@as_core/account/AuthWrapper';

// note -- handle all messages internally until everything is done so that a new component does not get
// created -- and maintain user information
// this is called if a user already has an account in cognito but is not registered

const debug = false;
// TODO -- add the registration page here -- decompose Sign-Up so that it uses same component
const Register = () => {
  const { user, setUser } = useContext(UserContext);
  const [userDataValidationKeys, setUserDataValidationKeys] = useState<
    string[]
  >([]);
  const [fieldUpdated, setFieldUpdated] = useState<boolean>(false);
  const [userData, setUserData] = useState({
    'address.billing': true,
    email: user.authEmail,
  });
  const [registeringUser, setRegisteringUser] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [clickedOnce, setClickedOnce] = useState<boolean>(false);
  const { logout } = useCognito();
  const { createUserRegistrationInfo } = useUserRegistrationInfo();
  const navigate = useNavigate();

  if (debug) console.log('Register | user:', user);
  const handleLogout = () => {
    logout(user?.authEmail);
    navigate('/user/login');
  };

  // set appropriate fields in the userData object
  const handleChange = (key: string, value: string) => {
    setUserData((prev) => ({ ...prev, [key]: value }));
  };

  // capture the user input to update validation state as needed
  const handleBlur = () => {
    if (clickedOnce) {
      const newIsValid = isFormValid(
        'register',
        userData,
        userDataValidationKeys,
        setErrorMessage
      );
      setIsValid(newIsValid);
      setFieldUpdated(true);
    }
  };

  const handleSubmit = () => {
    if (!clickedOnce) setClickedOnce(true);
    if (
      isFormValid('register', userData, userDataValidationKeys, setErrorMessage)
    ) {
      setRegisteringUser(true);
      // register user and set their information
      const info = fromDotNotation(userData);
      if (Object.hasOwn(info, 'password')) delete info['password']; // make sure password is not sent
      info['system'] = APP_CONFIG.system;
      if (debug) console.log('info for creating user', info);
      createUserRegistrationInfo(info)
        .then((userRegistration) => {
          if (userRegistration) {
            // as it is a new user, set to isAppAuthorized and add the zero credits
            setUser((prev) => ({
              ...prev,
              regInfo: userRegistration,
              isRegistered: true,
              userRegion: getUserRegion(userRegistration),
              userCountryCode: getUserCountryCode(userRegistration),
              isAppAuthorized: false,
            }));
            navigate('/home');
          } else {
            setRegisteringUser(false);
          }
        })
        .catch((error) => {
          setRegisteringUser(false);
          console.error('CreateUserRegistrationAPIError: ', error);
          setErrorMessage(error);
        });
    }
  };

  // set validation keys on first creation
  useEffect(() => {
    let userDataKeys = [];
    userFormFields.forEach((group) => {
      group.forEach((field) => {
        if (field.value !== 'password') userDataKeys.push(field.value);
      });
    });
    userDataKeys.push('organization.type');
    setUserDataValidationKeys(userDataKeys);
  }, []);

  return (
    <>
      {registeringUser ? (
        <StatusMessage>Registering User Information</StatusMessage>
      ) : (
        <Container key={'user-register'}>
          <AppDialogHeader
            message={'Add User Account Information'}
            tooltip={APP_CONFIG.userRegisterInfo}
          />

          <StyledForm key={'user-registration-form'}>
            <Row height={'18px'}>
              {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
            </Row>
            {userFormFields.map((row, index) => (
              <Row width={'100%'} key={'row_' + index}>
                {row.map((info, row_index) =>
                  renderAccountInputField(
                    'register',
                    info,
                    `${index}_${row_index}`,
                    userData,
                    handleChange,
                    clickedOnce && !isValidField(info.value, userData),
                    handleBlur
                  )
                )}
              </Row>
            ))}
            <Row>
              <UserAccountPolicies />
            </Row>
            <Row>
              <Button
                type={'submit'}
                label={'Register Account'}
                onClick={handleSubmit}
              />
            </Row>
            <Row>{fieldUpdated && isValid ? <></> : null}</Row>
            <Message>
              {' '}
              To exit this app sign-out:
              <MenuButton
                key={'register_logout'}
                text={'Sign Out of Account'}
                icon={<LogoutIcon />}
                onClick={handleLogout}
              />
            </Message>
          </StyledForm>
        </Container>
      )}
    </>
  );
};

export default Register;

const Container = styled.div`
  width: 600px;
  height: max-content;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
  position: relative;
`;

const Message = styled.div`
  display: flex;
  height: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
  padding-right: 10px;
  color: ${(p) => p.theme.palette.textSecondary};
  margin: 5px;
`;
