import React, { ReactComponentElement } from 'react';
import styled from 'styled-components/macro';

export type OptionT = {
  value: string;
  label: string;
  // eslint-disable-next-line
  icon: ReactComponentElement<any>;
};

type PropsT = {
  label?: string;
  selected: string;
  Options: OptionT[];
  onClick: (v: string) => void;
};

const OptionsSelector = (props: PropsT) => {
  return (
    <ViewSelectorContainer>
      {props?.label ? <Label>{props.label}</Label> : <></>}
      {props.Options.map((v, index) => (
        <ViewItem
          key={`view_item_${index}`}
          active={v.value === props.selected}
        >
          <IconButton
            key={`icon_button_${index}`}
            active={v.value === props.selected}
            onClick={() => props.onClick(v.value)}
          >
            {v.icon}
          </IconButton>
        </ViewItem>
      ))}
    </ViewSelectorContainer>
  );
};
export default OptionsSelector;

const ViewSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
`;

const ViewItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 5px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 10px;
  border: ${(p) => (p.active ? '1' : '0')}px solid
    ${(p) => p.theme.palette.backgroundQuaternary};
  background: ${(p) =>
    p.active
      ? p.theme.palette.backgroundQuaternary
      : p.theme.palette.backgroundPrimary};
  :hover {
    cursor: ${(p) => (p.active ? 'default' : 'pointer')};
    background: ${(p) =>
      p.active
        ? p.theme.palette.backgroundQuaternary
        : p.theme.palette.accentSecondary};
  }
`;

const Label = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.theme.sizes.small};
`;

const IconButton = styled.span<{ active: boolean }>`
  display: flex;
  filter: brightness(${(p) => (p.active ? 1.0 : 0.5)});
`;
