import React from 'react';
import styled from 'styled-components/macro';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import BasicTable from '@as_core/tables/BasicTable';

const overviewTableFields: BasicTableFieldsT[] = [
  { value: 'database', label: 'Database', type: 'string', width: 150 },
  { value: 'compound_count', label: '# Compounds', type: 'number', width: 150 },
  { value: 'version', label: 'Database Version', type: 'string', width: 150 },
  { value: 'last_updated', label: 'Last Updated', type: 'date', width: 150 },
];

const databaseFields: {[key: string]: string | number}[] = [
  { 'database': 'ChEMBL', 'compound_count': 500, 'version': null, 'last_updated': null },
  { 'database': 'ChEBI', 'compound_count': 1500, 'version': null, 'last_updated': null },
  { 'database': 'Coconut', 'compound_count': 2500, 'version': null, 'last_updated': null },
  { 'database': 'FAERS', 'compound_count': 3500, 'version': null, 'last_updated': null },
]

const debug = false;
const Overview = () => {
  debug && console.log('Overview');

  return (
    <Container>
      <BasicTable
        fields={overviewTableFields}
        rows={databaseFields}
        pillRows={false}
      />
    </Container>
  );
};

export default Overview;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

