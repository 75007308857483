import React from "react";
import DataSaveIcon from "@as_core/icons/dataSave.icon";
import TextButton from '@as_core/controls/buttons/TextButton';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import styled from 'styled-components/macro';

interface PropsI {
  label: string;
  path: string;
  file: string;
  width?: number;
  height?: number;
  info?: string;
  isMuted?: boolean;
  isDisabled?: boolean;
  afterClick?: () => void;
}

const debug = false;
const DownloadFileButton = (props:PropsI) => {
  const { label, path, file, width=200, height=32, isMuted=false, isDisabled=false } = props;
  if (debug) console.log('DownloadFileButton | props:', props);

  const handleClick = (path:string, file:string) => {
    const filePath = path + file;
    if (debug) console.log('filePath', filePath);
    let link=document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
    link.remove();
    props.afterClick && props.afterClick();
  }

  return(
    <Container>
      <TextButton
        label={label}
        icon={<DataSaveIcon />}
        width={width}
        height={height}
        isMuted={isMuted}
        isDisabled={isDisabled}
        onClick={()=>handleClick(path, file)}
      />
      { props?.info && (<HoverInfoText text={props.info} />) }
    </Container>
  )
}

export default DownloadFileButton;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
`