import React from 'react';
import styled from 'styled-components/macro';
import { FlexRow, FlexItem, FlexColumn } from '@components/layout/FlexStyles';
import TextButton from '@components/controls/TextButton';
import { useNavigate } from 'react-router-dom';
import GraphIcon from '@components/icons/graph.icon';
import { TextSmall, TextSmallBold } from '@components/elements/TextStyles';
import { ImportTargetPanelFinalPropsT } from './types';
const debug = false;
const ImportTargetPanelFinal = (props: ImportTargetPanelFinalPropsT) => {
  const { status } = props;
  if (debug) console.log('UploadImportTargetPanel');
  const navigate = useNavigate();
  const showStatus = () => {
    return (
      <FlexColumn>
        {status.map((s, index) => (
          <FlexRow key={index}>
            <TextSmall>{s}</TextSmall>
          </FlexRow>
        ))}
      </FlexColumn>
    );
  };
  return (
    <Container>
      <FlexColumn width={'100%'} h_centered>
        <FlexRow>
          <FlexItem>
            <TextSmallBold color='accent'>Calculation Status:</TextSmallBold>
          </FlexItem>
          <FlexItem>{showStatus()}</FlexItem>
        </FlexRow>
        <FlexRow width={'80%'} h_centered>
          <TextSmall color='accentSecondary'>
            Universe is computing it should take less than 1-4 minutes to
            compute. You will see when it is available from the Data List, when
            the View Universe Button is active
          </TextSmall>
        </FlexRow>
        <FlexRow height={'25px'} />
        <FlexRow>
          <TextButton
            text={'Data List'}
            onClick={() => navigate('/data_import/list')}
            icon={<GraphIcon size={28} />}
            tooltipPlacement={'bottom'}
            width={200}
            height={32}
            margin={'5px'}
          />
        </FlexRow>
      </FlexColumn>
    </Container>
  );
};
export default ImportTargetPanelFinal;
const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
`;