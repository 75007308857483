import _ from 'lodash';
import React, { useMemo } from 'react';
import {
  ValueRendererT,
  CompoundFieldsT,
  CompoundFieldT,
} from '@stores/fields';
import { CompoundT } from '@src/type';
import GenericRenderer from '@components/valueRenderers/GenericRenderer';
import MultipleSelectWithChips from '@as_core/components/select/MultipleSelectWithChips';
import ObjectlistRenderer from '@components/valueRenderers/ObjectlistRenderer';
import { SelectChangeEvent } from '@mui/material/Select';
import SelectField from '@as_core/components/select/SelectField';

interface HandleChangeProps {
  action: 'UPDATECOMPOUND' | 'SETVIEW' | 'ADDOBJECT' | 'REMOVEOBJECT';
  payload:
    | { field_id: string; newValue: string | number }
    | { view: string }
    | { fieldId: string; propId: string };
}

interface DisplayValueProps {
  compound: CompoundT;
  fields: CompoundFieldsT;
  fieldId: string;
  propId: string;
  handleChange: (payload: HandleChangeProps) => void;
}

const DisplayValue = (props: DisplayValueProps) => {
  const { compound, fields, fieldId, propId, handleChange } = props;

  const getRenderer = React.useCallback((): ValueRendererT => {
    const renderer = _.get(fields, `${fieldId}.value_renderer`);

    const isValidRenderer = (value: unknown): value is ValueRendererT =>
      typeof value === 'string' &&
      Object.values(ValueRendererT).includes(value as ValueRendererT);

    return isValidRenderer(renderer) ? renderer : ValueRendererT.string;
  }, [fields, fieldId]);

  const _handleChange = (event: SelectChangeEvent<unknown>) => {
    handleChange({
      action: 'UPDATECOMPOUND',
      payload: {
        field_id: fieldId,
        newValue: event.target.value as string | number,
      }, // changed propId to field_id
    });
  };

  const getValue = React.useCallback((): string | CompoundFieldT => {
    const value = _.get(compound, propId, '');
    return typeof value === 'string' || typeof value === 'number'
      ? value
      : {
          field_id: '',
          group_id: '',
          data_key: '',
          group_name: '',
          field_options: '',
          group_order: 0,
          symbol: '',
          short_name: '',
          long_name: '',
          short_description: '',
          long_description: '',
        };
  }, [compound, propId]);

  const optionsArray = useMemo(() => {
    const fieldOptions = _.get(fields, `${fieldId}.field_options`);
    return (typeof fieldOptions === 'string' ? fieldOptions : '')
      .split(';')
      .filter(Boolean)
      .map((field) => ({
        label: field,
        value: field,
      }));
  }, [fields, fieldId]);

  return (
    <>
      {getRenderer() === ValueRendererT.objectlist ? (
        <ObjectlistRenderer
          compound={compound}
          fields={fields}
          fieldId={fieldId}
          propId={propId}
          handleChange={handleChange}
        />
      ) : getRenderer() === ValueRendererT.singleselect ? (
        <SelectField
          onChange={_handleChange}
          options={optionsArray}
          value={getValue()}
          size='small'
          fullWidth
        />
      ) : getRenderer() === ValueRendererT.multiselect ? (
        <MultipleSelectWithChips
          compound={compound}
          fields={fields}
          fieldId={fieldId}
          propId={propId}
          handleChange={handleChange}
        />
      ) : (
        <GenericRenderer
          compound={compound}
          fieldId={fieldId}
          propId={propId}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

export default DisplayValue;
