import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery } from '@tanstack/react-query';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import { ListLoader } from '@as_core/elements/listStyles';
import { Row } from '../common/flexStyles';
import useStripeProducts from '@business/products/useStripeProducts';
import { HomeTileCardT } from '@as_core/tiles/HomePageTiles';
import MenuButton from '@components/elements/MenuButton';
import ShippingTipsIcon from '@components/icons/shippingTips.icon';
import SimpleTileCard from '@as_core/tiles/SimpleTileCard';
import { FlexColumn, FlexRow } from '@as_core/elements/flexStyles';
import { TextBold } from '@as_core/elements/textStyles';
import AssayInstructions from './AssayInstructions';
import BackArrowIcon from '@components/icons/backArrow.icon';

const debug = false;
const AssayOverview = () => {
  const [tiles, setTiles] = useState<HomeTileCardT[]>([]);
  const [view, setView] = useState<'instructions' | 'process'>('process');
  const { getProductCategories } = useStripeProducts();
  if (debug) console.log('AssayOverview | view', view);

  // load the available products categories
  const {
    isPending,
    isError,
    error,
    data: productCategories,
  } = useQuery({
    queryKey: ['products', 'categories'],
    queryFn: () => getProductCategories('screen'),
  });
  if (isError) console.error('error loading categories:', error.message);

  useEffect(() => {
    if (productCategories !== undefined) {
      const assayTiles: HomeTileCardT[] = productCategories.map((c) => ({
        id: c.categoryCode,
        isPinned: false,
        title: c.categoryName,
        newTab: false,
        icon: c.icon,
        link: `/orders/create/${c.categoryCode}`,
        description: c.categoryDescription,
      }));
      if (debug) console.log('AssayOverview | assayTiles', assayTiles);
      setTiles(assayTiles);
    }
  }, [productCategories]);

  if (debug)
    console.log(
      'AssayOverview | isPending: ',
      isPending,
      'productCategories',
      productCategories
    );

  return (
    <>
      <ButtonsContainer>
        { view === 'process' ?
          <MenuButton
            icon={<ShippingTipsIcon size={48} />}
            onClick={() => setView('instructions')}
            text='Order and Screening Process'
            tooltipPlacement='bottom'
            size={48}
            isActive={true}
          />
        :
          <MenuButton
            icon={<BackArrowIcon size={48} />}
            onClick={() => setView('process')}
            text='Return to Select Screens'
            tooltipPlacement='bottom'
            size={48}
            isActive={true}
          />
        }
      </ButtonsContainer>
      {!isPending && productCategories.length && tiles.length ? (
        view === 'process' ? (
          <FlexRow h_centered width={'100%'}>
            <FlexColumn width={'800px'}>
              <FlexRow>
                <TextBold color={'primary'}>
                  Select the screen you would like to order:
                </TextBold>
              </FlexRow>
              <Cards>
                {tiles.map((card) => (
                  <SimpleTileCard key={card.id} card={card} />
                ))}
              </Cards>
            </FlexColumn>
          </FlexRow>
        ) : (
          <AssayInstructions />
        )
      ) : isPending ? (
        <Row width={'100%'} v_align={'center'} h_align={'center'}>
          <ListLoader />
        </Row>
      ) : (
        <ErrorMessages
          messages={[
            <MessageItem key='emi_no_orders'>
              Error retrieving any product categories
            </MessageItem>,
          ]}
        />
      )}
    </>
  );
};

export default AssayOverview;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  width: 100%;
  margin-top: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: max-content;
  margin-left: auto;
  margin-right: 32px;
  gap: 16px;
`;
