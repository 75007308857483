import React, { useState } from 'react';
import styled from 'styled-components/macro';

import useCompounds from '@components/compounds/useCompounds';
import PropertiesTile from './components/PropertiesTile';
import NewValuesTile from './components/NewValuesTile';
import TextButton from '@components/controls/TextButton';
import BackArrowIcon from '@components/icons/backArrow.icon';
import NewCompoundIcon from '@components/icons/newCompound.icon';
import DataSaveIcon from '@components/icons/dataSave.icon';
import ExitAreaIcon from '@components/icons/exitArea.icon';
import { useNavigate } from 'react-router-dom';
import MolImgTile from '@components/compounds/images/MolImgTile';
import { useSelector } from 'react-redux';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import { RootState } from '@src/store';
import { FieldSliceT } from '@stores/fields';
import { CompoundMetaT } from '@pages/PgCompoundsAdd';
import { CompoundT } from '@src/type';
import Alert from '@components/elements/Alert';

interface ReviewStructurePropsT {
  compound: CompoundT;
  compoundMeta: CompoundMetaT;
  priorStep: () => void;
}

let buttonWidth = 220;
let buttonHeight = 40;
let buttonMargin = '4px 10px';

const edit_fields = ['compound_name'];

const physchem_fields = [
  'formula',
  'exact_mol_wt',
  'heavy_atom_count',
  'rotatable_bonds',
  'aromatic_rings',
  'lipinski_hba',
  'lipinski_hbd',
  'polar_surface_area',
  'crippen_log_p',
  'c_sp3',
  'fs_p3',
];

const debug = false;
const ReviewStructure = (props: ReviewStructurePropsT) => {
  const { compound, compoundMeta, priorStep } = props;
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  if (debug) console.log('ReviewStructure | fields:', allFields);
  if (debug) console.log('ReviewStructure | compound:', compound);
  if (debug) console.log('ReviewStructure | compoundMeta:', compoundMeta);
  const navigate = useNavigate();

  const { updateCompound } = useCompounds();

  let mol_svg_json = compoundMeta?.svg;

  const addCompound = async (exit: boolean) => {
    if (debug) console.log('ReviewStructure | addCompound compound:', compound);

    if (
      !compound?.cfields?.compound_name ||
      compound?.cfields?.compound_name?.value === ''
    ) {
      setAlertOpen(true);
      setAlertMessage(
        'Compound name is required. Please enter a compound name.'
      );
    } else {
      try {
        // 1: save the compound, get the compound uuid
        const localCompound = {
          ...compound,
          mol_svg: mol_svg_json,
          structure: compoundMeta.structure,
        };
        await updateCompound(localCompound);
        if (exit) {
          // 3: navigate to the details page
          navigate(`/compounds/user/table`);
        } else {
          priorStep();
        }
      } catch (ex) {
        console.error('ERROR: failed to save compound.', ex);
      }
    }
  };

  return (
    <ReviewStructureContainer>
      <Review>
        <Alert
          type={'general'}
          title={'Issue Saving Compound'}
          message={alertMessage}
          alertOpen={alertOpen}
          closeAlert={() => {
            setAlertOpen(false);
            setAlertMessage('');
          }}
        />
        <ReviewBanner>
          <TextButton
            text={'Edit Structure'}
            onClick={priorStep}
            icon={<BackArrowIcon size={32} />}
            width={buttonWidth}
            height={buttonHeight}
            margin={buttonMargin}
          />
        </ReviewBanner>
        <CompoundInformationContainer>
          <FlexRow>
            <FlexItem width={'50%'}>
              <Column>
                <Title>Add Compound Information</Title>
                <NewValuesTile compound={compound} fields={edit_fields} />
                <Title>Molecular Structure</Title>
                <ImgContainer>
                  <MolImgTile mol_svg={mol_svg_json} width={280} />
                </ImgContainer>
              </Column>
            </FlexItem>
            <FlexItem width={'50%'}>
              <Column>
                <Title>Molecular Properties</Title>
                {Object.hasOwn(compound, 'cfields') ? (
                  <PropertiesTile
                    compound={compound}
                    fields={physchem_fields}
                  />
                ) : (
                  <></>
                )}
              </Column>
            </FlexItem>
          </FlexRow>
        </CompoundInformationContainer>
        <ReviewFooter>
          <TextButton
            text={'Save + New Molecule'}
            onClick={() => addCompound(false)}
            icon={
              <>
                <DataSaveIcon size={28} />
                <NewCompoundIcon size={24} />
              </>
            }
            width={buttonWidth}
            height={buttonHeight}
            margin={buttonMargin}
            iconRight={true}
          />
          <TextButton
            text={'Save + Exit'}
            onClick={() => addCompound(true)}
            icon={
              <>
                <DataSaveIcon size={28} />
                <ExitAreaIcon size={28} />
              </>
            }
            width={buttonWidth}
            height={buttonHeight}
            margin={buttonMargin}
            iconRight={true}
          />
        </ReviewFooter>
      </Review>
    </ReviewStructureContainer>
  );
};

export default ReviewStructure;

const ReviewStructureContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

const ReviewBanner = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ReviewFooter = styled.div`
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  vertical-align: top;
  height: max-content;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.textPrimary};
`;

const Review = styled.div`
  position: relative;
  width: 850px;
  height: 500px;
  margin: 0 auto;
`;

const CompoundInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  border-radius: 15px;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;

const ImgContainer = styled.div`
  width: 280px;
  height: 280px;
`;
