import React from 'react';
import { InputAdornment } from '@mui/material';
import InputField, { IInputField } from './InputField';
import { Search } from '@mui/icons-material';
import styled from 'styled-components/macro';

const SearchField = (props: IInputField) => {
  return (
    <StyledInputField
      {...props} // Spread all props here
      placeholder={props.placeholder ?? 'Search ...'}
      variant={props.variant || 'standard'}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

const StyledInputField = styled(InputField)`
  font-size: ${(p) => p.theme.sizes.xxsmall};
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    .MuiInput-underline {
      border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary} !important;
      border-radius: unset;
    }
  }

  & .MuiFormLabel-root {
    font-size: ${(p) => p.theme.sizes.xsmall};

    padding: 0;
    color: ${(p) => p.theme.palette.accentSecondary};
  }
  & .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  }
  & .MuiInput-underline {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary};
    border-radius: unset;
  }
  &.MuiInput-underline:hover {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
    border-radius: unset;
  }
  & .MuiInput-underline.Mui-focused:after {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  }
  & .MuiAutocomplete-paper {
    color: red;
    font-size: ${(p) => p.theme.sizes.xxsmall};
  }
  & .MuiAutocomplete-option.Mui-focused {
    font-size: ${(p) => p.theme.sizes.xxsmall};
  }
`;

export default SearchField;
