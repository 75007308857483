import React, {useState, useEffect} from "react";
import styled from "styled-components/macro";
import {StripeProductT} from "@business/products/useStripeProducts";
import BasicTable from "@as_core/tables/BasicTable";
import {
  getShowPrice,
  getAddToCart,
  getMinOrderPrice,
  getShowUnit,
  getShowNameDescription,
} from '@business/products/components/utils';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';

type FieldsT = {value: string, label: string, width: number, align?: string};
const fields: {[key: string]: FieldsT[]} = {
  'narrow': [
    {value: 'addToCart', label: '', width: 50},
    {value: 'nameDescription', label: 'Screening Product/Description', width: 450, align: 'left'},
    {value: 'showUnit', label: 'Unit', width: 125},
    {value: 'showPrice', label: 'Unit Price', width: 100, align: 'right'}
  ],
  'wide': [
    {value: 'addToCart', label: '', width: 50},
    {value: 'name', label: 'Screening Product', width: 225, align: 'left'},
    {value: 'description', label: 'Description', width: 400, align: 'left'},
    {value: 'showUnit', label: 'Unit', width: 125},
    {value: 'showPrice', label: 'Unit Price', width: 100, align: 'right'}
  ]
};

function getTableFields(width: number): FieldsT[] {
  if (width < 1200) return fields['narrow'];
  return fields['wide']
}

type PropsT = {
  orgType: string,
  products: StripeProductT[],
  onSelect: (product: StripeProductT, priceType: string) => void;
}
const debug = false;
const ProductsTable = (props:PropsT) => {
  const [rows, setRows] = useState<StripeProductT[]>([]);
  const {height: windowHeight, width: windowWidth} = useWindowDimensions();
  const [tableFields, setTableFields] = useState<FieldsT[]>(getTableFields(windowWidth));
  const {orgType, products} = props;
  if (debug) console.log('Products Table', products, windowHeight, windowWidth);

  useEffect(()=>{
    const updatedRows = products.map((p) => (
      { ...p,
        showPrice: getShowPrice(orgType, p),
        addToCart: getAddToCart(orgType, p, props.onSelect),
        minOrderPrice: getMinOrderPrice(orgType, p),
        showUnit: getShowUnit(p),
        nameDescription: getShowNameDescription(p),
      }
    ))
    setRows(updatedRows)
  },[products]);

  useEffect(()=>{
    setTableFields(getTableFields(windowWidth))
  },[windowWidth]);

  return(
    <ProductsTableContainer>
      <BasicTable
        fields={tableFields}
        rows={rows}
        pillRows={false}
      />
    </ProductsTableContainer>
  )
}

export default ProductsTable;

const ProductsTableContainer = styled.div`
  display: flex;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
