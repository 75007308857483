import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import MenuButton from '@components/elements/MenuButton';
import {
  FlexBanner,
  FlexBannerGroup,
  FlexItem,
} from '@components/layout/FlexStyles';
import EditIcon from '@components/icons/edit.icon';
import NearNeighborsIcon from '@components/icons/nearNeighbors.icon';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import { FieldSliceT } from '@stores/fields';
import BackArrowIcon from '@components/icons/backArrow.icon';
import { useNavigate } from 'react-router-dom';
import SearchField from '@as_core/components/inputs/SearchField';
import { CompoundT } from '@src/type';
import { INeighbor } from '@components/compounds/neighbors/SimilarityCard';

const debug = false;

interface DetailBannerProps {
  view: string; // 'main' | 'neighbors' | 'notes'
  searchText: string;
  updateSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSetView: (view: string) => void;
  handleNotesChange: () => void;
  compound?: CompoundT;
  neighbors?: INeighbor[];
}

const DetailBanner = ({
  view,
  searchText,
  updateSearch,
  handleKeyDown,
  handleSetView,
  handleNotesChange,
  compound,
  neighbors,
}: DetailBannerProps) => {
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );

  const getTitle = () => {
    const name = _.get(compound, allFields['compound_name']?.data_key, '');
    const casrn = _.get(compound, allFields['casrn']?.data_key, '');
    let title = name;
    if (casrn !== '') {
      title = title + ' (' + casrn + ')';
    }
    return title;
  };

  const navigate = useNavigate();
  const handleBack = () => {
    if (debug) console.log('CompoundCompareData | Handle Back');
    navigate(-1);
  };

  if (debug) console.log('DetailBanner | neighbors:', neighbors);

  return (
    <FlexBanner>
      <FlexBannerGroup>
        <MenuButton
          text={'Return to prior view'}
          onClick={handleBack}
          icon={<BackArrowIcon size={32} />}
          width={220}
        />
        <Title>{getTitle()}</Title>
      </FlexBannerGroup>
      <FlexBannerGroup>
        <FlexItem width={'500px'}>
          <SearchField
            value={searchText}
            onChange={updateSearch}
            onKeyDown={handleKeyDown}
            placeholder='Search ...'
          />
        </FlexItem>
      </FlexBannerGroup>
      <FlexBannerGroup>
        <MenuButton
          onClick={
            neighbors?.length ? () => handleSetView('neighbors') : () => {}
          }
          notificationCount={neighbors?.length}
          icon={<NearNeighborsIcon />}
          isActive={view === 'neighbors'}
          text={'Tanimoto Neighbors'}
          tooltipPlacement={'bottom'}
        />
        <MenuButton
          onClick={handleNotesChange}
          icon={<EditIcon />}
          isActive={view === 'notes'}
          text={'Notes'}
          tooltipPlacement={'bottom'}
        />
      </FlexBannerGroup>
    </FlexBanner>
  );
};

export default DetailBanner;

const Title = styled.h2`
  padding-left: 10px;
  white-space: nowrap;
  font-size: ${(p) => p.theme.sizes.xxlarge};
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.heavy};
  color: ${(p) => p.theme.palette.titlePrimary};
`;
