import React from 'react';
import styled from 'styled-components/macro';
import { DeleteOutline } from '@mui/icons-material';

import { ScrollBox } from '@components/scrollbox/scrollbox';
import { FilterDisplayI } from './filter.types';
import FilterSlider from './filters/filter.slider';
import FilterString from './filters/filter.string';
import FilterSelect from './filters/filter.select';
import FilterLogical from './filters/filter.logical';

// Handle the different types of filters
const debug = false;
const renderFilter = (f, info, handleFilterUpdate) => {
  if (debug) console.log('filter: ', f, 'info', info);
  if (f.type === 'logical') {
    return (
      <FilterLogical
        key={f}
        filterName={info.long_name}
        filter={f}
        handleUpdate={handleFilterUpdate}
      />
    );
  } else if (f.type === 'slider') {
    return (
      <FilterSlider
        key={f}
        filterName={info.long_name}
        filter={f}
        handleUpdate={handleFilterUpdate}
      />
    );
  } else if (f.type === 'string') {
    return (
      <FilterString
        key={f}
        filterName={info.long_name}
        filter={f}
        handleUpdate={handleFilterUpdate}
      />
    );
  } else if (f.type === 'select') {
    let options = info?.field_options;
    if (debug) console.log('options', options); // TODO Incase options not available -- set to selected
    if (!options) {
      options = f.value[0];
    }
    return (
      <FilterSelect
        key={f}
        filterName={info.long_name}
        filter={f}
        options={options}
        handleUpdate={handleFilterUpdate}
      />
    );
  } else {
    return (
      <>
        Filter not implemented for {f.field_id} of type {info.value_renderer}
      </>
    );
  }
};

// Display all the set filters
const FilterDisplay = ({
  filters,
  fieldInfo,
  handleFilterUpdate,
  filterDelete,
}: FilterDisplayI) => {
  if (debug) console.log('FilterDisplay {filters}', filters);
  // filter out errant empties -- fix this later
  const valid_filters = filters.filter((v) => Object.keys(v).length !== 0);

  return (
    <ScrollBox scrollX={false}>
      <Filters>
        {valid_filters.map((f, id) => (
          <FilterRow key={id}>
            <FilterItem>
              {renderFilter(f, fieldInfo[f.field], handleFilterUpdate)}
            </FilterItem>
            <FilterItem>
              <StyledDeleteOutline onClick={() => filterDelete(f.uuid)} />
            </FilterItem>
          </FilterRow>
        ))}
      </Filters>
    </ScrollBox>
  );
};

export default FilterDisplay;

const Filters = styled.label`
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const FilterRow = styled.label`
  display: flex;
  padding: 5px;
  font-size: ${(p) => p.theme.sizes.xsmall};

  width: 550px;
  margin-bottom: 7px;
  align-items: center;
  flex-direction: row;
  border-radius: 15px;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border: 1px solid ${(p) => p.theme.palette.backgroundTertiary};
`;

const FilterItem = styled.label`
  display: flex;
`;

const StyledDeleteOutline = styled(DeleteOutline)`
  color: ${(p) => p.theme.palette.accentPrimary};
`;
