import React from "react";
import DataUploadIcon from "@as_core/icons/dataUpload.icon";
import TextButton from '@as_core/controls/buttons/TextButton';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import styled from 'styled-components/macro';

interface PropsI {
  label: string;
  onClick?: () => void;
  width?: number;
  height?: number;
  info?: string;
  isMuted?: boolean;
  isDisabled?: boolean;

}

const debug = false;
const UploadFileButton = (props:PropsI) => {
  const { label, width=200, height=32, isMuted=false, isDisabled=false } = props;
  if (debug) console.log('DownloadFileButton | props:', props);

  const handleClick = () => {
    props.onClick && props.onClick();
  }

  return(
    <Container>
      <TextButton
        label={label}
        icon={<DataUploadIcon />}
        width={width}
        height={height}
        isMuted={isMuted}
        isDisabled={isDisabled}
        onClick={()=>handleClick()}
      />
      { props?.info && (<HoverInfoText text={props.info} />) }
    </Container>
  )
}

export default UploadFileButton;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
`
