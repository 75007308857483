import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';

interface ParameterImageViewerProps {
  config?: Array<Record<string, string>>;
  height?: number;
  width?: number;
}

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';

const ParameterImageViewer = (props: ParameterImageViewerProps) => {
  const { config, height = 250, width = 400 } = props;
  if (debug) console.log('ParameterImageViewer | props', props);

  // STATES
  const [activeTab, setActiveTab] = useState(config[0]['label']);

  const handleClick = (e) => {
    if (debug) console.log('MeasurementsViewer | e ', e);
    setActiveTab(e);
  };

  const displayImage = () => {
    const matched = config.find((c) => c.label === activeTab);
    const img_src = _.get(matched, 'image', '');
    if (debug) console.log('MeasurementsViewer | matched:', matched);
    if (img_src) {
      return (
        <img
          width={'100%'}
          src={process.env.PUBLIC_URL + img_src}
          alt={activeTab}
        />
      );
    } else {
      return <div>Unable to find image for &quot;{activeTab}&quot;</div>;
    }
  };

  return (
    <ImageContainer height={height} width={width}>
      <ImageWrapper>{displayImage()}</ImageWrapper>
      <TabWrapper>
        {config.map((param, i) => (
          <Tab
            key={'zf_' + i}
            className={param.label === activeTab ? 'active' : ''}
            onClick={() => handleClick(param.label)}
          >
            {param.label}
          </Tab>
        ))}
      </TabWrapper>
    </ImageContainer>
  );
};

const ImageContainer = styled.div<{ height: number; width: number }>`
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  margin-right: 10px;
  float: left;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  padding: 0;
  justify-content: center;
`;

const TabWrapper = styled.div`
  color: ${(p) => p.theme.palette.titlePrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const Tab = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.sizes.small};
  &.MuiButtonBase-root {
    background-color: ${(p) => p.theme.palette.accentSecondary};
    border: 1px solid ${(p) => p.theme.palette.backgroundSecondary};
    border-radius: 5px;
    margin: 0 2px 2px;
    padding: 10px;
    width: max-content;
    height: 16px;
  }
  &.active {
    background-color: ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.textPrimary};
  }
  :hover {
    border: 1px solid ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.textSecondary};
  }
`;

export default ParameterImageViewer;
