import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import {
  ColumnsInit,
  ColumnT,
  LibrariesInit,
  LibraryT,
  UseFileImportContextT,
  workbookT,
} from './useFileImport';
import { LibrarySearchRequestT } from '@utils/useLibrarySearch';
import { APP_CONFIG } from "@app_config/app";
import UploadFile from './UploadFile';
import ImportCompoundSet from './importCompoundSet/ImportCompoundSet';
import ImportTargetPanelNarrow from './importTargetPanel/ImportTargetPanelNarrow';
import ImportTargetPanel from './importTargetPanel/ImportTargetPanel';
import ImportDoseResponse from './importDoseResponse/ImportDoseResponse';
import ImportGeneric from './importGeneric/ImportGeneric';
import { FileImportContext } from '@stores/FileImportContext';
import { useNavigate } from "react-router-dom";
import { UserContext } from "@stores/UserContext";
import AbbvieUpload from '@components/abbvieUpload';
import { getUserSubscriptionType } from "@utils/useUserAppInfo";
import { UploadOptionT } from '@src/type';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
const INITIAL_STEP = 1;
const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
// Define file type options only once here
const FileImportOptions = [
  {
    label: 'Compound Set w/ Data',
    value: 'compound_set',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
  },
  {
    label: 'Target Panel (Universe) - Wide',
    value: 'target_panel',
    info: 'This wide data format uses one row per compound with targets as columns.',
    subscriptions: ['pilot'],
  },
  {
    label: 'Target Panel (Universe) - Narrow',
    value: 'target_panel_narrow',
    info: 'Narrow format with multiple rows per compound for different targets.',
    subscriptions: ['pilot'],
  },
  {
    label: 'Dose Response Curve',
    value: 'dose_response',
    info: 'Requires [compound ID, sample ID, dose, response]. Multiple dose-response sets per compound.',
    subscriptions: ['pilot'],
  },
  {
    label: 'Generic Plotting Data',
    value: 'generic',
    info: 'Generic data import. All rows/columns are imported as-is.',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
  },
  {
    label: 'Abbvie Data',
    value: 'abbvie',
    info: 'Custom format for Abbvie data.',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
    system: ['3rnd-abbvie'],
  },
];
const UploadData = () => {
  const [workbook, setWorkbook] = useState<workbookT>();
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const [importer, setImporter] = useState('compound_set'); // default = compound_set
  const [fileLoaded, setFileLoaded] = useState(false);
  const [activeColumn, setActiveColumn] = useState<number>(null);
  const [columns, setColumns] = useState<ColumnT[]>(ColumnsInit);
  const [libraries, setLibraries] = useState<LibraryT[]>(LibrariesInit);
  const [requests, setRequests] = useState<LibrarySearchRequestT[]>([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { user } = useContext(UserContext);
  const userSubscriptionType = getUserSubscriptionType(user.appInfo);
  const navigate = useNavigate();
  // Filter options based on user subscription
  const filteredUploadOptions: UploadOptionT[] = FileImportOptions.filter((opt) =>
    (!opt.subscriptions || opt.subscriptions.includes(userSubscriptionType)) &&
    (!opt.system || opt.system.includes(APP_CONFIG.system))
  ).map(({ label, value, info }) => ({ label, value, info }));
  // Separate out the "Compound Set w/ Data" from the rest.
  const basicOption = filteredUploadOptions.find(
    (opt) => opt.value === 'compound_set'
  );
  // Everything else
  const advancedOptions = filteredUploadOptions.filter(
    (opt) => opt.value !== 'compound_set'
  );
  const context: UseFileImportContextT = {
    workbook, setWorkbook,
    activeStep, setActiveStep,
    columns, setColumns,
    activeColumn, setActiveColumn,
    importer, setImporter,
    fileLoaded, setFileLoaded,
    libraries, setLibraries,
    requests, setRequests,
  };
  const handleReset = () => {
    if (debug) console.log('UploadData | Reset()');
    context.setFileLoaded(false);
    context.setWorkbook(null);
    context.setActiveColumn(null);
    context.setColumns(ColumnsInit);
    context.setLibraries(LibrariesInit);
    context.setActiveStep(INITIAL_STEP);
    navigate('/data_import/upload');
  };
  const handleImporterSelect = (value: string) => {
    setImporter(value);
  };
  const validImporters = filteredUploadOptions.map((o) => o.value);
  const showImporterComponent = context.workbook && validImporters.includes(context.importer);
  const renderImporter = () => {
    switch (context.importer) {
      case 'compound_set':
        return <ImportCompoundSet handleReset={handleReset} />;
      case 'target_panel':
        return <ImportTargetPanel handleReset={handleReset} />;
      case 'target_panel_narrow':
        return <ImportTargetPanelNarrow formatType={'narrow'} handleReset={handleReset} />;
      case 'dose_response':
        return <ImportDoseResponse handleReset={handleReset} />;
      case 'generic':
        return <ImportGeneric handleReset={handleReset} />;
      case 'abbvie':
        return <AbbvieUpload />;
      default:
        return null;
    }
  };
  const toggleShowAdvanced = () => {
    setShowAdvanced((prev) => !prev);
  };
  return (
    <FileImportContext.Provider value={context}>
      <PageContainer>
        {!showImporterComponent ? (
          <ContentWrapper>
            <LeftColumn>
              <SectionTitle>File Type</SectionTitle>
              {/* If we have a basicOption: Show "Compound Set w/ Data" */}
              {basicOption && (
                <OptionButton
                  isActive={importer === basicOption.value}
                  onClick={() => handleImporterSelect(basicOption.value)}
                  title={basicOption.info || ''}
                  isAdvanced={false}
                >
                  {basicOption.label}
                </OptionButton>
              )}
              {/* Button to reveal the rest (Advanced) */}
              <ContainerCollapse>
              <ListItemButton
                onClick={toggleShowAdvanced}
                sx={{ pl: 2 }}
                style={{
                  border: '2px solid rgb(214, 11, 81)',
                  borderRadius: 4,
                  marginTop: 8,
                  maxHeight: '51px',
                  zIndex:2
                }}
              >
                <ListItemIcon>
                  {showAdvanced ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <ListItemText primary="Advanced Upload" />
              </ListItemButton>

              <AdvancedCollapse in={showAdvanced} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {advancedOptions.map((option) => {
                    const isActive = importer === option.value;
                    return (
                      <OptionButton
                        key={option.value}
                        isActive={isActive}
                        onClick={() => handleImporterSelect(option.value)}
                        title={option.info || ''}
                        style={{ margin: '8px 16px' }}
                        isAdvanced={true}
                      >
                        {option.label}
                      </OptionButton>
                    );
                  })}
                </List>
              </AdvancedCollapse>
              </ContainerCollapse>
            </LeftColumn>
            <RightColumn>
              <InstructionsTitle>Select file type and then upload your files</InstructionsTitle>
              <UploadContainer>
                <UploadFile
                  choices={filteredUploadOptions}
                  onChange={(e) => setImporter(e.target.value)}
                />
              </UploadContainer>
            </RightColumn>
          </ContentWrapper>
        ) : (
          <ImporterContainer>{renderImporter()}</ImporterContainer>
        )}
      </PageContainer>
    </FileImportContext.Provider>
  );
};
export default UploadData;
/** Styled Components **/
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 105px);
  width: calc(100vw - 50px);
  color: ${(p) => p.theme.palette.textPrimary};
  overflow-y: auto;
`;
const ContentWrapper = styled.div`
  display: flex;
  width: max-content;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
`;
const LeftColumn = styled.div`
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 665px;
`;
const SectionTitle = styled.h2`
  font-size: 1.4rem;
  color: ${(p) => p.theme.palette.accentPrimary};
  margin-bottom: 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  padding-bottom: 5px;
`;
const InstructionsTitle = styled.h3`
  font-size: 1.2rem;
  color: ${(p) => p.theme.palette.accentPrimary};
  margin-bottom: 20px;
`;
// Add an extra prop to OptionButton: { isActive: boolean; isAdvanced?: boolean }
const OptionButton = styled.button<{ isActive: boolean; isAdvanced?: boolean }>`
  background: ${({ isActive, theme }) => (isActive ? theme.palette.accentPrimary : 'transparent')};
  color: ${({ isActive, theme }) => (isActive ? theme.palette.textPrimary : theme.palette.accentSecondary)};
  border: 2px solid ${({ theme }) => theme.palette.accentPrimary};
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  transition:
    background 0.3s ease,
    color 0.3s ease,
    transform 0.2s ease,
    box-shadow 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Use the isAdvanced prop to set width */
  width: ${({ isAdvanced }) => (isAdvanced ? '94.5%' : '100%')};
  &:hover {
    background: ${({ theme }) => theme.palette.accentPrimary};
    color: ${({ theme }) => theme.palette.textPrimary};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(70, 130, 180, 0.5);
  }
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  /* Icon indicating selectable option */
  &::after {
    content: '${({ isActive }) => (isActive ? '✔️' : '□')}';
    font-size: 1.2rem;
    margin-left: 10px;
    transition: transform 0.2s ease;
  }
`;
const UploadContainer = styled.div`
  background: ${(p) => p.theme.palette.backgroundSecondary};
  padding: 20px;
  border: 1px dashed ${(p) => p.theme.palette.accentPrimary};
  border-radius: 10px;
`;
const ImporterContainer = styled.div`
  width: 100%;
  margin: 20px auto 0 auto;
`;

const AdvancedCollapse = styled(Collapse)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(60px);
  z-index: 9999;
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
  border-radius: 8px;
  padding: 8px;
  min-width: 260px;
`;

const ContainerCollapse = styled.div`
  position: relative;
`;