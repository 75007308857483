import React from 'react';
import styled from 'styled-components/macro';
import DataLoading from "@as_core/elements/DataLoading";
import { getComponent } from './utils/actions';

type PropsT = {
  view: string;
}

const debug = false;
const Chosmos = (props:PropsT) => {
  const { view } = props;
  const isPending = false; // placehold for any data context loading

  debug && console.log('chosmos | view:', view, 'isPending', isPending);

  return (
    <ChosmosContainer>
      {
        isPending ? <DataLoading />
      :
        getComponent(view)
      }
    </ChosmosContainer>
  );
};

export default Chosmos;

const ChosmosContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

