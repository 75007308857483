import React from 'react';
import styled from 'styled-components/macro';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import { Row } from '../common/flexStyles';
import { RequestT } from '../hooks/useRequests';
import { getValueByPath } from '@utils/misc';

export const getTitleInfo = (action: string, request: RequestT) => {
  return (
    <>
      {getValueByPath(request, 'name', '') + ' '}
      <HoverInfoText text={getValueByPath(request, 'description', '')} />
    </>
  );
};

type PropsT = {
  action: string;
  request: RequestT;
};

const ReportInfoHeader = (props: PropsT) => {
  return (
    <InfoHeaderContainer>
      <Row>{getTitleInfo(props.action, props.request)}</Row>
    </InfoHeaderContainer>
  );
};
export default ReportInfoHeader;

const InfoHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
`;
