import _ from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import { ltrim } from '@utils/misc';
import { CompoundFieldsT } from '@stores/fields';
import { CompoundT } from '@src/type';
import SelectField from '@as_core/components/select/SelectField';

interface HandleChangeProps {
  action: 'UPDATECOMPOUND' | 'SETVIEW' | 'ADDOBJECT' | 'REMOVEOBJECT';
  payload:
    | { field_id: string; newValue: string | number } // updated field_id
    | { view: string }
    | { fieldId: string; propId: string };
}

interface IMultipleSelect {
  compound: CompoundT;
  fields: CompoundFieldsT;
  fieldId: string;
  propId: string;
  handleChange: (payload: HandleChangeProps) => void;
}

const MultipleSelect = (props: IMultipleSelect) => {
  const { compound, fields, fieldId, propId, handleChange } = props;

  const _handleChange = (event) => {
    const value = ltrim(_.get(event, 'target.value', []).join(';'), ';');
    handleChange({
      action: 'UPDATECOMPOUND',
      payload: { field_id: fieldId, newValue: value }, // added field_id
    });
  };

  const getValue = React.useCallback(
    (propId: string): string[] => {
      const value = _.get(compound, propId, '').split(';');
      if (value.length === 1 && value[0] === '') {
        return [];
      } else {
        return value;
      }
    },
    [compound]
  );

  const getOptions = React.useCallback(
    (fieldId: string): string[] => {
      const options = _.get(fields, `${fieldId}.field_options`, '');
      return typeof options === 'string' ? options.split(';') : [];
    },
    [fields]
  );

  return (
    <StyledFormControl>
      <SelectField
        value={getValue(propId)}
        onChange={_handleChange}
        multiple
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={(selected) => (
          <Chips>
            {(selected as string[]).map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Chips>
        )}
        size='small'
        fullWidth
        options={getOptions(fieldId).map((name) => ({
          label: name,
          value: name,
        }))}
      />
    </StyledFormControl>
  );
};

export default MultipleSelect;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;

  & .MuiChip-root {
    color: ${(props) => props.theme.palette.textPrimary};
    font-family: ${(props) => props.theme.fonts.main};
    font-weight: ${(props) => props.theme.weights.light};
    font-size: ${(props) => props.theme.sizes.small};
    background-color: ${(props) => props.theme.palette.accentPrimary};
  }
`;

const StyledFormControl = styled(FormControl)`
  color: ${(props) => props.theme.palette.textPrimary};
  margin: ${(props) => props.theme.sizes.xsmall};
  min-width: ${(props) => props.theme.sizes.xsmall};
  border: ${(p) => p.theme.borders.thin};

  & .MuiSelect-icon {
    color: ${(props) => props.theme.palette.accentPrimary};
  }
`;
