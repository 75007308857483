import React from 'react';
import { MenuItemsT } from '@as_core/menus/SideMenu';
import DashboardIcon from '@components/icons/dashboard.icon';
import SearchIcon from '@components/icons/search.icon';
import OrdersActiveIcon from '@components/icons/orders.active';
import OrdersPastIcon from '@components/icons/orders.past';

export const chosmosMenuItems: MenuItemsT[] = [
  {
    id: 'chemporiumDashboard',
    name: 'Overview',
    pathname: '/chemporium/overview',
    icon: <DashboardIcon />,
  },
  {
    id: 'chemporiumSearch',
    name: 'Search',
    pathname: '/chemporium/search',
    icon: <SearchIcon />,
  },
  {
    id: 'chemporiumOrders',
    name: 'Orders',
    pathname: '/chemporium/orders',
    icon: <OrdersActiveIcon />,
  },
  {
    id: 'chemporiumPast',
    name: 'Past Orders',
    pathname: '/chemporium/past',
    icon: <OrdersPastIcon />,
  },
];

const debug = false;

export const getChemporiumLogo = () => {
  return '/img/Chosmos_Logo_notext.svg';
};

export const getChemporiumMenus = (subscription: string) => {
  if (debug) console.log('getMenuAccount | {subscription}:', subscription);
  return chosmosMenuItems;
};
