import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const SearchIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "#F2F2F2" : "#8F8F8F";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M19.7415 18.4843L15.5507 14.2762C16.6283 13.0396 17.2186 11.4836 17.2186
        9.86374C17.2186 6.07913 14.0293 3 10.1093 3C6.1893 3 3 6.07913 3 9.86374C3 13.6484 6.1893 16.7275
        10.1093 16.7275C11.581 16.7275 12.9833 16.299 14.1823 15.4854L18.405 19.7255C18.5815 19.9024
        18.8189 20 19.0732 20C19.314 20 19.5425 19.9114 19.7159 19.7502C20.0843 19.4079 20.0961 18.8403
        19.7415 18.4843ZM10.1093 4.79054C13.0068 4.79054 15.364 7.06632 15.364 9.86374C15.364 12.6612
        13.0068 14.9369 10.1093 14.9369C7.21181 14.9369 4.85461 12.6612 4.85461 9.86374C4.85461 7.06632
        7.21181 4.79054 10.1093 4.79054Z"/>
      </svg>
    </>
  )
}

export default SearchIcon;