import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import {useQuery} from "@tanstack/react-query";
import { UserContext } from '@stores/UserContext';
import { useParams } from 'react-router-dom';
import DataLoading from "@as_core/elements/DataLoading";
import PageContainer from '@as_core/pages/PageContainer';
import {isSubscribed} from "./gostarMenus";
import Gostar from './Gostar';
import { getTitle } from './utils/actions';
import { NotSubscribedMessage } from './gostarMenus';
import { getUserSubscriptionType } from '@utils/useUserAppInfo';

const debug = false;
const PgGostar = () => {
  const { view } = useParams();
  const { user, setUser } = useContext(UserContext);
  const subscription = getUserSubscriptionType(user.appInfo);
  const appTitle = <img src='/img/GOSTAR_Logo_text_only.svg' alt='Logo' height={24} />;

  // make sure correct subApp selected for left side menus
  if (user.subApp !== 'gostar') setUser((prev) => ({ ...prev, subApp: 'gostar' }));

  // load requests for the specific defined action
  const { isPending, error, data: partnerAuthorization } = useQuery({
    queryKey: ['partnerAuthorization', user.authId], queryFn: () => {
    },
  });

  debug && console.log('PgRequestsPartners | view:', view, 'isPending', isPending, 'error', error, 'vendorAuthorizations:', partnerAuthorization);

  return (<PageContainer appTitle={appTitle} title={getTitle(view)} showRepo={false}>
    <Container>
      {!isSubscribed(subscription) && view !== 'overview' ? <NotSubscribedMessage /> : isPending ? <DataLoading /> :
      <Gostar view={view} />}
    </Container>
  </PageContainer>);
};

export default PgGostar;

const Container = styled.div`
    font-size: 24px;
    color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  & ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
  }
  & ::-webkit-scrollbar-track {
      background: transparent;
  }
  & ::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.palette.accentPrimary};
      border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
      background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

