import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const ShippingTipsIcon = ({ size = 24 }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = style.name === 'dark' ? 'white' : 'black';
  const colorHighlight = style.palette.accentPrimary;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 512 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/* Background paths */}
      <path
        fill={color}
        d='M504.2,301.3l-24-17.1l-0.3-72.5c-0.1-11.2-8.4-19.3-19.8-19.3h-58.2c0.7-4-0.2-7.8-2.5-10.8
        c-2.5-3.3-6.5-5.2-10.9-5.2h-52.3c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5l50.5,0l-51.8,193.4H220.9c0.2-7.5-2-14.6-6.3-20.1
        c-5.9-7.7-15.1-11.9-25.8-11.9c-19.3,0-38.5,13.7-46,32.1l-33,0l14.7-54.7c1.1-4-1.3-8.1-5.3-9.2c-4-1.1-8.1,1.3-9.2,5.3l-15,56.1
        c-1.2,4.4-0.4,8.9,2.2,12.3c2.5,3.3,6.5,5.2,10.9,5.2h31.5c-0.2,7.5,2,14.6,6.3,20.1c5.9,7.7,15.1,11.9,25.8,11.9
        c19.3,0,38.5-13.7,46-32.1h170.4c-0.2,7.5,2,14.6,6.3,20.1c5.9,7.7,15.1,11.9,25.8,11.9c19.3,0,38.5-13.7,46-32.1h2.7
        c12.2,0,24.3-9.5,27.6-21.6l14.8-55.1C513.5,314.4,510.8,306.1,504.2,301.3L504.2,301.3z M205.1,390.3
        c-3.8,14.4-19.1,26.5-33.4,26.5c-6,0-10.9-2.2-13.9-6.1c-3.2-4.2-4-10.1-2.3-16.6c3.8-14.4,19.1-26.5,33.4-26.5
        c6,0,10.9,2.2,13.9,6.1C206,378,206.8,383.9,205.1,390.3L205.1,390.3z M465.2,280.6h-48.6c-1.3,0-3-0.3-4-1.6c-0.9-1.1-1.1-3-0.5-5
        l6.4-24c1.5-5.6,7.6-10.5,13.1-10.5H465L465.2,280.6z M453.6,390.3c-3.8,14.4-19.1,26.5-33.4,26.5c-6,0-10.9-2.2-13.9-6.1
        c-3.2-4.2-4-10.1-2.3-16.6c3.8-14.4,19.1-26.5,33.4-26.5c6,0,10.9,2.2,13.9,6.1C454.5,378,455.3,383.9,453.6,390.3L453.6,390.3z
        M367.5,320.6l30.3-113.3h62.2c2.2,0,4.8,0.8,4.8,4.3l0.1,12.7h-33.3c-12.2,0-24.3,9.5-27.6,21.6l-6.4,24c-1.8,6.6-0.6,13.2,3.1,18
        c3.6,4.7,9.4,7.4,15.9,7.4h53.7l25.1,18c2.1,1.5,1.5,4.8,1.2,5.7l-0.4,1.4l-4,15l-10.4,38.7c-1.4,5.4-7.2,10.1-12.5,10.4
        c0.1-7.5-2-14.5-6.3-20.1c-5.9-7.7-15.1-11.9-25.8-11.9c-19.3,0-38.5,13.7-46,32.1h-41l13.2-49.1'
      />
      <path
        fill={color}
        d='M191.9,319.6c66,0,119.7-53.7,119.7-119.7S257.9,80.2,191.9,80.2S72.1,133.9,72.1,199.9
        S125.9,319.6,191.9,319.6L191.9,319.6z M191.9,95.2c57.7,0,104.7,47,104.7,104.7s-47,104.7-104.7,104.7s-104.7-47-104.7-104.7
        S134.1,95.2,191.9,95.2L191.9,95.2z'
      />
      <path
        fill={color}
        d='M87.7,296.6H7.5c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5h80.2c4.1,0,7.5-3.4,7.5-7.5
        C95.2,300,91.8,296.6,87.7,296.6z'
      />
      <path
        fill={color}
        d='M79.6,328.7H31.5c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5h48.1c4.1,0,7.5-3.4,7.5-7.5
        C87.1,332,83.8,328.7,79.6,328.7z'
      />
      <path
        fill={color}
        d='M71.6,360.7h-16c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h16c4.1,0,7.5-3.4,7.5-7.5S75.8,360.7,71.6,360.7z'
      />

      {/* Line path */}
      <path
        fill='none'
        stroke={colorHighlight}
        strokeWidth='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M121.1,240l69.5,40.2l69.5-40.2v-80.3l-69.5-40.2l-69.5,40.2V240z'
      />

      {/* Circles */}
      <circle
        fill={colorHighlight}
        stroke={colorHighlight}
        strokeWidth='6'
        strokeMiterlimit='10'
        cx='190.7'
        cy='119.5'
        r='9.3'
      />
      <circle
        fill={colorHighlight}
        stroke={colorHighlight}
        strokeWidth='6'
        strokeMiterlimit='10'
        cx='121.1'
        cy='160'
        r='9.3'
      />
      <circle
        fill={colorHighlight}
        stroke={colorHighlight}
        strokeWidth='6'
        strokeMiterlimit='10'
        cx='260.2'
        cy='158.9'
        r='9.3'
      />
      <circle
        fill={colorHighlight}
        stroke={colorHighlight}
        strokeWidth='6'
        strokeMiterlimit='10'
        cx='190.7'
        cy='280.2'
        r='9.3'
      />
      <circle
        fill={colorHighlight}
        stroke={colorHighlight}
        strokeWidth='6'
        strokeMiterlimit='10'
        cx='260.2'
        cy='239.9'
        r='9.3'
      />
      <ellipse
        fill={colorHighlight}
        stroke={colorHighlight}
        strokeWidth='6.0001'
        strokeMiterlimit='10.0001'
        cx='121.1'
        cy='239.9'
        rx='9.3'
        ry='9.3'
      />
    </svg>
  );
};

export default ShippingTipsIcon;
