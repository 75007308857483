import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { CompoundEditor } from '@components/compoundEditor/CompoundEditor';
import Alert from '@components/elements/Alert';
import StationaryModal from '@as_core/elements/StationaryModal';
import { Molecule } from 'openchemlib/full';
import useCompounds from '@components/compounds/useCompounds';
import NewCompoundIcon from '@components/icons/newCompound.icon';
import MenuButton from '@components/controls/MenuButton';
import MolImgTile from '@components/compounds/images/MolImgTile';
import CompoundDrawIcon from '@components/icons/compoundDraw.icon';

interface PropsInterface {
  smiles?: string;
  updateSmiles: (s: string) => void;
}

type ImageT = {
  dark: string;
  light: string;
};

const EmptyImages = { dark: '', light: '' } as ImageT;

let errorMessage =
  'There appears to be an issue with the structure. ' +
  'Computing properties failed. ' +
  'Review structure and try again. Cannot be an empty structure. ';

const debug = false;
export const DefineStructure = (props: PropsInterface) => {
  const [localSmiles, setLocalSmiles] = useState<string>(
    props?.smiles ? props.smiles : ''
  );
  const [localMol, setLocalMol] = useState<string>('');
  const [images, setImages] = useState<ImageT>(EmptyImages);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { calculateProperties } = useCompounds();

  const handleCompoundEdit = (mol: string) => {
    if (debug) console.log('DefineStructure | handleCompoundEdit ', mol);
    setLocalMol(mol);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    getComputedData(localMol).then((data) => {
      if (debug)
        console.log('DefineStructure | handleModalClose | data:', data);
      setImages(data.svg);
      const smiles = data.structure.smiles.value;
      setLocalSmiles(smiles);
      props.updateSmiles(smiles);
    });
  };

  const getComputedData = async (mol: string) => {
    if (debug) console.log('DefinedStructure | getProperties ', mol);
    try {
      const response = await calculateProperties({ mol: mol });
      if (debug) console.log('getProperties | response: ', response);
      return response.data;
    } catch (err) {
      if (debug) console.error('Error from getProperties: ERROR', err);
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    if (localSmiles !== '') {
      if (debug)
        console.log('DefineStructure | useEffect | localSmiles', localSmiles);
      const mol = Molecule.fromSmiles(localSmiles).toMolfile();
      setLocalMol(mol);
      getComputedData(mol).then((data) => {
        if (debug) console.log('DefineStructure | useEffect ', data);
        setImages(data.svg);
      });
    } else {
      setLocalMol(Molecule.fromSmiles('').toMolfile());
    }
  }, []);

  if (debug)
    console.log(
      'DefineStructure | localSmiles ',
      localSmiles,
      'images',
      images
    );

  return (
    <DefineStructureContainer>
      <StructureImageContainer>
        {localSmiles === '' ? (
          <StructureButtonContainer>
            <ImageContainer>No Structure Defined</ImageContainer>
            <ButtonContainer>
              <MenuButton
                text={'Add Structure for Search'}
                onClick={() => setModalOpen(true)}
                icon={<NewCompoundIcon size={32} />}
              />
            </ButtonContainer>
          </StructureButtonContainer>
        ) : (
          <StructureButtonContainer>
            <ImageContainer>
              <MolImgTile mol_svg={images} width={80} />
            </ImageContainer>
            <ButtonContainer>
              <MenuButton
                text={'Edit Structure for Search'}
                onClick={() => setModalOpen(true)}
                icon={<CompoundDrawIcon size={32} />}
              />
            </ButtonContainer>
          </StructureButtonContainer>
        )}
      </StructureImageContainer>
      <StationaryModal
        title={'Define Structure'}
        modalOpen={modalOpen}
        onCloseClick={handleModalClose}
        width={880}
        height={600}
        opacity={1.0}
        icon={'update'}
      >
        <EditStructureContainer width={880}>
          <Editor>
            <CompoundEditor mol={localMol} onChange={handleCompoundEdit} />
          </Editor>
        </EditStructureContainer>
      </StationaryModal>
      <Alert
        type={'general'}
        title={'Warning: Structure Issue'}
        message={errorMessage}
        alertOpen={alertOpen}
        closeAlert={() => setAlertOpen(false)}
      />
    </DefineStructureContainer>
  );
};

export default DefineStructure;

const DefineStructureContainer = styled.div`
  display: flex;
`;

const StructureImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.xsmall};

  font-style: italic;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const ButtonContainer = styled(ImageContainer)`
  display: flex;
`;

const EditStructureContainer = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.width}px;
  background-color: yellow;
`;

const StructureButtonContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const Editor = styled.div`
  position: relative;
  width: 880px;
  height: 600px;
  margin: 0 auto;
`;
