import React, { useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import styled from 'styled-components/macro';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import HoverText from '@as_core/elements/HoverText';
import TextButton from '@as_core/controls/buttons/TextButton';
import UploadIcon from '@components/icons/upload.icon';

export type FilesT = FileWithPath[];

type ErrorsT = {
  code: string;
  message: string;
};

type FileRejectionsT = {
  file: FileWithPath;
  errors: ErrorsT[];
};

const addUnique = (array1: FilesT, array2: FilesT) => {
  const array1Names = array1.map((a) => a.name);
  const newArray = array1;
  array2.forEach((f) => {
    if (!array1Names.includes(f.name)) {
      newArray.push(f);
    }
  });
  return newArray;
};

type PropsT = {
  handleUpload: (f: FilesT) => void;
};

const debug = false;
const FileUpload = (props: PropsT) => {
  const [uploadFiles, setUploadFiles] = useState<FilesT>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejectionsT[]>([]);
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
  const { acceptedFiles, fileRejections, getRootProps } = useDropzone({
    accept: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  });

  useEffect(() => {
    if (debug)
      console.log(
        'UploadComponent | useEffect | acceptedFiles:',
        acceptedFiles.length,
        'rejectedFiles',
        fileRejections.length
      );
    let updated = false;
    if (acceptedFiles.length) {
      updated = true;
      setUploadFiles((prevState) => addUnique(prevState, acceptedFiles));
    }
    if (fileRejections.length) {
      updated = true;
      setRejectedFiles(fileRejections);
    }
    if (updated) setIsDataUpdated(true);
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    setIsDataUpdated(false);
  }, [isDataUpdated]);

  if (debug)
    console.log('uploadFiles', uploadFiles, 'rejectedFiles', rejectedFiles);
  let rejectedFilesList: string = '';
  if (rejectedFiles.length) {
    rejectedFiles.forEach((f) => {
      if (rejectedFilesList.length) rejectedFilesList = rejectedFilesList + ' ';
      rejectedFilesList = rejectedFilesList + f.file.name;
    });
  }

  return (
    <Container className='containerInUploadComponent'>
      <DropZoneContainer>
        <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
          <Row>
            <StyledFileUploadIcon sx={{ fontSize: '96px' }} />
          </Row>
          <Row>Drag and drop files</Row>
          <Row>-OR-</Row>
          <Row>
            <Button>Click to Select files</Button>
          </Row>
        </StyledDropZone>
      </DropZoneContainer>
      <FilesContainer>
        <FileRow>
          <FileHeader>
            Files to Upload:{' '}
            {uploadFiles.length ? '(' + uploadFiles.length + ')' : ''}
          </FileHeader>
          <FilesList>
            {uploadFiles.length ? (
              <>
                {uploadFiles.map((f, index) => (
                  <FileItem key={`fileitem_${index}`}>
                    <FileName key={`filename_${index}`}>{f.name}</FileName>
                  </FileItem>
                ))}
              </>
            ) : null}
          </FilesList>
        </FileRow>
        {rejectedFiles.length ? (
          <FileRow>
            <FileHeader>
              Ignored Files: <HoverInfoText text='Files must be spreadsheet' />
            </FileHeader>
            <HoverText
              text={rejectedFiles.length.toString() + ' files'}
              info={rejectedFilesList}
            />
          </FileRow>
        ) : null}
      </FilesContainer>
      <FileRow>
        <TextButton
          icon={<UploadIcon />}
          width={200}
          label={'Upload Files'}
          onClick={() => props.handleUpload(uploadFiles)}
          isDisabled={uploadFiles.length === 0}
        />
      </FileRow>
    </Container>
  );
};

export default FileUpload;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const DropZoneContainer = styled.div`
  display: flex;
  width: 300px;
  height: 200px;
  padding: 5px;
`;

const StyledDropZone = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 15px;
  border: 2px dashed white;
`;

const Row = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.theme.sizes.large};
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  width: calc(100% - 20px);
  margin-top: 10px;
  font-size: ${(p) => p.theme.sizes.xsmall};
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${(p) => p.theme.sizes.xsmall};

  justify-content: start;
  width: max-content;
  margin-bottom: 5px;
`;

const FileHeader = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding-right: 5px;
  width: 140px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const FilesList = styled.div`
  display: flex;
  width: 300px;
  flex-direction: row;
  font-size: ${(p) => p.theme.sizes.xsmall};

  justify-content: start;
  overflow-x: auto;
`;

const FileItem = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  white-space: nowrap;
  padding: 0 5px;
  border-radius: 15px;
  margin: 0 2px;
  background: ${(p) => p.theme.palette.textPrimary};
  color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const FileName = styled.div`
  display: flex;
`;

const StyledFileUploadIcon = styled(DriveFolderUploadIcon)`
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const Button = styled.button`
  cursor: pointer;
  color: ${(p) => p.theme.palette.textPrimary};
  font-weight: bold !important;
  font-size: 16px;
  height: 32px;
  width: 250px;
  background: ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  :hover {
    background: ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.backgroundPrimary};
  }
`;
