type Section = 
  | 'ImportCompoundSet' 
  | 'ImportTargetPanel' 
  | 'ImportTargetPanelNarrow' 
  | 'ImportDoseResponse' 
  | 'ImportGeneric'

export const cpd_set_msg_step_1 = 'To match your data with Aseda\'s existing columns, please define an ID column (e.g., "Compound ID" or "CAS"). This will act as a unique identifier.'
export const cpd_set_msg_step_2 = 'Match your columns with Aseda\'s columns. Use "Automatic Match" to help pre-calculate matches, or manually match by clicking "Import As." If no match is found, you can create a new field.'

type StepDetail = {
  message: string;
  duration: number;
};

const GetStepDetails = (step: number, section: Section, validationCompleted?: boolean): StepDetail => {
  const messages: Record<Section, Record<number, StepDetail>> = {
    ImportCompoundSet: {
      0: {message: 'Please address the issues found in your file before proceeding.', duration: 15000},
      1: {
        message: 'To match your data with Aseda\'s existing columns, please define an ID column (e.g., "smiles"). This will act as a unique identifier.',
        duration: 15000,
      },
      2: {
        message: 'Match your columns with Aseda\'s columns. Use "Automatic Match" to help pre-calculate matches, or manually match by clicking "Import As." If no match is found, you can create a new field.',
        duration: 15000,
      },
      3: {
        message: 'We are checking if your compounds already exist in our database. This may take some time depending on the size of your data.',
        duration: 15000,
      },
      4: {
        message: 'Your data is being uploaded. Please wait—this may take a while depending on your file size.',
        duration: 15000,
      },
      5: {
        message: 'Your data has been successfully uploaded! You can now review it on the "My Compounds" page.',
        duration: 15000,
      },
    },
    ImportTargetPanel: {
      1: { message: 'The left column displays the names of the required fields expected by our system. The right column lists the columns from your uploaded file. Please map each required field on the left to the corresponding column in your file on the right. This ensures that your data aligns correctly with our system requirements.', duration: 15000 },
      2: { message: "The data has been mapped. You can review the results before proceeding to the next step. Once completed, please click the 'Next' button in the top-right corner.", duration: 15000 },
      3: { message: 'Please enter a universe name, select one of the three response types displayed below, and submit the universe calculation graph.', duration: 15000 },
      4: { message: 'Your calculation is in progress and may take 1 to 4 minutes to complete.', duration: 15000 },
    },
    ImportTargetPanelNarrow: {
      1: { message: 'The left column displays the names of the required fields expected by our system. The right column lists the columns from your uploaded file. Please map each required field on the left to the corresponding column in your file on the right. This ensures that your data aligns correctly with our system requirements.', duration: 15000 },
      2: { message: "The data has been mapped. You can review the results before proceeding to the next step. Once completed, please click the 'Next' button in the top-right corner.", duration: 15000 },
      3: { message: 'Please enter a universe name, select one of the three response types displayed below, and submit the universe calculation graph.', duration: 15000 },
      4: { message: 'Your calculation is in progress and may take 1 to 4 minutes to complete.', duration: 15000 },
    },
    ImportDoseResponse: {
      1: { message: 'The left column displays the names of the required fields expected by our system. The right column lists the columns from your uploaded file. Please map each required field on the left to the corresponding column in your file on the right. This ensures that your data aligns correctly with our system requirements.', duration: 15000 },
      2: { message: "The data has been mapped. You can review the results before proceeding to the next step. Once completed, please click the 'Next' button in the top-right corner.", duration: 15000 },
      3: { message: 'Please click on "Submit Curve Fit Calculation"', duration: 15000 },
      4: { message: 'Your data has been successfully uploaded! You can now review it on the "My Compounds" page.', duration: 15000 },
    },
    ImportGeneric: {
      1: { message: 'The data has been mapped. You can review the results then, please click on the button "upload" on top right to finilize the data import.', duration: 15000 },
    },
  };


  if (validationCompleted === false && step === 1 && section === 'ImportCompoundSet') {
    return messages[section][0];
  }

  return messages[section]?.[step] || { message: '', duration: 0 };
};

export default GetStepDetails;
