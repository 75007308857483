import React from 'react';
import styled from 'styled-components/macro';
import { MenuItem } from '@mui/material';

interface PropsT {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}

const PullDownMenuItem = (props: PropsT) => {
  const { icon, label, onClick } = props;
  return (
    <StyledMenuItem onClick={onClick}>
      <MenuIcon>{icon}</MenuIcon>
      <MenuText>{label}</MenuText>
    </StyledMenuItem>
  );
};

export default PullDownMenuItem;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: ${(p) => p.theme.sizes.small};
    align-items: center;
    justify-items: flex-start;
    justify-content: flex-start;
    :hover {
      color: ${(p) => p.theme.palette.accentSecondary};
      background-color: ${(p) => p.theme.palette.backgroundQuaternary};
    }
  }
`;

const MenuIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30px;
`;

const MenuText = styled.div`
  display: flex;
  width: 80px;
`;
