import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useQuery } from '@tanstack/react-query';
import { OrderT } from './types';
import { ListLoader } from '@as_core/elements/listStyles';
import MenuButton from '@components/elements/MenuButton';
import { Row } from '../common/flexStyles';
import BackArrowIcon from '@components/icons/backArrow.icon';
import useRequests from '../hooks/useRequests';
import RequestsTable from '../user/RequestsTable';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import OrderDetailsTile from './OrderDetailsTile';

interface PropsI {
  order: OrderT;
  orderMode?: string;
  setNumberRequests?: (n: number) => void;
  handleBack: () => void;
  topBuffer?: number;
}

const debug = false;
const ViewOrderDetails = (props: PropsI) => {
  const { order, orderMode = 'success', handleBack, topBuffer = 100 } = props;
  const { getRequestsByOrder } = useRequests();
  if (debug) console.log('ViewOrderDetails | order:', order);

  // load requests for the specific defined action
  const {
    isPending,
    isError,
    error,
    data: requests,
  } = useQuery({
    queryKey: ['requests', 'order', order.orderId],
    queryFn: () => getRequestsByOrder(order.orderId),
  });
  if (isError) console.error('ViewOrderDetails | error:', error.message);

  useEffect(() => {
    if (requests !== undefined && props?.setNumberRequests)
      props.setNumberRequests(requests.length);
  }, [isPending, requests]);

  return (
    <ViewContainer topBuffer={topBuffer}>
      <DataRowContainer>
        <MenuButton
          onClick={handleBack}
          icon={<BackArrowIcon />}
          text={'Back to Orders List'}
          tooltipPlacement={'bottom'}
        />
        <OrderHeader>{order?.name ? order.name : ''}</OrderHeader>
      </DataRowContainer>
      {!Object.keys(order) ? (
        <ErrorMessages
          messages={[
            <MessageItem key='emi_loading_error'>
              Error loading order
            </MessageItem>,
          ]}
        />
      ) : (
        <>
          <TitleRow>Order Details</TitleRow>
          <DataRowContainer>
            <OrderDetailsTile order={order} orderMode={orderMode} />
          </DataRowContainer>
          <TitleRow>Order Actions and Fulfillment Details</TitleRow>
          {isPending ? (
            <Row
              width={'1200px'}
              height={'100px'}
              v_align={'center'}
              h_align={'center'}
            >
              <ListLoader />
            </Row>
          ) : (
            <DataRowContainer>
              {requests !== null ? (
                <RequestsTable
                  requests={requests}
                  requestType='all'
                  showProcessMap={false}
                  setNumberRequests={props.setNumberRequests}
                />
              ) : (
                <></>
              )}
            </DataRowContainer>
          )}
        </>
      )}
    </ViewContainer>
  );
};

export default ViewOrderDetails;

const ViewContainer = styled.div<{ topBuffer?: number }>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(p) => p.topBuffer}px);
  width: max-content;
  padding: 0 10px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

ViewContainer.defaultProps = {
  topBuffer: 100,
};

const DataRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
`;

const TitleRow = styled.div`
  width: 100%;
  font-size: 16px;
  margin: 10px 0 0 20px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const OrderHeader = styled(TitleRow)`
  text-align: center;
  font-size: ${(p) => p.theme.sizes.xlarge};
  font-weight: bold;
  color: ${(p) => p.theme.palette.accentPrimary};
`;
