import React from 'react';
import styled from 'styled-components/macro';

export type StepT = {number: number; image: string; text: string;}

interface PropsI {
  step: StepT;
}
const debug = false;
const ProcessStepTile = (props:PropsI) => {
  const {step} = props;
  if (debug) console.log('ProcessStepTile | props: ', props);
  return (
    <ProcessStepTileCard>
      <TileNumber>{step.number}</TileNumber>
      <TileImage>
        <img src={step.image} alt={step.text} width={'100px'} height={'100px'} />
      </TileImage>
      <TileText>{step.text}</TileText>
    </ProcessStepTileCard>
  )
}
export default ProcessStepTile;

const ProcessStepTileCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: max-content;
  height: max-content;
`;

const TileNumber = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 30px;
  height: 30px;
  top: 15px;
  left: 65px;
  border-radius: 50%;
  background: ${(p) => p.theme.palette.accentSecondary};
`

const TileImage = styled.div`
  display : flex;
  width: 150px;
  height: 150px;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const TileText = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: center;
    font-size: 12px;
    width: 150px;
    text-align: center;
`