import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import useFileImport from '../useFileImport';
import { createTargetPanelDataSetFromExcel } from '@components/dataSets/dataSet.service';
import { Header, Row } from '../shared/RowElements';
import ImportTargetPanelReviewRow from './ImportTargetPanelReviewRow';
import { UserContext } from '@stores/UserContext';
import { ImportTargetPanelReviewPropsT } from './types';
import TextButton from '@components/controls/TextButton';
import ForwardArrowIcon from '@components/icons/forwardArrow.icon';
const debug = false;
const debugComponentName = 'ImportTargetPanelReview';
const ImportTargetPanelReview = (props: ImportTargetPanelReviewPropsT) => {
  const {
    formatType = 'wide',
    columnFields,
    dataSet,
    setDataSet,
    width,
  } = props;
  const {
    context: { workbook, columns, setActiveStep },
  } = useFileImport();
  const { user } = useContext(UserContext);
  if (debug) console.log(debugComponentName + ' | workbook', workbook);
  if (debug) console.log(debugComponentName + ' | columns', columns);
  useEffect(() => {
    if (debug)
      console.log(
        debugComponentName + ' | useEffect | columnFields:',
        columnFields
      );
    // process the file and put it in the correct format
    let myColumns = columns.map((c) => ({ ...c, selected: false }));
    if (formatType === 'wide') {
      for (const col of columnFields) {
        if (!col?.multiple) {
          const index = myColumns.findIndex(
            (c) => c.colName === col.matched_column
          );
          if (index !== -1) {
            myColumns[index].field_id = col.value;
            myColumns[index].selected = true;
          }
        } else if (col?.unMatched) {
          for (const colName of col.matched_column) {
            const index = myColumns.findIndex((c) => c.colName === colName);
            if (index !== -1) {
              myColumns[index].field_id = colName;
              myColumns[index].selected = true;
            }
          }
        }
      }
    } else {
      for (const col of columnFields) {
        const index = myColumns.findIndex(
          (c) => c.colName === col.matched_column
        );
        if (index !== -1) {
          myColumns[index].field_id = col.value;
          myColumns[index].selected = true;
        }
      }
    }
    let dataSet = createTargetPanelDataSetFromExcel(
      formatType,
      user.authId,
      workbook,
      myColumns
    );
    if (debug) console.log(dataSet);
    setDataSet(dataSet);
  }, [workbook, columns]);
  if (debug) console.log(debugComponentName + ' | dataSet ', dataSet);
  return (
    <Container>
      {!dataSet?.data.length ? (
        <></>
      ) : (
        <ScrollContainer>
          <Table width={width}>
            <tbody>
              <Row>
                <Header>Compound Ids</Header>
                <Header>Smiles</Header>
                <Header>Target Assay Pairs</Header>
                <Header>Assay Data Preview</Header>
              </Row>
              {dataSet.data.slice(0, 100).map((data, dataIdx) => {
                if (debug)
                  console.log('ImportTargetPanelReview | row data:', data);
                return (
                  <ImportTargetPanelReviewRow
                    key={dataIdx}
                    data={data}
                    dataIdx={dataIdx}
                  />
                );
              })}
            </tbody>
          </Table>
        </ScrollContainer>
      )}
      <ButtonContainer>
        <TextButton
          text={'Done with review'}
          height={30}
          width={200}
          onClick={() => setActiveStep((currStep) => currStep + 1)}
          icon={<ForwardArrowIcon />}
        />
      </ButtonContainer>
    </Container>
  );
};
export default ImportTargetPanelReview;
const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 330px);
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;
const Table = styled.table<{ height?: number; width?: number }>`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;
