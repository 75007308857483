import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import DashboardIcon from '@components/icons/dashboard.icon';
import SearchIcon from '@components/icons/search.icon';
import CenteredMessages, {MessageItem} from "@as_core/elements/CenteredMessages";
import ListIcon from '@components/icons/list.icon';

export const gostarMenuItems: MenuItemsT[] = [
  {
    id: 'gostarDashboard',
    name: 'Overview',
    pathname: '/gostar/overview',
    icon: <DashboardIcon />
  },
  {
    id: 'gostarSearch',
    name: 'Search',
    pathname: '/gostar/search',
    icon: <SearchIcon />
  },
  {
    id: 'gostarPast',
    name: 'Past Searches',
    pathname: '/gostar/past',
    icon: <ListIcon />
  },
];

const debug = false;

export function isSubscribed(subscription: string) {
  if (debug) console.log('isSubscribed', subscription);
  return subscription !== 'basic';
}

export const NotSubscribedMessage = () => {
  return (
    <CenteredMessages
      messages={[
        <MessageItem key={'msg_no_subscription_1'} color={'accent'}>You have landed on this page by mistake.</MessageItem>,
        <MessageItem key={'msg_no_subscription_2'} color={'accent'}>Please subscribe to GOSTAR to access this functionality.</MessageItem>,
      ]}
    />
  )
}

export const getGostarLogo = () => {
  return ('/img/GOSTAR_Logo_notext.svg')
}

export const getGostarMenus = (subscription: string) => {
  if (debug) console.log('getGostarMenus | {subscription}:', subscription);
  if (!isSubscribed(subscription)) return [gostarMenuItems[0]];
  return gostarMenuItems;
}
