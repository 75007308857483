import styled from 'styled-components/macro';
import { getValueByPath } from '@utils/misc';
import React from 'react';
import { RequestFormT } from '../hooks/useRequestForms';
import RequestFormFileDownload from './RequestFormFileDownload';
import { Row, Label, Value } from '../common/flexStyles';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';

const userFields: BasicTableFieldsT[] = [
  {
    value: 'created_at',
    label: 'Created',
    type: 'datetime',
  },
  {
    value: 'file_name',
    label: 'File Name',
    type: 'string',
  },
  {
    value: 'updated_at',
    label: 'Last Updated',
    type: 'datetime',
  },
];

function getFieldValue(form: RequestFormT, item: BasicTableFieldsT) {
  const value = getValueByPath(form, item.value, '');
  if (value === '') return value;
  if (item.type === 'datetime') {
    const date = new Date(value);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return value;
}

interface PropsI {
  view?: string;
  requestForm: RequestFormT;
}

const debug = false;
const RequestFormFileDetails = (props: PropsI) => {
  const { requestForm } = props;
  if (debug) console.log('PlateMapFileDetails | requestForm:', requestForm);

  const displayValue = (
    form: RequestFormT,
    item: BasicTableFieldsT,
    index: number
  ) => {
    return (
      <Row key={index} height={'24px'}>
        <Label>{item.label}:</Label>
        <Value>{getFieldValue(form, item)}</Value>
      </Row>
    );
  };

  return (
    <ViewContainer>
      <HeaderRow border={'top'}>
        <RequestHeader>{requestForm?.name}</RequestHeader>
      </HeaderRow>
      <Row>
        <ReportContainer>
          {Object.keys(requestForm) ? (
            userFields.map((item, index) =>
              displayValue(requestForm, item, index)
            )
          ) : (
            <></>
          )}
        </ReportContainer>
      </Row>
      <Row>
        <RequestFormFileDownload requestForm={requestForm} />
      </Row>
    </ViewContainer>
  );
};

export default RequestFormFileDetails;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderRow = styled(Row)`
  justify-content: center;
  padding-top: 10px;
  height: 24px;
`;

const ActionButtons = styled.div`
  display: flex;
  width: max-content;
`;

const RequestHeader = styled(ActionButtons)`
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
`;
