import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import FieldImportTable from './fieldImportTable/FieldImportTable';
import FieldValidationFile from './FieldValidationFile';
import ImportReview from './importReviewTable/ImportReview';
import UploadDataStepper from '../shared/UploadDataStepper';
import Toast from '../shared/Toast';
import UploadCompounds from './UploadCompounds';
import useFileImport from '../useFileImport';
import { CompoundT } from '../../../../type';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import Alert from '@components/elements/Alert';
import GetStepDetails from '../shared/GetToastMessage';

const steps = [
  'Upload Spreadsheet',
  'Choose Identity',
  'Match Columns',
  'Review Results',
  'Save',
];

const debug: boolean = process.env.REACT_APP_HOST_TYPE === 'dev';
interface ImportCompoundSetProps {
  handleReset: () => void;
}
 
const ImportCompoundSet = (props: ImportCompoundSetProps) => {
  const { handleReset } = props;
  const [compoundSet, setCompoundSet] = useState<CompoundT[]>([]);
  const [toast, setToast] = useState<{ message: string; duration: number } | null>(null);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [validationCompleted, setValidationCompleted] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const height = windowHeight - 100;
  const width = windowWidth - 200;
  const { context, removeIdentityColumns, hasSelectedIdentity } = useFileImport();
  debug &&
    console.log('ImportCompoundSet: {step, height, width}', context.activeStep);
  debug &&
    console.log('ImportCompoundSet | context.workbook: ', context.workbook);
  debug &&
    console.log('ImportCompoundSet | context.columns:  ', context.columns);

  // ###########################################################################
  // STEPPER
  // ###########################################################################
  const handleNext = (isSelect = true) => {
    if (context.activeStep === 1 && !hasSelectedIdentity() && !isSelect) {
      <Alert
        type={'general'}
        title={'Alert ID'}
        message={'Please select at least one identity column.'}
        alertOpen={true}
      />
      return;
    }
    context.setActiveStep((currStep) => currStep + 1);
  };

  const handleBack = () => {
    context.setActiveStep((currStep) => {
      const nextStep = currStep - 1;
      if (nextStep === 1) {
        removeIdentityColumns();
      }
      return nextStep;
    });
  };

  if (context.activeStep < 1) {
    handleReset();
  }

  const closeAlert = () => {
    setAlertOpen(false);
    handleReset();
  };

  useEffect(() => {
    // Check for file type as soon as the file is selected
    const fileExtension = context.workbook.filename.split(".").pop().toLowerCase();
    if (!['xls', 'xlsx'].includes(fileExtension)) {
      setAlertVisible(true);
      setAlertOpen(true);
    } else {
      setAlertVisible(false);
    }
  }, [context.workbook.filename]);

  useEffect(() => {

    const { message, duration } = GetStepDetails(context.activeStep, 'ImportCompoundSet', validationCompleted);
    if (message) {
      setToast({ message, duration });
    } else {
      setToast(null);
    }
    if (context.activeStep !== 1) setValidationCompleted(false);
  }, [context.activeStep, validationCompleted]);

  if (debug) console.log('ImportCompoundSet | compoundSet', compoundSet);

  const usedComponent = useCallback(() => {
    switch (context.activeStep) {
      case 0:
        return (
          <FieldImportTable
            width={width}
            height={height}
            fieldFilter='all'
            incrementStep={handleNext}
          />
        );
      case 1:
        return (
          <>
            {!validationCompleted ? (
              <FieldValidationFile
                filename={context.workbook.filename}
                sheetName={context.workbook.workbook?.SheetNames[0]}
                columns={context.columns}
                onAllChecksPassed={(hasIssues) => {
                  setValidationCompleted(!hasIssues);
                }}
              />
            ) : (
              <FieldImportTable
                width={width}
                height={height}
                fieldFilter='Identity'
                incrementStep={handleNext}
                incrementAfterMapping={true}
              />
            )}
          </>
        );
      case 2:
        return (
          <FieldImportTable
            width={width}
            height={height}
            fieldFilter='all'
            incrementStep={handleNext}
          />
        );
      case 3:
        return (
          <ImportReview
            height={height}
            width={width}
            compoundSet={compoundSet}
            setCompoundSet={setCompoundSet}
            handleNext={handleNext}
            showUpload
          />
        );
      case 4:
        return (
          <UploadCompounds
            height={height}
            width={width}
            compoundSet={compoundSet}
            handleNext={handleNext}
          />
        );
      case 5:
        return (
          <UploadCompounds
            height={height}
            width={width}
            compoundSet={compoundSet}
            handleNext={handleNext}
          />
        );
      default:
        return <div />;
    }
  }, [context.activeStep, validationCompleted]);

  return (
    <Container height={height} width={width}>
      {alertVisible && (
        <Alert
          type="general"
          alertOpen={alertOpen}
          closeAlert={closeAlert}
          title="File Upload Error"
          message="Incorrect file type detected. Please upload an Excel file."
        />
      )}
      <UploadDataStepper
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        uploadStep={4}
        hideNextSteps={[]}
        steps={steps}
      />
      <ToastContainer>
        { toast && <Toast
          message={toast?.message}
          duration={toast?.duration}
          onClose={() => setToast(null)}
        />}
      </ToastContainer>      
      <StepContainer>{usedComponent()}</StepContainer>
    </Container>
  );
};

export default ImportCompoundSet;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  min-width: 700px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  background: ${(p) => p.theme.palette.backgroundSecondary};
  margin-bottom: 50px;
`;
const ToastContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;