import React from 'react';
import { UseCartObjectReturnType } from '@as_core/hooks/useShoppingCart';
import OrdersCartTable from './carts/OrdersCartTable';
import OrdersCartTile from '@business/orders/components/carts/OrdersCartTile';
import { StripeProductT } from '@business/products/useStripeProducts';

export const ordersCartTableFields = [
  { value: 'delete', label: '', width: 50 },
  { value: 'info', label: '', width: 50 },
  { value: 'name', label: 'Product Name', width: 250, align: 'left' },
  {
    value: 'metadata.catalogNumber',
    label: 'Catalog Number',
    width: 150,
    align: 'left',
  },
  { value: 'price', label: 'Unit Price', width: 100, align: 'right' },
  { value: 'quantity', label: 'Quantity', width: 100 },
  { value: 'totalCost', label: 'Line Item Cost', width: 100, align: 'right' },
];

export type OrdersCartPropsI = {
  cart: UseCartObjectReturnType;
  type?: 'table' | 'tile';
  getCategoryIcon?: (product: StripeProductT) => React.ReactNode;
  width?: number;
  showTotalCost?: boolean;
  noQuantityChanges?: boolean;
};

const debug = false;
const OrdersCart = (props: OrdersCartPropsI) => {
  const { cart, width=250, type='table', showTotalCost = true } = props;
  if (debug) console.log('OrderCart | {props}:', props);

  if (type === 'tile') {
    return (
    <OrdersCartTile
      key={'orders_cart_tile'}
      getCategoryIcon={props?.getCategoryIcon ? props.getCategoryIcon : () => null}
      width={width}
      cart={cart}
      showTotalCost={showTotalCost}
      noQuantityChanges={props.noQuantityChanges}
    />
    )
  }

  return (
    <OrdersCartTable
      key={'orders_cart_table'}
      cart={cart}
      showTotalCost={showTotalCost}
      noQuantityChanges={props.noQuantityChanges}
    />
  )
};

OrdersCart.defaultProps = {
  noQuantityChanges: false,
};

export default OrdersCart;
