import React from 'react';
import styled from 'styled-components/macro';
import { UseCartObjectReturnType } from '@as_core/hooks/useShoppingCart';
import { getCartTotalCost } from '@business/orders/components/utils';
import OrdersCartIcon from '@business/orders/components/carts/OrdersCartIcon';
import CartItemTile from '@business/orders/components/carts/CartItemTile';
import { StripeProductT } from '@business/products/useStripeProducts';
import TextButton from '@components/controls/TextButton/TextButton';
import { useNavigate } from 'react-router-dom';

export type OrdersCartPropsI = {
  width: number;
  cart: UseCartObjectReturnType;
  getCategoryIcon: (product: StripeProductT) => React.ReactNode;
  showTotalCost: boolean;
  noQuantityChanges: boolean;
};

const debug = false;
const OrdersCartTile = (props: OrdersCartPropsI) => {
  const { width, cart, getCategoryIcon, showTotalCost,  } = props;
  const navigate = useNavigate();
  if (debug) console.log('OrdersCartTable | {props}:', props);

  return (
    <OrdersCartTileContents width={width}>
      <CartTop>
        <OrdersCartIcon cart={cart} onClick={() => {}}/>
        { showTotalCost && (<OrdersCost>Total Cost {getCartTotalCost(cart.data)}</OrdersCost>)}
      </CartTop>
      <CartConfirmButton>
        {cart.length() ? (
          <OrdersButton>
            <TextButton
            width={200}
            label={'Proceed to Purchase'}
            onClick={() => navigate('/orders/confirm')}
            />
          </OrdersButton>
        ) : 'No Items in Cart'}
      </CartConfirmButton>
      <CartBottom>
        <CartItemTiles>
          { Object.keys(cart.data).map((catNo, index) => {
            console.log(catNo);
            const itemIcon = getCategoryIcon(cart.data[catNo].product);
            return (
              <CartItemTile
                key={catNo + '_' + index}
                cart={cart}
                icon={itemIcon}
                catNo={catNo}
              />
            )
          })}
        </CartItemTiles>
      </CartBottom>
    </OrdersCartTileContents>
  );
};

export default OrdersCartTile;

const OrdersCartTileContents = styled.div<{width: number}>`
  display: flex;
  width: ${(p) => p.width - 2}px;
  height: calc(100vh - 70px);
  flex-direction: column;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
  border-left: 2px solid ${(p) => p.theme.palette.backgroundQuaternary };
`;

const CartTop = styled.div`
  width: 100%;
  height: 90px;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  background: ${(p) => p.theme.palette.backgroundQuaternary };
`

const OrdersCost = styled.div`
  display: flex;
  width: max-content;
`

const CartConfirmButton = styled.div`
  width: 100%;
  height: 55px;
  font-style: italic;
  color: ${(p) => p.theme.palette.textSecondary};
  flex-direction: column;
  justify-items: center;
  align-content: center;
  text-align: center;
`

const OrdersButton = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const CartBottom = styled.div`
  width: calc(100% - 7px);
  padding-left: 7px; // center with space for scroll bar
  height: calc(100vh - 155px);
  overflow-x: hidden;
  overflow-y: scroll;
`

const CartItemTiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
`
