import { ProductCategoryT} from "@business/products/products.types";

export const ProductsCategoriesFull:ProductCategoryT[] = [
  {
    categoryCode: "AS-SYS",
    categoryType: "Screen",
    categoryName: "SYSTEMETRIC\u00ae Cell Health",
    categoryDescription: "A multi-parametric acute cellular stress assay.",
  },
  {
    categoryCode: "AS-ZBE",
    categoryType: "Screen",
    categoryName: "ZBEscreen\u2122 Zebrafish Screen",
    categoryDescription: "A zebrafish bioactivity screen designed to detect teratogenicity.",
  },
  {
    categoryCode: "AS-BMVK",
    categoryType: "Screen",
    categoryName: "Biomimetic Valko Screen",
    categoryDescription: "A screen using 6 HPLC measurements to assist understanding in-vivo distribution.",
  },
  {
    categoryCode: "AS-CQC-SST",
    categoryType: "Screen",
    categoryName: "Compound QC",
    categoryDescription: "A screen using HPLC and MS to understand solubility and stability.",
  }
]