import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import BasicTable from '@as_core/tables/BasicTable';
import { getObjectValueByKey } from '@utils/misc';
import { FieldsTypeT } from '@subApps/admin/components/ObjectDetails';
import { getTableRows } from '@subApps/admin/components/shared';
import SearchField from '@as_core/components/inputs/SearchField';
import { RepositoryDetailsT } from '@subApps/account/repositoryManagement/useRepos';

const columns: FieldsTypeT[] = [
  {
    value: 'view',
    label: '',
    type: 'action',
    id: { key: 'uuid', label: 'Repo' },
    width: 40,
  },
  { value: 'uuid', label: 'Repo Id', width: 290 },
  { value: 'name', label: 'Repo Name', width: 275 },
  { value: 'ownerAuthEmail', label: 'Owner Email', width: 275 },
  { value: 'numberInvitedUsers', label: 'Invited Users', width: 120 },
];

interface RepoListI {
  repos: RepositoryDetailsT[];
  handleSelect: (repoId: string) => void;
}

const debug = false;
const RepoList = ({ repos, handleSelect }: RepoListI) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  if (debug) console.log('RepoList | repos:', repos);

  // HANDLERS
  const handleClick = (action: string, repoId: string) => {
    if (debug)
      console.log('RepoList | handleClick action:', action, 'repoId:', repoId);
    handleSelect(repoId);
  };

  // SEARCH FUNCTION
  const filteredRepos = useMemo(() => {
    if (!searchTerm) return repos;

    return repos.filter(
      (repo) =>
        getObjectValueByKey(repo, 'uuid')
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        getObjectValueByKey(repo, 'name')
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        getObjectValueByKey(repo, 'ownerAuthEmail')
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
  }, [repos, searchTerm]);

  if (debug) console.log('RepoList | searchTerm:', searchTerm);
  if (debug) console.log('RepoList | filteredRepos:', filteredRepos);

  return (
    <RepoListContainer>
      <SearchField
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder='Search repositories...'
      />

      <ScrollContainer>
        <BasicTable
          key='repo-table'
          fields={columns}
          rows={getTableRows(filteredRepos, columns, handleClick)}
          stickyHeader
        />
      </ScrollContainer>
    </RepoListContainer>
  );
};

export default RepoList;

const RepoListContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: auto;
`;

const ScrollContainer = styled.div`
  display: flex;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 16px;
  color: #666;
`;
