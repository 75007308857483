import React from 'react';
import styled from 'styled-components/macro';
import SearchCompound from '@components/compoundEditor/SearchCompound';
import DataLoading from '@as_core/elements/DataLoading';

const debug = false;
const Search = () => {
  debug && console.log('Search');
  const [searchText, setText] = React.useState<string>('');
  const [searchSmiles, setSearchSmiles] = React.useState<string>('');
  const [searchType, setSearchType] = React.useState<string>('text');
  const [similarityThreshold, setSimilarityThreshold] = React.useState<number>(0.75);
  const [isSearching, setIsSearching] = React.useState(false);

  const handleSearch = () => {
    setIsSearching(true);
  }

  return (
    <Container>
      { isSearching ?
      <DataLoading />
      :
      <SearchCompound
      smiles = {searchSmiles}
      onSmilesChange={(smiles) => setSearchSmiles(smiles)}
      text = {searchText}
      onTextChange={(text) => setText(text)}
      searchType = {searchType}
      onSearchTypeChange={(e) => setSearchType(e.target.value)}
      similarityThreshold = {similarityThreshold}
      onSimilarityThresholdChange={(v) => setSimilarityThreshold(v)}
      onSubmit={() => handleSearch()}
      />
      }
    </Container>
  );
};

export default Search;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

