import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import DashboardIcon from '@components/icons/dashboard.icon';
import SearchIcon from '@components/icons/search.icon';
import CenteredMessages, {MessageItem} from "@as_core/elements/CenteredMessages";
import ListIcon from '@components/icons/list.icon';

export const chosmosMenuItems: MenuItemsT[] = [
  {
    id: 'chosmosDashboard',
    name: 'Overview',
    pathname: '/chosmos/overview',
    icon: <DashboardIcon />
  },
  {
    id: 'chosmosSearch',
    name: 'Search',
    pathname: '/chosmos/search',
    icon: <SearchIcon />
  },
  {
    id: 'chosmosPast',
    name: 'Past Searches',
    pathname: '/chosmos/past',
    icon: <ListIcon />
  },
];

const debug = false;

export function isSubscribed(subscription: string) {
  if (debug) console.log('isSubscribed', subscription);
  return subscription !== 'basic';
}

export const NotSubscribedMessage = () => {
  return (
    <CenteredMessages
      messages={[
        <MessageItem key={'msg_no_subscription_1'} color={'accent'}>You have landed on this page by mistake.</MessageItem>,
        <MessageItem key={'msg_no_subscription_2'} color={'accent'}>Please subscribe to ChOSMOS to access this functionality.</MessageItem>,
      ]}
    />
  )
}

export const getChosmosLogo = () => {
  return ('/img/Chosmos_Logo_notext.svg')
}

export const getChosmosMenus = (subscription: string) => {
  if (debug) console.log('getMenuAccount | {subscription}:', subscription);
  if (!isSubscribed(subscription)) return [chosmosMenuItems[0]];
  return chosmosMenuItems;
}
