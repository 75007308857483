import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Checkbox } from '@mui/material';
import { DataSetsInfoT, GraphSettingsT } from '../types';
import { UpdateSettingsT } from '../CompoundUniverse';

interface PropsI {
  datasets: DataSetsInfoT[];
  settings: GraphSettingsT;
  updateSettings: (f: string, v: UpdateSettingsT) => void;
}

const UniverseLibrarySelector = (props: PropsI) => {
  const { datasets, settings, updateSettings } = props;
  const [datasetSwitchId, setDatasetSwitchId] = useState<string>('');

  // use until implement multiple datasets
  useEffect(() => {
    if (datasets && datasetSwitchId === '' && datasets.length > 1) {
      const newDatasetId = datasets[1]?.datasetId;
      setDatasetSwitchId(newDatasetId);
      updateSettings('selectedDatasetId', '');
    }
  }, [datasets]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      updateSettings('selectedDatasetId', datasetSwitchId);
    } else {
      updateSettings('selectedDatasetId', '');
    }
  };

  return (
    <Container>
      <Checkbox
        checked={settings.selectedDatasetId !== ''}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        size='small'
      />
      <Label>My Compounds</Label>
    </Container>
  );
};

export default UniverseLibrarySelector;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 35px;
  width: max-content;
  & .PrivateSwitchBase-input {
    padding: 0;
    margin: 0;
  }
  & .MuiCheckbox-root {
    padding: 0;
  }
`;

const Label = styled.div`
  font-size: ${(p) => p.theme.sizes.xsmall};

  padding-left: 3px;
  color: ${(p) => p.theme.palette.textSecondary};
`;
