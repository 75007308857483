import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

type PropsT = {
  label?: string | ReactNode;
  width?: string;
  height?: string;
  children: ReactNode;
};

const LabeledContainer = (props: PropsT) => {
  return (
    <MainContainer
      width={props?.width ? props.width : 'max-content'}
      height={props?.height ? props.height : 'max-content'}
    >
      {props?.label ? <OverlayTitle>{props.label}</OverlayTitle> : null}
      <Content>{props.children}</Content>
    </MainContainer>
  );
};
export default LabeledContainer;

export const MainContainer = styled.div<{ width?: string; height?: string }>`
  display: flex;
  position: relative;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  border-radius: 15px;
  margin: 10px;
  padding: 10px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;

const OverlayTitle = styled.div`
  position: absolute;
  margin-top: -18px;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 0 10px;
  font-size: ${(p) => p.theme.sizes.small};
  font-weight: bold;
  color: ${(p) => p.theme.palette.accentSecondary};
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 15px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
