import React from 'react';
import styled from 'styled-components/macro';
import DataLoading from "@as_core/elements/DataLoading";
import { getComponent } from './utils/actions';

type PropsT = {
  view: string;
}

const debug = false;
const Gostar = (props:PropsT) => {
  const { view } = props;
  const isPending = false; // placeholder for any data context loading

  debug && console.log('gostar | view:', view, 'isPending', isPending);

  return (
    <GostarContainer>
      {
        isPending ? <DataLoading />
      :
        getComponent(view)
      }
    </GostarContainer>
  );
};

export default Gostar;

const GostarContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

