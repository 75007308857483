import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import { ApiInfoAllT,  useApiInfo } from '@stores/apiInfo';
import ShowObject from '@as_core/elements/ShowObject';
import DataLoading from '@as_core/elements/DataLoading';

const debug = false;
const SettingsInfo = () => {
  // API and CONTEXT
  const { user } = useContext(UserContext);
  const { getApiInfoAll } = useApiInfo();

  // STATE VARIABLES
  const [apiInfo, setApiInfo] = useState<ApiInfoAllT>(null);
  const [loaded, setLoaded] = useState(false);
  if (debug) console.log('UserInsights | user:', user);

  // DATA LOADING
  useEffect(() => {
    if (!loaded) {
      getApiInfoAll().then((apiInfo) => {
        if (debug) console.log(apiInfo);
        setApiInfo(apiInfo);
        setLoaded(true);
      })
    }
  }, [loaded]);

  if (debug) console.log('SettingsInfo | apiInfo', apiInfo);

  return (
    <Container>
      { loaded ?
        <>
        { Object.keys(apiInfo).map((key: string) => (
          <Tile key={key}>
            <Title>{key} API: </Title>
            <Values><ShowObject obj={apiInfo[key]?.api}/></Values>
            <Title>{key} DB: </Title>
            <Values><ShowObject obj={apiInfo[key]?.db}/></Values>
          </Tile>
        ))}
        </>
      :
        <DataLoading />
      }
    </Container>
  );
};

export default SettingsInfo;

const Container = styled.div`
  display: flex;
  padding: 10px;
  max-width: calc(100vw - 50px);
  flex-direction: row;
  flex-wrap: wrap;
  width: max-content;
`;

const Tile = styled.div`
  width: 400px;
  min-height: 100px;
  padding: 10px;
  margin: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
`

const Title = styled.div`
    display: flex;
`;

const Values = styled.div`
  display: flex;
`;

