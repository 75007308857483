import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import useCoupons from '../orders/useCoupons';
import CouponCard from '@business/subscriptions/components/CouponCard';
import { CouponT } from '../types';
import { UserContext } from '@stores/UserContext';
import { SubscriptionsProductsList } from '@business/products/Subscriptions';
import TextButton from '@components/controls/TextButton/TextButton';
import { FlexRow } from '@components/layout/FlexStyles';
import { BasicTextInput } from '@as_core/account/forms/FormStyles';
import { APP_CONFIG } from '@app_config/app';
import useSubscriptions from '@subApps/account/subscriptionManagement/useSubscriptions';
import { useNavigate } from 'react-router-dom';
import { UserT } from '@stores/UserContext';

const getInitialRepoName = (user: UserT) => {
  let repoName: string;
  if (user?.regInfo?.organization?.type === 'academic') {
    repoName =
      user.regInfo.name.first +
      ' ' +
      user.regInfo.name.last +
      ' Lab Data Repository';
  } else {
    repoName = user.regInfo.organization.name + ' Data Repository';
  }
  // console.log('getInitialRepoName: ', repoName);
  return repoName;
};

const debug = false;
const SubscriptionByCoupon = () => {
  const { getCoupon } = useCoupons();
  const { addSubscription } = useSubscriptions();
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [repoName, setRepoName] = useState<string>(getInitialRepoName(user));
  const [code, setCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [coupon, setCoupon] = useState<CouponT>(null);
  if (debug)
    console.log('SubscriptionByCoupon | user:', user, 'repoName', repoName);

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');
    setCoupon(null);
    getCoupon({ code: code }).then((res) => {
      if (debug) console.log('SubscriptionByCoupon | res: ', res);
      if (res?.errors.length) {
        setErrorMessage(res?.errors[0]);
      } else {
        if (res?.data.length) {
          let updated_coupon = res?.data[0];
          if (updated_coupon?.expired) {
            setErrorMessage(
              'Retrieved coupon via code is already expired. Please request a new code.'
            );
          } else if (updated_coupon?.system !== APP_CONFIG.system) {
            setErrorMessage(
              'Retrieved coupon via code is not for this application. Please confirm code.'
            );
          } else {
            updated_coupon.productDetails = SubscriptionsProductsList.find(
              (p) => p.catalogNumber === updated_coupon.product
            );
            if (debug)
              console.log(
                'SubscriptionByCoupon | productDetails',
                updated_coupon.product,
                updated_coupon.productDetails
              );
            setCoupon(updated_coupon);
          }
        }
      }
    });
  };
  const handleCodeUpdate = (e) => {
    if (debug) console.log('SubscriptionByCoupon | e:', e.target.value);
    setCode(e.target.value.toUpperCase());
  };

  const handleRepoNameChange = (e) => {
    if (debug) console.log('repoName Change', e.target.value);
    setRepoName(e.target.value);
  };

  const handleRedeemCoupon = (e: MouseEvent) => {
    e.preventDefault();
    setErrorMessage('');
    if (debug) console.log('SubscriptionByCoupon | redeemCoupon');
    let params = {
      type: coupon.productDetails.level,
      repoName: repoName,
      allowedInvites: coupon.allowedInvites,
      allowedRepositories: coupon?.allowedRepositories
        ? coupon.allowedRepositories
        : 1,
      duration: coupon.days,
      source: 'coupon',
      sourceId: code,
    };
    addSubscription(params).then((resp) => {
      if (debug)
        console.log('SubscriptionByCode | addSubscription {resp}: ', resp);
      if (resp.errors.length) {
        setErrorMessage(resp.errors[0]);
      } else {
        setUser((prev) => ({ ...prev, appInfo: resp.data[0] }));
        navigate('/home');
      }
    });
  };

  if (debug)
    console.log('code ', code, 'errorMessage', errorMessage, 'coupon', coupon);

  return (
    <OrdersCouponContainer>
      <Title>Redeem a coupon code for a subscription.</Title>
      <CodeForm>
        <Label>Enter Code to Retrieve Coupon:</Label>
        <BasicTextInput
          width={'150px'}
          value={code}
          onChange={handleCodeUpdate}
        />
        <TextButton
          width={150}
          height={32}
          label={'Retrieve Coupon'}
          onClick={handleCodeSubmit}
        />
      </CodeForm>
      {errorMessage ? (
        <FlexRow>
          <ErrorMessage>Error: {errorMessage}</ErrorMessage>
        </FlexRow>
      ) : null}
      <FlexRow>
        {coupon ? (
          <CouponCard
            coupon={coupon}
            repoName={repoName}
            onRepoNameChange={handleRepoNameChange}
            onRedeem={handleRedeemCoupon}
          />
        ) : (
          <ErrorMessage>
            Enter Code to Retrieve Subscription Coupon
          </ErrorMessage>
        )}
      </FlexRow>
    </OrdersCouponContainer>
  );
};

export default SubscriptionByCoupon;

const OrdersCouponContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const Title = styled.div`
  display: flex;
  width: 600px;
  margin: 20px;
  font-size: ${(p) => p.theme.sizes.xlarge};
  color: ${(p) => p.theme.palette.textPrimary};
`;

const CodeForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 600px;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;

const Label = styled.div`
  display: flex;
  white-space: nowrap;
  font-size: ${(p) => p.theme.sizes.small};
  margin-right: 10px;
`;

const ErrorMessage = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  font-style: italic;
  color: ${(p) => p.theme.palette.accentSecondary};
`;
