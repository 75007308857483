import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import TileCard from '@as_core/tiles/TileCard';
import useUserSettings from '@utils/api/useUserSettings';
import homeCards from '@app_config/homeCards';

import useCognito from '@as_core/account/useCognito';

export type HomeTileCardT = {
  id: string;
  isPinned: boolean; // for more than basic account -- allow use to pin cards to front
  title: string; // card title
  newTab: boolean; // launch in external tab if external link
  icon: React.ReactNode; // icon to use in the card
  description: string; // description
  link: string; // link: either internal reference or external
  enable?: string[]; // disable if not part of subscription level
  restrict?: string[]; // don't show if restricted by subscription level
};

interface propsT {
  subscriptionLevel: string;
}

const debug = false;

// display a set of active/inactive tiles
const HomePageTiles = (props: propsT) => {
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [tiles, setTiles] = useState(homeCards);

  if (debug) console.log('HomePageTiles | props: ', props);

  const { subscriptionLevel } = props;
  const { getUserSettings, updateUserSettings } = useUserSettings();
  const { getToken } = useCognito();

  useEffect(() => {
    if (!settingsLoaded && subscriptionLevel !== 'basic') {
      fetchUserSettings();
    } else {
      setSettingsLoaded(true);
    }
  }, [settingsLoaded, subscriptionLevel]);

  const fetchUserSettings = async () => {
    try {
      const token = getToken();
      const resp = await getUserSettings(token, 'home');
      const pinnedTiles = resp?.data?.pinnedTiles || [];

      const updatedTiles = tiles
        .map((tile) => ({
          ...tile,
          isPinned: pinnedTiles.includes(tile.id),
        }))
        .filter((tile) => !tile.restrict?.includes(subscriptionLevel))
        .sort((a, b) => (a.isPinned === b.isPinned ? 0 : a.isPinned ? -1 : 1));
      setTiles(updatedTiles);
    } catch (error) {
      console.error('Failed to fetch user settings:', error);
    } finally {
      setSettingsLoaded(true);
    }
  };

  const handlePinnedToggle = (e: React.MouseEvent, id: string) => {
    e.preventDefault();

    const updatedTiles = tiles.map((tile) =>
      tile.id === id ? { ...tile, isPinned: !tile.isPinned } : tile
    );

    const pinnedTiles = updatedTiles
      .filter((tile) => tile.isPinned)
      .map((tile) => tile.id);

    updateUserSettings(getToken(), 'home', { pinnedTiles });
    setTiles(updatedTiles);
  };

  const sortedTiles = useMemo(
    () =>
      tiles
        .filter((tile) => !tile.restrict?.includes(subscriptionLevel))
        .sort((a, b) => (a.isPinned === b.isPinned ? 0 : a.isPinned ? -1 : 1)),
    [tiles, subscriptionLevel]
  );

  return (
    <TileContainer>
      <Cards>
        {settingsLoaded &&
          sortedTiles.map((card) => (
            <TileCard
              key={card.id}
              onClickPinned={handlePinnedToggle}
              subscriptionLevel={subscriptionLevel}
              card={card}
            />
          ))}
      </Cards>
    </TileContainer>
  );
};

const TileContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export default HomePageTiles;
