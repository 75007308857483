import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HomeTileCardT } from './HomePageTiles';

interface propsT {
  subscriptionLevel?: string;
  card: HomeTileCardT;
}

const debug = false;

// TODO: Update so that will support step number on top of the border, and description underneath

const SimpleTileCard = (props: propsT) => {
  const { card } = props;
  if (debug) console.log('TileCard', props);

  const getCardContent = (active, card) => {
    return (
      <CardContent active={active}>
        <Header>{card.title}</Header>
        <Icon>{card.icon}</Icon>
      </CardContent>
    );
  };

  return (
    <StyledCard active={true}>
      <StyledLink to={card.link}>{getCardContent(true, card)}</StyledLink>
    </StyledCard>
  );
};

export default SimpleTileCard;

const StyledLink = styled(Link)`
  width: 100%;
`;

export const StyledCard = styled.div<{ active: boolean }>`
  display: flex;
  padding: 3px;
  border-radius: 15px;
  opacity: ${(p) => (p.active ? 1.0 : 0.5)};
  background-color: ${(p) =>
    p.active
      ? p.theme.palette.backgroundTertiary
      : p.theme.palette.backgroundQuaternary};
  :hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary};
  }
`;

export const CardContent = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  padding: 5px;
  height: 180px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  :hover {
    border: 1px solid
      ${(p) =>
        p.active
          ? p.theme.palette.accentPrimary
          : p.theme.palette.accentSecondary};
  }
`;

const Header = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.xlarge};
  min-height: 24px;
  justify-content: center;
  text-align: center;
`;

const Icon = styled.div`
  display: flex;
  width: 50%;
  height: 50%;
  justify-content: center;
  align-items: center;

  & svg {
    width: 100%;
    height: 100%;
  }
`;
