import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ColumnsIcon = ({size = 24}:{size?: number}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  return (<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={color} d="M20.9,2H3.1C1.5,2,.2,3.2.2,4.6v14.7c0,1.4,1.3,2.6,2.8,2.6h17.8c1.6,0,2.8-1.2,2.8-2.6V4.6c0-1.5-1.3-2.6-2.8-2.6ZM15,3.4v17.2h-6V3.4h6ZM1.8,19.4V4.6c0-.7.6-1.2,1.3-1.2h4.4v17.2H3.1c-.7,0-1.3-.6-1.3-1.2ZM22.2,19.4c0,.7-.6,1.2-1.3,1.2h-4.4V3.4h4.4c.7,0,1.3.6,1.3,1.2v14.7Z"/>
  </svg>)
}

export default ColumnsIcon;