import React from 'react';
import { FlexColumn, FlexRow } from '@as_core/elements/flexStyles';
import styled from 'styled-components/macro';
import { TextRegular, TextTitle } from '@as_core/elements/textStyles';
import ProcessSteps from './ProcessSteps';
import { StepT } from './ProcessStepTile';

const UserSteps: StepT[] = [
  { number: 1, image: '/img/Request_Process_Images/steps/ShoppingCart.svg', text: 'Complete your order' },
  { number: 2, image: '/img/Request_Process_Images/steps/96WellPlate_Upload.svg', text: 'Plate compounds as per the instructions' },
  { number: 3, image: '/img/Request_Process_Images/steps/UploadCompound.svg', text: 'Upload the plate map using the upload tool' },
  { number: 4, image: '/img/Request_Process_Images/steps/ShippingTips.svg', text: 'Ship compounds and enter shipping information' },
]

const PartnerSteps1: StepT[] = [
  { number: 5, image: '/img/Request_Process_Images/steps/delivery_Compound.svg', text: 'Compounds received' },
  { number: 6, image: '/img/Request_Process_Images/steps/CellularBiology.svg', text: 'Screen in process' },
  { number: 7, image: '/img/Request_Process_Images/steps/dataQuality.svg', text: 'Data processing' },
]

const PartnerSteps2: StepT[] = [
  { number: 8, image: '/img/Request_Process_Images/steps/approved.svg', text: 'Data QC' },
  { number: 9, image: '/img/Request_Process_Images/steps/dashboard_Compound.svg', text: 'Data available' },
  { number: 10, image: '/img/Request_Process_Images/steps/CompoundUniverse.svg', text: 'Analyze results in the Universe' },
]

const AssayInstructions = () => {

  return (
    <CategoriesContainer>
      <FlexColumn h_centered width={'800px'}>
        <TextTitle color={'accent'}>Accelerate your Research</TextTitle>
        <FlexRow height={'10px'}></FlexRow>
        <TextRegular color={'primary'}>
          AsedaSciences and its screening partners provides a simple 4-step process with easy-to-follow
          instructions on how much sample to ship and the laboratory location and key contact. You can monitor the
          progress of your order online. Just follow the instructions provided via the web tool and respond to any
          emails or alerts provided to you.
        </TextRegular>
        <FlexRow height={'10px'}></FlexRow>
        <FlexRow width={'100%'}>
          <TextRegular color={'primary'} >
            Your simple 4-step process:
          </TextRegular>
        </FlexRow>
        <FlexRow width={'100%'}>
          <ProcessSteps steps={UserSteps} />
        </FlexRow>
        <FlexRow height={'10px'}></FlexRow>
        <FlexRow width={'100%'}>
          <TextRegular color={'primary'} >
            Then monitor your status online. You will receive an automated email alert when your results are available.
          </TextRegular>
        </FlexRow>
        <FlexRow height={'30px'}></FlexRow>
        <FlexRow width={'100%'} h_centered>
          <TextPill>SERVICE PROVIDER PROCESS</TextPill>
        </FlexRow>
        <FlexRow width={'80%'}>
          <ProcessSteps steps={PartnerSteps1} />
        </FlexRow>
        <FlexRow width={'80%'}>
          <ProcessSteps steps={PartnerSteps2} />
        </FlexRow>
        <FlexRow height={'20px'}></FlexRow>
        <FlexRow width={'100%'} h_centered>
          <TextRegular color={'primary'} >
            View your results in 3RnD and take advantage to the state-of-the-art visualization.
          </TextRegular>
        </FlexRow>
        <FlexRow height={'20px'}></FlexRow>
      </FlexColumn>
    </CategoriesContainer>
  );
};

export default AssayInstructions;

const CategoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextPill = styled.div`
  width: max-content;
  display: flex;
  border-radius: 15px;
  padding: 5px 10px;
  background: ${(p) => p.theme.palette.accentSecondary};
`;