import React from 'react';
import styled from 'styled-components/macro';

const debug = false;
const PastOrders = () => {
  debug && console.log('PastOrders');

  return (
    <Container>
      Default Component for PastSearches
    </Container>
  );
};

export default PastOrders;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

