import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyleProvider } from '@theme/AppStyles';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store';
import App from './App';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://ab60862980e5fb070645e66745bcc93b@o4508739797581824.ingest.us.sentry.io/4508739799482368',
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://3rnd.thewarriors3.com'],
  // Session Replay
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const render = () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <StyleProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StyleProvider>
    </Provider>
  );
};

render();
