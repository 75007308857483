import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const CompoundQCIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={colorHighlight}
          d="M21.4,8.2L13,3V.8c0-1-1.5-1-1.5,0v2.2L3.5,7.7c-.2.1-.4.4-.4.7v9.5l-2.2,1.4c-.8.6.1,1.8.9,1.2l2.1-1.3,8,4.7c.2.1.5.1.8,0l8.8-5.3v-10.4h-.1ZM19.9,17.8l-7.6,4.5-7.6-4.5v-9l7.6-4.5,7.6,4.5v9Z" />

    <path fill={color}
          d="M14.7,14.2v-5.7c0-1.3-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4v5.7c-.7.7-1.2,1.6-1.2,2.7,0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6-.5-2-1.2-2.7ZM12.3,19.3c-1.3,0-2.4-1.1-2.4-2.4s.3-1.3.8-1.8l.4-.4v-6.3c0-.7.5-1.2,1.2-1.2s1.2.5,1.2,1.2v6.3l.4.4c.5.5.8,1.1.8,1.8,0,1.3-1.1,2.4-2.4,2.4Z" />
    <rect fill={colorHighlight} x="11.7" y="10.9" width="1.2" height="7.2" />
    <circle fill={colorHighlight} cx="12.3" cy="16.9" r="1.8" />
  </svg>
)
}

export default CompoundQCIcon;