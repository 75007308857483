import React, {useEffect, useState} from "react";
import styled from 'styled-components/macro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DataLoading from "@as_core/elements/DataLoading";
import {FilesT} from "@as_core/controls/inputs/FileUpload";
import useRequests, {RequestT} from "../hooks/useRequests";
import useRequestForms, {RequestFormT} from '../hooks/useRequestForms';
import useRequestStatus from '../hooks/useRequestStatus';

interface PropsI {
  request: RequestT;
  formType: string;
  formName: string;
  requestForms: RequestFormT[];
  stage?: string;
  setUpdated: ()=>void;
  handleClose: ()=>void;
  files: FilesT;
}

const debug = false;
const UploadRequestFormFiles = (props:PropsI) => {
  const {request, formType, stage='platemapAvailable', setUpdated, handleClose, files} = props;
  const { updateRequest } = useRequests();
  const { createRequestFormWithFile } = useRequestForms();
  const { updateRequestStatus } = useRequestStatus();
  const queryClient = useQueryClient();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  if (debug) console.log('UploadRequestFormFiles | request:', request, 'files:', files);

  const createRequestForms = async (request: RequestT, files: FilesT): Promise<RequestFormT[]> => {
    const promises = files.map(async (file, index): Promise<RequestFormT> => {
      // create the request - need to send as form data to handle the file object for s3
      const formData = new FormData();
      formData.append('request_id', request.id);
      formData.append('name',request.name + '_form_' + formType + '_' + index );
      formData.append('form_type', formType);
      formData.append('file',  file);
      if (debug) console.log('UploadRequestFormFiles | formData', formData);
      return createRequestFormWithFile(formData);
    });
    const requestForms: RequestFormT[] = await Promise.all(promises);
    if (stage === 'platemapAvailable') {
      await updateRequest(request.id, {'status': 'Platemap Available'});
      await queryClient.invalidateQueries({ queryKey: ['requests', 'user'] });
    }
    if (debug) console.log('UploadRequestFormFiles | createRequestForms | requestForms ', requestForms);
    return requestForms;
  }

  const mutation = useMutation({
    mutationFn: () => updateRequestStatus(request, stage),
    onSuccess: (data) => {
      if (debug) console.log('StatusUpdate | mutation | OnSuccess | data:', data);
      queryClient.invalidateQueries({ queryKey: ['requestStatus', request.id] }).then();
      handleClose();
    }
  });

  useEffect(() => {
    if (!isUploading) {
      if (files.length !== 0) {
        setIsUploading(true);
        if (debug) console.log('UploadRequestFormFiles | creating assay for request:', request?.id);
        createRequestForms(request, files)
        .then((requestForms) => {
            if (debug) console.log('UploadRequestFormFiles | requestIds ', requestForms);
            mutation.mutate();
            setIsUploading(false);
            setUpdated();
          }
        );
      } else {
        if (debug) console.log('UploadRequestFormFiles | USE_EFFECT | no files -- skipping');
      }
    }
  }, [files]);

  return (
    <ListContainer>
      {isUploading ?
        <DataLoading />
        :
        null
      }
    </ListContainer>
  );
};

export default UploadRequestFormFiles;

const ListContainer = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
