import React, { useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import useStripeProducts, { StripeProductT } from '../products/useStripeProducts';
import { UserContext } from '@stores/UserContext';
import ProductsTable from '../products/components/ProductsTable';
import OrdersCart from './components/OrdersCart';
import { FlexRow, FlexColumn } from '@components/layout/FlexStyles';
import { ListLoader } from '@as_core/elements/listStyles';
import ProductCategorySelector from '@business/products/components/ProductCategorySelector';
import { UseCartObjectReturnType } from '@as_core/hooks/useShoppingCart';
import { useQuery } from '@tanstack/react-query';

interface PropsI {
  ordersCart: UseCartObjectReturnType;
}

const debug = false;
const OrderCreate = (props: PropsI) => {
  const { ordersCart } = props;
  const { category: activeCategory } = useParams<{category: string}>();
  const { getAllProducts, getProductCategories } = useStripeProducts();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const orgType = user?.regInfo?.organization?.type
    ? user.regInfo.organization.type
    : 'commercial';
  if (debug) console.log('OrderCreate | user:', user, 'orgType:', orgType, 'region', user.userRegion);

  // load the available products
  let {
    isPending: isProductsPending,
    isError: isProductsError,
    error: productsError,
    data: products,
  } = useQuery({
    queryKey: ['products', 'all'],
    queryFn: () => getAllProducts(user.userRegion),
  });
  if (isProductsError)
    console.error('error loading products:', productsError.message);

  // load the available products categories
  let {
    isPending: isCategoriesPending,
    isError: isCategoriesError,
    error: categoriesError,
    data: productCategories,
  } = useQuery({
    queryKey: ['products', 'categories'],
    queryFn: () => getProductCategories('screen'),
  });
  if (isCategoriesError)
    console.error('error loading categories:', categoriesError.message);

  const getCategoryIcon = (product: StripeProductT): React.ReactNode => {
    console.log('getCategoryIcon', product, productCategories);
    const category = productCategories.find((c) => c.categoryCode === product.metadata.categoryCode);
    if (category) {
      return category.icon;
    }
    return <></>;
  }

  const handleSelectCategory = (code: string) => {
    if (debug) console.log('OrderCreate | handleSelectCategory', code);
    navigate(`/orders/create/${code}`);
  };

  // add product to cart at minimum order size;
  const handleAddToCart = (product: StripeProductT, priceType: string) => {
    const quantity = Number(product.metadata.unitOrderMinSize);
    if (debug)
      console.log(
        'OrderCreate | handleAddToCart',
        ordersCart.data,
        quantity,
        product,
        priceType
      );
    ordersCart.add(product, quantity, priceType);
  };

  // set default category once categories are loaded
  useEffect(() => {
    if (productCategories !== undefined) {
      if (activeCategory === undefined)
        navigate(`/orders/create/${productCategories[0]?.categoryCode}`);
    }
  }, [productCategories]);

  // in case user deletes all screens from cart and just complementary screen -- delete.
  if (
    ordersCart.length() === 1 &&
    Object.hasOwn(ordersCart.data, 'AS-SCREEN-3RND')
  ) {
    ordersCart.delete('AS-SCREEN-3RND');
  }

  const showProducts =
    products === undefined
      ? []
      : products.filter(
          (p) =>
            p.metadata.catalogNumber !== 'AS-SCREEN-3RND' &&
            p.metadata.categoryCode === activeCategory
        );

  if (debug) console.log('OrderCreate | isPending, products', isProductsPending, products);
  if (debug) console.log('OrderCreate | isPending, showCategories', isCategoriesPending, productCategories);
  return (
    <OrdersCreateContainer>
      {isProductsPending || isCategoriesPending ? (
        <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
          <ListLoader size={50} />
        </FlexRow>
      ) : (
        <FlexRow width={'100%'}>
          {/* products list */}
          <FlexColumn width={'100%'} h_centered>
            <ProductsContainer>
              <FlexRow v_centered>
                <ProductCategorySelector
                  productCategories={productCategories}
                  activeCategory={activeCategory}
                  onSelect={handleSelectCategory}
                />
              </FlexRow>
              <ProductsTable
                orgType={orgType}
                products={showProducts}
                onSelect={handleAddToCart}
              />
              <ProductSettings>
                <Label>Customer Type:</Label>
                <Value>{orgType}</Value>
                <Label>Customer Region:</Label>
                <Value>{user.userRegion}</Value>
              </ProductSettings>
            </ProductsContainer>
            {/*
            <OrdersCart
              cart={ordersCart}
              showTotalCost={false}
              getCategoryIcon={getCategoryIcon}
            />
            */}
          </FlexColumn>
          {/* shopping cart */}
          <FlexColumn width={'225px'}>
            <OrdersCart cart={ordersCart} width={225} type={'tile'} getCategoryIcon={getCategoryIcon} />
          </FlexColumn>
        </FlexRow>
      )}
    </OrdersCreateContainer>
  );
};

export default OrderCreate;

const OrdersCreateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const ProductsContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
`;

const ProductSettings = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Label = styled.div`
  font-size: ${(p) => p.theme.sizes.xxsmall};
  font-style: italic;
  padding-left: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled(Label)``;
