import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import BasicTable from '@as_core/tables/BasicTable';
import { getObjectValueByKey } from '@utils/misc';
import { FieldsTypeT } from '@subApps/admin/components/ObjectDetails';
import { getTableRows } from '@subApps/admin/components/shared';
import SearchField from '@as_core/components/inputs/SearchField';
import { UserEventsItemT } from '@utils/useUserAppInfo';

const columns: FieldsTypeT[] = [
  {
    value: 'view',
    label: '',
    type: 'action',
    id: { key: 'authId', label: 'User' },
    width: 40,
  },
  { value: 'authId', label: 'Id', width: 260 },
  { value: 'authEmail', label: 'Email', width: 320 },
  { value: 'event', label: 'Event', width: 90 },
  { value: 'eventType', label: 'Event Type', width: 100 },
  { value: 'eventCount', label: 'Event Count', width: 180 },
];

export type EventSummaryT = {
  authId: string;
  authEmail: string;
  event: string;
  eventType: string;
  eventCount: number;
};

interface UserEventsListI {
  allUserEvents: UserEventsItemT[];
  handleSelect: (userId: string) => void;
}

const debug = false;

const reduceUserEventsList = (events: UserEventsItemT[]): EventSummaryT[] => {
  const summary: Record<string, EventSummaryT> = {};

  events?.forEach((event) => {
    const eventKey = `${event.authId}_${event.event_type}`;

    if (!summary[eventKey]) {
      summary[eventKey] = {
        authId: event.authId,
        authEmail: event.authEmail || '',
        event: event.event,
        eventType: event.event_type,
        eventCount: 1,
      };
    } else {
      summary[eventKey].eventCount += 1;
    }
  });

  return Object.values(summary);
};

const UserEventsList = ({ allUserEvents, handleSelect }: UserEventsListI) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const summary = useMemo(
    () => reduceUserEventsList(allUserEvents),
    [allUserEvents]
  );

  const filteredUserEvents = useMemo(() => {
    if (!searchTerm) return summary;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return summary.filter(
      (event) =>
        getObjectValueByKey(event, 'authId')
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        getObjectValueByKey(event, 'authEmail')
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        getObjectValueByKey(event, 'event')
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        getObjectValueByKey(event, 'eventType')
          .toLowerCase()
          .includes(lowerCaseSearchTerm)
    );
  }, [summary, searchTerm]);

  if (debug) console.log('UserEventsList | allUserEvents:', allUserEvents);
  if (debug)
    console.log('UserEventsList | filteredUserEvents:', filteredUserEvents);

  const handleClick = (action: string, userId: string) => {
    if (debug)
      console.log(
        'UserEventsList | handleClick action:',
        action,
        'userId:',
        userId
      );
    handleSelect(userId);
  };

  return (
    <UserEventsListContainer>
      <SearchField
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder='Search user events...'
      />

      <ScrollContainer>
        <BasicTable
          key='user-events-table'
          stickyHeader
          fields={columns}
          rows={getTableRows(filteredUserEvents, columns, handleClick)}
        />
      </ScrollContainer>
    </UserEventsListContainer>
  );
};

export default UserEventsList;

const UserEventsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  gap: 32px;
  margin-top: 32px;
`;

const ScrollContainer = styled.div`
  display: flex;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
`;
