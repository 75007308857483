import React from 'react';
import MolSvgTile from './MolSvgTile';

// top-level component to re-use across areas
interface MolImgTileProps {
  uuid?: string;
  mol_svg?: object;
  width?: number;
  className?: string;
  hover?: boolean;
}

const debug = false;
const MolImgTile = (props: MolImgTileProps) => {
  const { className, width = 300, hover } = props;
  if (debug) console.log('MolImgTile | props:', props);

  // switch between SVG or URL as stabilize platform
  if (props?.mol_svg) {
    return (
      <>
        <MolSvgTile
          width={width}
          mol_svg={props?.mol_svg}
          hover={hover}
          className={className}
        />
      </>
    );
  } else {
    return <>Unknown Image Format</>;
  }
};

export default MolImgTile;
