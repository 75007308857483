import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import UserEventsList from '@subApps/admin/components/UserEventsList';
import UserEventsDetail from '@subApps/admin/components/UserEventsDetail';
import useUserAppInfo, { UserEventsItemT } from '@utils/useUserAppInfo';
import { BasicTextInput } from '@as_core/account/forms/FormStyles';

const debug = false;

const UserInsights = () => {
  // API and CONTEXT
  const { user } = useContext(UserContext);
  const { getAllUserEvents } = useUserAppInfo();

  // STATE VARIABLES
  const [daysSince, setDaysSince] = useState<string>('30');
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [loaded, setLoaded] = useState<boolean>(false);
  const [allUserEvents, setAllUserEvents] = useState<UserEventsItemT[]>([]);
  const [userEvents, setUserEvents] = useState<UserEventsItemT[]>([]);
  const [error, setError] = useState<string | null>(null);

  if (debug) console.log('UserInsights | user:', user);

  // HANDLERS
  const onSelect = (userId: string) => {
    if (debug) console.log('UserInsights | onSelect userId', userId);
    setSelectedUserId(userId);
  };

  const handleChange = (value: string) => {
    if (value !== daysSince) {
      setDaysSince(value);
      setLoaded(false);
    }
  };

  // DATA LOADING
  useEffect(() => {
    if (!loaded) {
      getAllUserEvents(daysSince)
        .then((response) => {
          if (debug) console.log('getAllUserEvents | response', response);
          setAllUserEvents(response);
          setError(null);
        })
        .catch((err) => {
          console.error('Failed to fetch user events:', err);
          setError('Failed to load user events. Please try again later.');
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, [loaded, daysSince, getAllUserEvents]);

  useEffect(() => {
    if (selectedUserId) {
      const filteredEvents = allUserEvents.filter(
        (event) => event.authId === selectedUserId
      );
      setUserEvents(filteredEvents);
    }
  }, [selectedUserId, allUserEvents]);

  if (debug) console.log('UserInsights | allUserEvents:', allUserEvents);
  if (debug) console.log('UserInsights | selectedUserId:', selectedUserId);

  return (
    <Container>
      <ParameterBox>
        <Item>Show Data for the Past Number of Days:</Item>
        <Item>
          <BasicTextInput
            value={daysSince}
            onChange={(e) => handleChange(e.target.value)}
            type='number'
            min='1'
            placeholder='Enter number of days'
          />
        </Item>
      </ParameterBox>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {selectedUserId ? (
        <UserEventsDetail
          userEvents={userEvents}
          backToList={() => setSelectedUserId('')}
        />
      ) : (
        <UserEventsList allUserEvents={allUserEvents} handleSelect={onSelect} />
      )}
    </Container>
  );
};

export default UserInsights;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ParameterBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 32px;
`;

const Item = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  margin-left: 5px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: ${(p) => p.theme.sizes.small};
  margin-bottom: 20px;
`;
