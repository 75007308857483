import React from 'react';
import styled from 'styled-components/macro';
import '../../components-style/SelectableTable.scss';
interface ChildrenExtraPropsT {
  scrollY?: boolean;
  hasBorder?: boolean;
}

interface PagePanelUploadProps {
  title?: string | null;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
}

const PagePanelUpload = (props: PagePanelUploadProps) => {
  const {
    title = null,
    width = '100%',
    height = '100%',
    margin = '3px 6px',
    padding = '3px',
  } = props;
  return (
    <Panel width={width} height={height} margin={margin} padding={padding}>
      {title !== null ? <PanelTitle>{title}</PanelTitle> : <></>}
      <PanelChildren>{props?.children}</PanelChildren>
    </Panel>
  );
};

export default PagePanelUpload;

const Panel = styled.div.attrs(() => ({
  className: 'panel', // Add the class name here
}))<{ width: string; height: string; margin: string; padding: string }>`
  display: flex;
  border-radius: 15px;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  margin: ${(p) => p.margin};
  padding: ${(p) => p.padding};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  align-self: center;
`;

const PanelChildren = styled.div<ChildrenExtraPropsT>`
  display: flex;
  overflow-y: ${(p) => {
    return p.scrollY ? 'scroll' : 'none';
  }};
  position: relative;
`;

const PanelTitle = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: ${(p) => p.theme.sizes.large};
  font-weight: ${(p) => p.theme.weights.heavy};
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary};
  height: 50px;
`;
