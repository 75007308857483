import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { FlexRow, FlexItem } from '../elements/flexStyles';
import { TextTitle } from '../elements/textStyles';
import useBugReports from './useBugReports';
import EditIcon from '@components/icons/edit.icon';
import MenuButton from '@components/controls/MenuButton';
import TrashIcon from '@components/icons/trash.icon';
import { APP_CONFIG } from '@app_config/app';
import BasicTable from '@as_core/tables/BasicTable';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import ViewIcon from '@as_core/icons/view.icon';
import { IBugsList, IBugReport } from './bugs.types';
import AddCommentIcon from '@components/icons/addComment.icon';
import Checkbox from '@components/controls/CheckBox';

const tableFields: BasicTableFieldsT[] = [
  { value: 'view', label: '', type: 'computed', width: 40 },
  { value: 'edit', label: '', type: 'computed', width: 40 },
  { value: 'comment', label: '', type: 'computed', width: 40 },
  { value: 'title', label: 'Title', align: 'left', width: 300 },
  {
    value: 'submit.time_submitted',
    label: 'Date Submitted',
    type: 'date',
    width: 150,
  },
  { value: 'status', label: 'Status', width: 100 },
  { value: 'delete', label: '', type: 'computed', width: 40 },
];

type ShownBugT = Array<'open' | 'closed'>;

function getApiBugType(shownBugs: ShownBugT) {
  let bug_type: 'open' | 'closed' | 'all' = 'open';

  if (shownBugs.includes('open') && shownBugs.includes('closed')) {
    bug_type = 'all';
  } else if (shownBugs.includes('closed')) {
    bug_type = 'closed';
  }
  return bug_type;
}

const BugsList = ({
  isNewBugReported,
  onActionBug,
  inEditBug,
  deletedBugId,
  setDeletedBugId,
}: IBugsList) => {
  const { getBugReports } = useBugReports();
  const [bugReports, setBugReports] = useState<IBugReport[]>([]);
  const [shownBugs, setShownBugs] = useState<ShownBugT>(['open']);

  useEffect(() => {
    const fetchData = async () => {
      return getBugReports({
        system: APP_CONFIG.name,
        bug_type: getApiBugType(shownBugs),
      });
    };
    fetchData().then((reports) => {
      setBugReports(reports)
    });
  }, [isNewBugReported, shownBugs]);

  useEffect(() => {
    if (deletedBugId) {
      setBugReports((prev) => prev.filter((bug) => bug.uuid !== deletedBugId));
      setDeletedBugId();
    }
  }, [deletedBugId]);

  const handleClick = (id: string, actionType: string) => {
    onActionBug(
      bugReports.find((report) => report.uuid === id),
      actionType
    );
  };

  const getTableRows = useMemo(() => {
    return bugReports.map((bugReport) => ({
      ...tableFields.reduce((row, field) => {
        const value = _.get(bugReport, field.value, '');

        if (field.type === 'date' && value) {
          row[field.value] = new Date(value).toDateString();
        } else if (field.value === 'view') {
          row[field.value] = (
            <MenuButton
              onClick={() => handleClick(bugReport.uuid, 'view')}
              icon={<ViewIcon />}
              text='View Bug Report'
              tooltipPlacement='bottom'
            />
          );
        } else if (
          field.value === 'edit' &&
          (bugReport.status === 'submitted' ||
            bugReport.status === 'acknowledged')
        ) {
          row[field.value] = (
            <MenuButton
              onClick={() => handleClick(bugReport.uuid, 'edit')}
              icon={<EditIcon />}
              text='Edit Bug Report'
              tooltipPlacement='bottom'
              isActive={inEditBug?.uuid === bugReport.uuid}
            />
          );
        } else if (
          field.value === 'delete' &&
          bugReport.status === 'submitted'
        ) {
          row[field.value] = (
            <MenuButton
              onClick={() => handleClick(bugReport.uuid, 'delete')}
              icon={<TrashIcon />}
              text='Delete Bug Report'
              tooltipPlacement='bottom'
            />
          );
        } else if (field.value === 'comment') {
          row[field.value] = (
            <MenuButton
              onClick={() => handleClick(bugReport.uuid, 'comment')}
              icon={<AddCommentIcon />}
              text='Add Comment to Bug Report'
              tooltipPlacement={'bottom'}
            />
          );
        } else {
          row[field.value] = value;
        }
        return row;
      }, {}),
    }));
  }, [bugReports, handleClick, inEditBug, tableFields]);

  const onChangeShownBugs = (status: 'open' | 'closed') => {
    setShownBugs((prev) => {
      if (prev.includes(status)) {
        const updated = prev.filter((bug) => bug !== status);
        return updated.length === 0 ? ['open'] : updated;
      } else {
        return [...prev, status];
      }
    });
  };

  return (
    <Content>
      <Heading color='accent'>
        Your Submitted Bugs:{' '}
        <Checkbox
          onChange={() => onChangeShownBugs('open')}
          selected={shownBugs.includes('open')}
          text='Open'
        />
        <Checkbox
          onChange={() => onChangeShownBugs('closed')}
          selected={shownBugs.includes('closed')}
          text='Closed'
        />
      </Heading>
      {bugReports.length === 0 ? (
        <FlexRow width='100%' height='100%' h_centered>
          <FlexItem>
            <Heading color='primary'>
              You currently do not have any open reported bugs
            </Heading>
          </FlexItem>
        </FlexRow>
      ) : (
        <TableContainer>
          <ScrollBox>
            <BasicTable
              fields={tableFields}
              rows={getTableRows}
              border={false}
            />
          </ScrollBox>
        </TableContainer>
      )}
    </Content>
  );
};

export default BugsList;

const Content = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 225px;
  width: 100%;
`;

const ScrollBox = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

const Heading = styled(TextTitle)`
  width: 100%;
  font-size: ${(p) => p.theme.sizes.large};
  display: flex;
  gap: 8px;
  align-items: center;
`;
