import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';

import PageContainer from '@as_core/pages/PageContainer';
import usePlots from '../usePlots';
import GraphTile from './GraphTile';
import Alert from '@components/elements/Alert';
import useCognito from '@as_core/account/useCognito';
import MenuButton from '@components/controls/MenuButton';
import NewGraphIcon from '@components/icons/newGraph.icon';
import { FlexItem, FlexSpacer } from '@as_core/elements/flexStyles';

import SelectDataModal from '../select-data-modal/SelectDataModal';
import ErrorMessages from '@as_core/elements/UserMessages';
import { MessageItem } from '@as_core/elements/UserMessages';
import UploadIcon from '@components/icons/upload.icon';
import { Grid, Paper } from '@mui/material';
import SearchField from '@as_core/components/inputs/SearchField';
import { debounce } from 'lodash';

const debug = false;
export const PlotList = () => {
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plotToDelete, setPlotToDelete] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [plots, setPlots] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(search);
  const { getPlots, deletePlot } = usePlots();
  const { getToken } = useCognito();

  // LOAD DATA
  useEffect(() => {
    if (!loaded) {
      const params = {};
      getPlots(getToken(), params).then((plotData) => {
        setLoaded(true);
        setPlots(plotData?.data || []);
      });
    }
  }, [loaded]);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  };

  const debouncedFilterUpdate = debounce((value) => {
    setFilter(value);
  }, 300);

  useEffect(() => {
    debouncedFilterUpdate(search);

    return () => {
      debouncedFilterUpdate.cancel();
    };
  }, [search]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setSearch('');
      setFilter('');
    }
  };

  const plotFilter = (plot) => {
    if (!filter.trim()) return true;

    const tokens = filter.match(/\S+/g);
    const fieldsToSearch = [plot.name, plot.userId, plot.compoundIds];

    const createdDate = new Date(plot.createdOn);
    const modifiedDate = new Date(plot.modifiedOn);

    fieldsToSearch.push(
      createdDate.toLocaleDateString(),
      createdDate.toUTCString(),
      modifiedDate.toLocaleDateString(),
      modifiedDate.toUTCString()
    );

    const matchText = fieldsToSearch.join().toLowerCase();

    return tokens.some((token) => matchText.includes(token.toLowerCase()));
  };

  const filteredPlots = useMemo(() => {
    return plots.filter(plotFilter);
  }, [plots, filter]);

  // HANDLERS
  const handleDeleteClick = (uuid: string) => {
    if (debug) console.log('handleDeleteClick ', uuid);
    setPlotToDelete(uuid);
    setIsDeleteAlertOpen(true);
  };

  const _handleCancel = () => {
    setPlotToDelete('');
    setIsDeleteAlertOpen(false);
  };

  const _handleDeleteConfirm = () => {
    if (debug) console.log('deleteConfirmed for ', plotToDelete);
    deletePlot(getToken(), plotToDelete).then(() => {
      setPlots((previous) =>
        previous.filter((plot) => plot.uuid !== plotToDelete)
      );
      setPlotToDelete('');
    });
  };

  return (
    <PageContainer title='My Plots'>
      <PlotBanner>
        <LeftColumn>
          <ActionPanel>
            <FlexItem>
              <MenuButton
                text={'Create New Plot'}
                icon={<NewGraphIcon />}
                margin={'1px'}
                tooltipPlacement='bottom-end'
                onClick={() => setIsModalOpen(true)}
              />
            </FlexItem>
            <FlexItem>
              <SearchPane>
                <SearchField
                  placeholder='Search'
                  value={search}
                  onChange={updateSearch}
                  onKeyDown={handleKeyDown}
                />
              </SearchPane>
            </FlexItem>
          </ActionPanel>
        </LeftColumn>
      </PlotBanner>

      {plots.length ? (
        <Grid container spacing={3} padding={2}>
          {filteredPlots.map((plot) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={plot.uuid}>
              <Paper elevation={1}>
                <GraphTile plot={plot} handleDeleteClick={handleDeleteClick} />
              </Paper>
            </Grid>
          ))}
          <Alert
            type={'deleteConfirm'}
            title={'Delete Plot'}
            message={'Confirm delete by typing DELETE below to Enable Delete'}
            alertOpen={isDeleteAlertOpen}
            onConfirm={_handleDeleteConfirm}
            closeAlert={_handleCancel}
          />
        </Grid>
      ) : (
        <PlotContainer>
          <ErrorMessages
            messages={[
              <MessageItem key='message-item-1' color='accentSecondary'>
                You do not have any saved plots.
              </MessageItem>,
              <MessageItem key='message-item-2'>
                <NewGraphIcon />
                <FlexSpacer width={5} />
                If you have uploaded data and/or created a compound favorites
                list, click on the &quot;Create New Plot&quot; above to create a
                plot.
              </MessageItem>,
              <MessageItem key='message-item-3'>
                <UploadIcon />
                You can also create a plot after uploading new data.
              </MessageItem>,
            ]}
          />
        </PlotContainer>
      )}
      <SelectDataModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </PageContainer>
  );
};

const PlotBanner = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  width: calc(100vw - 50px);
  padding: 5px;
`;

const PlotContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px);
  width: calc(100vw - 50px);
  padding: 5px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const ActionPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SearchPane = styled.div`
  display: flex;
  padding: 5px;
  height: 30px;
  width: 400px;
`;
