import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';
import MenuButton from '@components/elements/MenuButton';
import {
  FlexBannerGroup,
  FlexBanner,
  FlexItem,
  FlexSpacer,
} from '@components/layout/FlexStyles';
import { useNavigate } from 'react-router-dom';
import MagnifiedSvgImage from '../images/MagnifiedSvgImage';
import BackArrowIcon from '@components/icons/backArrow.icon';
import Checkbox from '@components/controls/CheckBox';
import BasicColumnTable from '@as_core/tables/BasicColumnTable';
import { CompoundFieldsT } from '@stores/fields';

interface CompoundCompareProps {
  selectedCompoundIds: string[];
  allFields: CompoundFieldsT;
  fieldIds: string[];
  data: { [key: string]: string | number }[];
}

const debug = false;
const CompoundCompare = ({
  selectedCompoundIds,
  allFields,
  fieldIds,
  data,
}: CompoundCompareProps) => {
  if (debug) {
    console.log(
      'CompoundCompareData | libCompoundIds',
      selectedCompoundIds,
      'data',
      data
    );
  }

  const [basicColumns, setBasicColumns] = useState<
    { [key: string]: string | number }[]
  >([]);
  const [basicFields, setBasicFields] = useState<
    { [key: string]: string | number }[]
  >([]);
  const [hideEmptyRows, setHideEmptyRows] = useState<boolean>(true);

  _.get(allFields, 'compound_name.data_key', null);

  if (debug) {
    console.log(
      'CompoundCompareData: {selectedCompoundIds}',
      selectedCompoundIds
    );
  }

  // ###########################################################################
  // Setting rows, columns
  // ###########################################################################
  const setColumnsRows = () => {
    const compounds = data.filter((c) =>
      selectedCompoundIds.includes(c.searchKey as string)
    );
    setBasicColumns(compounds);

    const newBasicFields: {
      value: string;
      label: string;
      renderer: string;
    }[] = fieldIds
      .filter((fieldId) => allFields[fieldId].cfields === true)
      .map((fieldId) => {
        const result = {
          value: allFields[fieldId].data_key,
          label: allFields[fieldId].short_name,
          renderer: allFields[fieldId].value_renderer,
          empty: true,
        };
        compounds.forEach((compound) => {
          if (allFields[fieldId].short_name === 'Molecule') {
            result[compound.uuid] = _.get(compound, 'mol_svg', '');
          } else {
            result[compound.uuid] = _.get(
              compound,
              allFields[fieldId].data_key,
              '-'
            );
          }
          if (result[compound.uuid] !== '' && result[compound.uuid] !== '-') {
            result.empty = false;
          }
        });
        return result;
      });
    if (debug) console.log('newBasicFields: ', newBasicFields);
    setBasicFields(() => [...newBasicFields]);
  };

  useEffect(() => {
    setColumnsRows();
  }, [selectedCompoundIds, data]);

  // ###########################################################################
  // Interactions
  // ###########################################################################
  const navigate = useNavigate();

  let usedFields = basicFields;
  if (hideEmptyRows) {
    usedFields = basicFields.filter((f) => !f.empty);
  }
  if (debug)
    console.log(
      'CompoundCompareData | basicFields, basicColumns',
      basicFields,
      basicColumns
    );

  return (
    <Container>
      <MagnifiedSvgImage />
      <FlexBanner border>
        <FlexBannerGroup>
          <MenuButton
            text={'Return to prior view'}
            onClick={() => navigate(-1)}
            icon={<BackArrowIcon size={32} />}
            width={220}
          />
          <FlexSpacer />
          <FlexItem>
            <Checkbox
              selected={hideEmptyRows}
              onChange={setHideEmptyRows}
              text={'Hide Empty Rows'}
            />
          </FlexItem>
        </FlexBannerGroup>
      </FlexBanner>
      <BasicColumnTable
        columns={basicColumns}
        fields={usedFields}
        debug={false}
        fullPage={true}
        colWidth={250}
      />
    </Container>
  );
};

export default CompoundCompare;

const Container = styled.div`
  width: calc(100vw - 75px);
`;
