import React from 'react';
import styled from 'styled-components/macro';

import { UseCartObjectReturnType } from '@as_core/hooks/useShoppingCart';
import { getActionableProductQuantity, getCartShowPrice, getLineItemCost } from '@business/orders/components/utils';
import MenuButton from '@components/controls/MenuButton/MenuButton';
import TrashIcon from '@components/icons/trash.icon';

interface PropsI {
  cart: UseCartObjectReturnType;
  icon: React.ReactNode;
  catNo: string;
}

const debug = false;
const CartItemTile = (props: PropsI) => {
  const { cart, icon, catNo } = props;
  if (debug) console.log('CartItemTile | {props}:', props);

  const item = cart.data[catNo].product;

  return (
    <CartItemTileContainer>
      <TileItem><BesideIcon /><Icon>{icon}</Icon>
        <BesideIcon>
          <MenuButton
          icon={<TrashIcon />}
          onClick={() => cart.delete(catNo)}
          text={'Delete Item'}
          tooltipPlacement={'bottom-end'}
          />
        </BesideIcon>
      </TileItem>
      <TileItem><Title>{ item.name }</Title></TileItem>
      <TileItem><Label>Unit Price:</Label>{ getCartShowPrice(cart.data[catNo]) }</TileItem>
      <TileItem><Label>Quantity:</Label>{ getActionableProductQuantity(cart, catNo) }</TileItem>
      <TileItem><Label>Cost:</Label>{ getLineItemCost(cart, catNo) }</TileItem>
    </CartItemTileContainer>
  );
};

export default CartItemTile;

const CartItemTileContainer = styled.div`
  display: flex;
  position: relative;
  width: 200px;
  height: 150px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.textSecondary };
`;

const TileItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2px 0;
`

const Title = styled.div`
  display: flex;
  text-align: center;
  font-weight: bold;
  color: ${(p) => p.theme.palette.accentSecondary};
`

const Label = styled.div`
  display: flex;
  padding-right: 2px;
  width: 70px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 120px;
  max-height: 30px;
`

const BesideIcon = styled.div`
  display: flex;
  width: 32px;
`
