import React from 'react';
import styled from 'styled-components/macro';

type PropsT = {
  messages: string[] | React.ReactNode[];
};
export const CenteredMessages = (props: PropsT) => {
  return (
    <CenterContainer>
      <ErrorMessagesList>
        {props.messages.map((item, index) => (
          <Message key={index}>{item}</Message>
        ))}
      </ErrorMessagesList>
    </CenterContainer>
  );
};

export default CenteredMessages;

const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorMessagesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  padding: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const Message = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.textSecondary};
`;

export const MessageItem = styled.div<{ color?: string }>`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) =>
    p?.color === 'accent'
      ? p.theme.palette.accentSecondary
      : p.theme.palette.textSecondary};
`;
