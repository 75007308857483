import React, { useState } from 'react';
import { Cell, Row } from '../shared/RowElements';
import Alert from '@components/elements/Alert';
const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';

interface DoseResponsePair {
  dose: number;
  response: number;
}

// Define the specific structure for the expected data
interface DoseResponseData {
  compound_id: string;
  sample_id: string;
  data: DoseResponsePair[];
  dose_units ?: string;
}
// Define the structure for more generic data
interface GenericRecord {
  [key: string]: string | number | boolean | object | undefined;
}
// Main interface that combines both types
interface ImportDoseResponseReviewRowProps {
  data: DoseResponseData | GenericRecord;
  dataIdx: number;
  onClick?: () => void;
}

const ImportDoseResponseReviewRow = (props: ImportDoseResponseReviewRowProps) => {
  const { data, dataIdx, onClick } = props;
  const [alertOpen, setAlertOpen] = useState(true);
  // Type guard to check if `data` is DoseResponseData
  function isDoseResponseData(data: DoseResponseData | GenericRecord): data is DoseResponseData {
    return (
      typeof data === 'object' &&
      'compound_id' in data &&
      'sample_id' in data &&
      Array.isArray(data.data) &&
      data.data.every(
        (item) =>
          typeof (item as DoseResponsePair).dose === 'number' &&
          typeof (item as DoseResponsePair).response === 'number'
      )
    );
  }

  // If the data matches the DoseResponseData structure
  if (isDoseResponseData(data)) {
    const preview = data.data
      .slice(0, 4) // Take up to 4 pairs
      .map((pair) => `(${pair.dose.toFixed(2)}, ${pair.response})`)
      .join(', ');

    if (debug) console.log('ImportDoseResponseReviewRow | preview:', preview);

    return (
      <Row key={dataIdx}>
        <Cell>{data.compound_id}</Cell>
        <Cell>{data.sample_id}</Cell>
        <Cell>{data.data.length}</Cell>
        <Cell>{data.dose_units}</Cell>
        <Cell>{preview}</Cell>
      </Row>
    );
  } else {
    // When the data format is invalid, render the Alert. When the alert is dismissed (onConfirm or closeAlert),
    // we close the alert and call the onClick prop to navigate back (e.g. return user back to step -1).
    return (
      <Alert
        type="general"
        title="Invalid Data Format"
        width={540}
        height={345}
        message="The data is not in the correct format. Please verify your input."
        alertOpen={alertOpen}
        closeAlert={() => {
          setAlertOpen(false);
          if (onClick){
            onClick()
          } ;
        }}
        buttonText="Close Alert"
      />
    );
  }
};
export default ImportDoseResponseReviewRow;
