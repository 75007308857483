import React, { useState } from 'react';
import styled from 'styled-components/macro';
import FileUpload, { FilesT } from '@as_core/controls/inputs/FileUpload';
import { WarningAmber } from '@mui/icons-material';
import { RequestT } from '../hooks/useRequests';
import { RequestFormT } from '../hooks/useRequestForms';

import UploadRequestFormFiles from './UploadRequestFormFiles';

type PropsT = {
  request: RequestT;
  formType: string;
  formName: string;
  requestForms: RequestFormT[];
  handleClose: () => void;
  setUpdated: () => void;
};

const debug = false;
const RequestFormFilesUpload = (props: PropsT) => {
  const { request, formType, formName, requestForms, handleClose, setUpdated } =
    props;
  if (debug) console.log('RequestFormFilesUpload | request:', request);
  const [files, setFiles] = useState<FilesT>([]);

  const handleOnDrop = async (acceptedFiles: FilesT) => {
    if (debug)
      console.log('RequestFormFilesUpload | acceptedFiles: ', acceptedFiles);
    const newFiles = [];
    acceptedFiles.forEach((file) => {
      if (debug) console.log('RequestFormFilesUpload | f', file.name);
      newFiles.push(file);
    });
    setFiles(newFiles);
  };

  return (
    <Container>
      {!files.length ? (
        <>
          {requestForms !== null && requestForms.length ? (
            <Message>
              <WarningAmber sx={{ color: 'yellow' }} />
              Currently uploaded files ({requestForms.length}) will be deleted
              and replaced.
            </Message>
          ) : null}
          <FileUpload handleUpload={handleOnDrop} />
        </>
      ) : (
        <UploadRequestFormFiles
          request={request}
          formType={formType}
          formName={formName}
          requestForms={requestForms}
          setUpdated={setUpdated}
          handleClose={handleClose}
          files={files}
        />
      )}
    </Container>
  );
};
export default RequestFormFilesUpload;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  font-weight: bold;
  font-style: italic;
  color: ${(p) => p.theme.palette.accentPrimary};
`;
