import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { DateTime } from 'luxon';
import useRepos, { RepositoryDetailsT } from '../useRepos';
import { UserContext, UserT } from '@stores/UserContext';
import { Row, HorizontalLine } from '../../shared/flexStyles';
import TextButton from '@as_core/controls/buttons/TextButton';
import { ExitToApp } from '@mui/icons-material';
import RepoInvites from './RepoInvites';
import RepositoryUsers from './RepoUsers';
import RepoDelete from './RepoDelete';
import { FlexSpacer } from '@as_core/elements/flexStyles';
import LabeledContainer from '@as_core/elements/LabeledContainer';
import DataFolderIcon from '@as_core/icons/dataFolder.icon';
import DataFolderSharedIcon from '@as_core/icons/dataFolderShared.icon';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import RepoNameEdit from '@subApps/account/repositoryManagement/components/RepoNameEdit';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import { getRepositoryAccessLabel } from '../useRepos';
import { getObjectValueByKey } from '@utils/misc';

type PropsT = {
  repo: RepositoryDetailsT;
  setOrgs?: (id: string) => void;
  pushRefresh?: (repoId: string, changeType: string) => void;
};

const debug = false;
const RepoDetail = (props: PropsT) => {
  const { repo, pushRefresh } = props;
  const { user } = useContext(UserContext);
  const isOwner = user?.authId === repo?.ownerAuthId;
  const { deleteRepositoryUser } = useRepos();
  if (debug) console.log('RepoDetail | repoId', repo?.uuid, ' repo', repo);

  const handleLeaveOrganization = (user: UserT, repoId: string) => {
    if (debug)
      console.log('handleLeaveOrganization', user.authId, 'from', repoId);
    deleteRepositoryUser(repoId, user.authId).then(() => {
      pushRefresh(repoId, 'userLeave');
    });
  };

  return (
    <LabeledContainer
      label={
        isOwner ? (
          <>
            <DataFolderIcon />
            <FlexSpacer width={5} />
            Data Repository Details
          </>
        ) : (
          <>
            <DataFolderSharedIcon />
            <FlexSpacer width={5} />
            Shared Data Repository Details
          </>
        )
      }
    >
      {repo === undefined ? (
        <Container>
          <ErrorMessages
            messages={[
              <MessageItem key='undefined-detail'>
                Issue with passing undefined repo details -- please submit a bug
                report
              </MessageItem>,
            ]}
          />
        </Container>
      ) : (
        <Container>
          <RepoNameEdit
            repo={repo}
            allowEdit={isOwner}
            pushRefresh={pushRefresh}
          />
          {!isOwner ? (
            <>
              <Row>
                <PropName>Repository Owner:</PropName>
                <PropValue>
                  {repo?.ownerAuthEmail
                    ? repo.ownerAuthEmail
                    : repo.ownerAuthId}{' '}
                </PropValue>
              </Row>
              <Row>
                <PropName>Access Type:</PropName>
                <PropValue>{getRepositoryAccessLabel(user, repo)}</PropValue>
              </Row>
            </>
          ) : null}
          <Row>
            <PropName>Repo Created On:</PropName>
            <PropValue>
              {DateTime.fromISO(getObjectValueByKey(repo, 'createdOn', ''), {
                zone: 'utc',
              }).toLocaleString(DateTime.DATETIME_SHORT)}
            </PropValue>
          </Row>
          {isOwner ? (
            <>
              <RepoInvites repo={repo} />
              <RepositoryUsers repo={repo} usersUpdated={pushRefresh} />
              {repo.uuid !== user.appInfo.repositories.current.uuid ? (
                <RepoDelete repoId={repo.uuid} onDelete={pushRefresh} />
              ) : (
                <HoverInfoText
                  text={
                    'Cannot Delete Active Repository. If you want to remove this repository, set a different one active.'
                  }
                />
              )}
            </>
          ) : (
            <>
              <HorizontalLine />
              <Row>
                <TextButton
                  label={'Leave Repository'}
                  isMuted={true}
                  onClick={() => handleLeaveOrganization(user, repo.uuid)}
                  icon={<ExitToApp />}
                  width={200}
                  height={30}
                />
              </Row>
            </>
          )}
        </Container>
      )}
    </LabeledContainer>
  );
};

export default RepoDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PropValue = styled.div`
  display: flex;
  width: 330px;
  color: ${(p) => p.theme.palette.textPrimary};
  padding-left: ${(p) => p.theme.sizes.medium};
`;

const PropName = styled.div`
  display: flex;
  width: 170px;
  justify-content: end;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const Chip = styled.div<{ fill?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    p.fill ? p.theme.palette.accentPrimary : p.theme.palette.backgroundPrimary};
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
  width: 80px;
  height: 28px;
  border-radius: 10px;
  text-align: center;
  margin-left: 10px;
`;
Chip.defaultProps = {
  fill: false,
};
