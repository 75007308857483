// Primary upload component to handle the various steps
import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import ImportDoseResponseReview from './ImportDoseResponseReview';
import UploadDataStepper from '../shared/UploadDataStepper';
import ComputeCurveFit from './ComputeCurveFit';
import UploadDoseResponse from './UploadDoseResponse';
import useFileImport from '../useFileImport';
import AssignColumnsTableRequired from '../shared/assignColumns/AssignColumnsTableRequired';
import {matchColumnT, DataSetT} from "@components/dataSets/dataSet.types";
import GetStepDetails from '../shared/GetToastMessage';
import Toast from '../shared/Toast';
function getSteps() {
  return ['Upload Spreadsheet', 'Assign Columns', 'Review Data', 'Compute Curve Fit', 'Save'];
}
let columnIdentities:Array<matchColumnT> = [
  { value: 'compound', label: 'Compound Name/ID', required: true, matched_column: '' },
  { value: 'sample', label: 'Sample or Lot ID', required: true, matched_column: '' },
  { value: 'dose', label: 'Dose or Concentration', required: true, matched_column: '' },
  { value: 'dose_units', label: 'Dose or Conc. Units', required: true, matched_column: '' },
  { value: 'response_%', label: 'Response (%)', required: true, matched_column: '' }
]
interface ColumnField {
  label: string;
  required: boolean;
  matched_column: string | string[];
  unMatched?: boolean;
  multiple?: boolean;
  value: string;
}
const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
type HandleResetT = () => void;
const ImportDoseResponse = ({ handleReset }: { handleReset: HandleResetT }) => {
  const [dataSet, setDataSet] = useState<DataSetT>(undefined);
  const [nextAvailable, setNextAvailable] = useState<boolean>(false);
  const [columnIDs, setColumnIDs] = useState<ColumnField[]>(columnIdentities);
  const [toast, setToast] = useState<{ message: string; duration: number } | null>(null);
  const { context } = useFileImport();
  if (debug) console.log('ImportDoseResponse | step:', context.activeStep);
  // ###########################################################################
  // STEPPER
  // ###########################################################################
  const steps = getSteps();
  useEffect(() => {
    if (context.activeStep === 1) {
      const resetColumnIDs = columnIDs.map((c) => {
        c.matched_column = '';
        return c;
      });
      setColumnIDs(resetColumnIDs);
    }
  }, []);
  if (context.activeStep < 1) {  // handling reset issue
    context.setActiveStep(1);
  }
  function setMatchedColumn(name, value) {
    if (debug) console.log('ImportDoseResponse | setMatchedColumn:', name, value);
    const updatedColumnIDs = columnIDs;
    const index = columnIdentities.findIndex((c) => c.value === name);
    if (index !== -1) updatedColumnIDs[index].matched_column = value;
    setColumnIDs(updatedColumnIDs);
  }
  const handleNext = () => {
    if (debug) console.log('ImportDoseResponse | handleNext');
    context.setActiveStep((currStep) => currStep + 1);
  };
  const handleBack = () => {
    if (debug) console.log('ImportDoseResponse | handleBack');
    context.setActiveStep((currStep) => {
      const nextStep = currStep - 1;
      if (nextStep === 1) {
        // placeholder
      }
      if (nextStep < 1) {
        let resetColumnIDs = columnIDs.map((c) => {
          c.matched_column = '';
          return c;
        });
        setColumnIDs(resetColumnIDs)
        setDataSet(undefined);
        handleReset();
      }
      return nextStep;
    });
  };
  useEffect(() => {
    const { message, duration } = GetStepDetails(context.activeStep, 'ImportDoseResponse');
    if (message) {
      setToast({ message, duration });
    } else {
      setToast(null);
    }
  }, [context.activeStep]);
  if (debug) console.log('ImportDoseResponse {activeStep}', context.activeStep);
  if (debug) console.log('ImportDoseResponse {columnIdentities}', columnIDs);
  return (
    <Container>
      <UploadDataStepper
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        nextAvailable={nextAvailable}
        uploadStep={3}
        steps={steps}
      />
      <ToastContainer>
        {toast && <Toast message={toast.message} duration={toast.duration} onClose={() => setToast(null)}/>}
      </ToastContainer>
      <StepContainer>
        {(() => {
          switch (context.activeStep) {
            case 1: // assign the column from file to required fields
              return (
                <AssignColumnsTableRequired
                  columnFields={columnIDs}
                  setMatchedColumn={setMatchedColumn}
                  nextAvailable={nextAvailable}
                  setNextAvailable={setNextAvailable}
                />
              );
            case 2: // review the data
              return <ImportDoseResponseReview
                  columnFields={columnIDs}
                  dataSet={dataSet}
                  setDataSet={setDataSet}/>;
            case 3: //
              return <ComputeCurveFit dataSet={dataSet} setDataSet={setDataSet}/>;
            case 4: //
              return <UploadDoseResponse dataSet={dataSet} handleNext={handleNext}/>;
            case 5: //
              return <UploadDoseResponse dataSet={dataSet} handleNext={handleNext}/>;
          }
        })()}
      </StepContainer>
    </Container>
  );
};
export default ImportDoseResponse;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;
const StepContainer = styled.div`
  display: flex;
  border: ${(p) => p.theme.borders.thin};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: ${(p) => p.theme.sizes.small};
  width: 80%;
  align-self: center;
`;
const ToastContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
