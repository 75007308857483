import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}


const DataSaveIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = (style.name === "dark") ? "black" : "white";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M17.2,21.5H7c-2.6,0-4.6-2.1-4.6-4.6v-2h1.5v2C3.9,18.6,5.3,20,7,20h10.2c1.7,0,3.1-1.4,3.1-3.1v-2h1.5v2
	C21.9,19.4,19.8,21.5,17.2,21.5z"/>
        <path fill={colorHighlight} d="M10.8,2.1h3.1C15.1,2.1,16,3,16,4.2v3.6h1.2c0.7,0,1.4,0.4,1.7,1.1c0.3,0.7,0.3,1.4-0.2,2l-5.2,6.9c-0.8,0.9-2.3,0.9-3.1,0
	L5.3,11c-0.5-0.6-0.5-1.4-0.2-2.1c0.3-0.6,1-1,1.7-1h1.6V4.1c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.5,1.5-0.5
	C10.6,2.1,10.7,2.1,10.8,2.1z M12.4,17l5.2-6.9c0.1-0.2,0.1-0.4,0-0.5c-0.1-0.1-0.2-0.2-0.3-0.2h-2.8V4.2c0-0.4-0.3-0.7-0.7-0.7
	h-3.1l-0.1,0c-0.2,0-0.4,0-0.6,0.2C10,3.8,9.9,3.9,9.9,4.1v5.2H6.8c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.3,0.1,0.5l5.2,6.9
	c0.1,0.2,0.3,0.2,0.4,0.2C12.1,17.1,12.3,17.1,12.4,17z"/>
      </svg>
    </>
  )
}

export default DataSaveIcon;