import React, { useContext, useState, useEffect } from 'react';
import { APP_CONFIG } from '@app_config/app';
import styled from 'styled-components/macro';
import { getValueByPath } from '@utils/misc';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@stores/UserContext';
import OrdersCart from './components/OrdersCart';
import TextButton from '@components/controls/TextButton/TextButton';
import { FlexColumn } from '@as_core/elements/flexStyles';
import MenuButton from '@components/elements/MenuButton';
import BackArrowIcon from '@components/icons/backArrow.icon';
import useOrders from '@business/orders/useOrders';
import useShoppingCart, {
  CartItemT,
  CartObjectType,
  UseCartObjectReturnType,
} from '@as_core/hooks/useShoppingCart';
import { BasicTextInput } from '@as_core/account/forms/FormStyles';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import { TextSmall } from '@as_core/elements/textStyles';
import Alert from '@components/elements/Alert';
import { getCartPrice } from '@business/orders/components/utils';
import useStripeProducts from '@business/products/useStripeProducts';
import { useQuery } from '@tanstack/react-query';
import DataLoading from '@as_core/elements/DataLoading';

type reductionFieldT = {
  value: string;
  new_value: string;
};

const productReductionFields: reductionFieldT[] = [
  { value: 'id', new_value: 'stripe_product_id' },
  { value: 'price_id', new_value: 'stripe_price_id' },
  { value: 'name', new_value: 'productName' },
  { value: 'price', new_value: 'productPrice' },
  { value: 'metadata', new_value: 'metadata' },
];

function reduceProductFields(item: CartItemT) {
  const newProduct = {};
  productReductionFields.forEach((field) => {
    if (field.value === 'price_id') {
      const price = getCartPrice(item);
      newProduct[field.new_value] = price['id'];
    } else if (field.value === 'price') {
      const price = getCartPrice(item);
      newProduct[field.new_value] = price.unit_amount;
    } else {
      newProduct[field.new_value] = getValueByPath(
        item.product,
        field.value,
        ''
      );
    }
    // console.log(field.value, field.new_value, newProduct[field.new_value]);
  });
  return newProduct;
}

function streamlineOrder(order: CartObjectType) {
  const newOrder = {};
  Object.keys(order).forEach((key) => {
    newOrder[key] = {
      product: reduceProductFields(order[key]),
      priceType: order[key].priceType,
      quantity: order[key].quantity,
    };
  });
  // console.log('streamLinedOrder', newOrder);
  return newOrder;
}

interface PropsI {
  ordersCart: UseCartObjectReturnType;
}

const debug = false;
const OrderConfirm = (props: PropsI) => {
  const { ordersCart } = props;
  const { getAllProducts } = useStripeProducts();
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [complementaryHandled, setComplementaryHandled] =
    useState<boolean>(false);
  const ordersCartLimited = useShoppingCart({});
  const ordersCartAvailable = useShoppingCart({});
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { createOrderSession } = useOrders();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [orderName, setOrderName] = useState<string>('');
  const orgType = user?.regInfo?.organization?.type
    ? user.regInfo.organization.type
    : 'commercial';

  // load the available products
  let {
    isPending: isProductsPending,
    isError: isProductsError,
    error: productsError,
    data: products,
  } = useQuery({
    queryKey: ['products', 'all'],
    queryFn: () => getAllProducts(user.userRegion),
  });
  if (isProductsError)
    console.error('error loading products:', productsError.message);

  // handle limited availability of Cell Health Data
  useEffect(() => {
    ordersCartLimited.reset();
    ordersCartAvailable.reset();
    // split carts based on availability
    Object.keys(ordersCart.data).forEach((item) => {
      if (['AS-SYS-ACS12'].includes(item)) {
        // limited availability
        ordersCartLimited.add(
          ordersCart.data[item].product,
          ordersCart.data[item].quantity,
          ordersCart.data[item].priceType
        );
      } else {
        ordersCartAvailable.add(
          ordersCart.data[item].product,
          ordersCart.data[item].quantity,
          ordersCart.data[item].priceType
        );
      }
    });
    setComplementaryHandled(false);
  }, []);

  // handle complementary 3RnD access
  useEffect(() => {
    if (ordersCartAvailable.length() && !complementaryHandled) {
      if (ordersCartAvailable.hasType('screen')) {
        const product = products.find(
          (p) => p.metadata.catalogNumber === 'AS-SCREEN-3RND'
        );
        if (debug) console.log(product);
        if (product) ordersCartAvailable.add(product, 1, 'complementary');
      }
      setComplementaryHandled(true);
    }
  }, [ordersCartAvailable]);

  if (debug)
    console.log(
      'OrderConfirm | user:',
      user,
      'ordersCart:',
      ordersCart,
      'ordersLimited:',
      ordersCartLimited,
      'ordersAvailable:',
      ordersCartAvailable
    );

  const handleSubmit = async (
    e: { preventDefault: () => void },
    orderType: string
  ) => {
    setInProcess(true);
    e.preventDefault();
    if (orderType === 'PO') {
      setAlertOpen(true);
      // console.log('handleSubmit | PO');
    } else {
      console.log(
        'OrderConfirm.handleSubmit | ordersCart.data',
        ordersCartAvailable.data
      );
      const params = {
        system: APP_CONFIG.system,
        name: orderName,
        region: user.userRegion,
        type: 'screen',
        payment_type: orderType,
        order: streamlineOrder(ordersCartAvailable.data),
      };
      try {
        const resp = await createOrderSession(params);
        console.log('createOrderSession | response:', resp);
        if (resp.status === 'OK') {
          window.open(resp.url, '_self');
        } else if (resp.status === 'Error') {
          console.error('Error Create Order Session');
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <OrdersConfirmContainer>
      {isProductsPending ? (
        <DataLoading />
      ) : (
        <>
          <FlexColumn>
            <MenuButton
              text={'Go Back to Refine Order'}
              onClick={() => navigate('/orders/create')}
              icon={<BackArrowIcon size={32} />}
              width={220}
            />
            <FlexColumn h_centered>
              {ordersCartAvailable.length() ? (
                <>
                  <OrdersName>
                    <TextSmall>Order Name:</TextSmall>
                    <HoverInfoText
                      text={
                        'Order name that will be shown in your orders list and used in fulfillment requests'
                      }
                    />
                    <StyledTextInput
                      id='order_name'
                      type='text'
                      name='Order Name'
                      value={orderName}
                      placeholder={'Enter name for your order'}
                      onChange={(e) => setOrderName(e.target.value)}
                    />
                  </OrdersName>
                  <OrdersCart
                    cart={ordersCartAvailable}
                    noQuantityChanges={true}
                  />
                  <ProductSettings>
                    <Label>Customer Type:</Label>
                    <Value>{orgType}</Value>
                    <Label>Customer Region:</Label>
                    <Value>{user.userRegion}</Value>
                  </ProductSettings>
                  <OrdersButton>
                    <TextButton
                      width={200}
                      label={'Purchase via PO'}
                      isDisabled={orderName === ''}
                      onClick={(e) => handleSubmit(e, 'PO')}
                    />
                    <TextButton
                      width={200}
                      label={'Purchase via Credit Card'}
                      inProcess={inProcess}
                      isDisabled={orderName === ''}
                      onClick={(e) => handleSubmit(e, 'Stripe')}
                    />
                  </OrdersButton>
                </>
              ) : null}
              {ordersCartLimited.length() ? (
                <>
                  <Header>
                    Limited Availability Assays (Click on button below to email
                    us)
                  </Header>
                  <OrdersCart
                    cart={ordersCartLimited}
                    noQuantityChanges={true}
                  />
                  <ProductSettings>
                    <Label>Customer Type:</Label>
                    <Value>{orgType}</Value>
                    <Label>Customer Region:</Label>
                    <Value>{user.userRegion}</Value>
                  </ProductSettings>
                  <OrdersButton>
                    <TextButton
                      width={200}
                      label={'Contact Us For Availability'}
                      onClick={() =>
                        (window.location.href =
                          'mailto:info@asedasciences.com?subject=Interested in screening in SYSTEMETRIC Cell Health Screen')
                      }
                    />
                  </OrdersButton>
                </>
              ) : null}
            </FlexColumn>
          </FlexColumn>
          <Alert
            type={'general'}
            title={'Purchase Order'}
            message={'Check-out via PO is currently in development.'}
            alertOpen={alertOpen}
            closeAlert={() => setAlertOpen(false)}
          />
        </>
      )}
    </OrdersConfirmContainer>
  );
};

export default OrderConfirm;

const OrdersConfirmContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const OrdersName = styled.div`
  display: flex;
  width: max-content;
  flex-direction: row;
  align-items: center;
`;

const OrdersButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
`;

const StyledTextInput = styled(BasicTextInput)`
  display: flex;
  width: 200px;
`;

const Header = styled.div`
  display: flex;
  font-size: 16px;
  font-style: italic;
  margin-top: 30px;
  width: 100%;
`;

const ProductSettings = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Label = styled.div`
  font-size: ${(p) => p.theme.sizes.xxsmall};
  font-style: italic;
  padding-left: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled(Label)``;
