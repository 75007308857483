import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import StationaryModal from '@components/elements/StationaryModal';
import ColumnFieldPicker from './required/ColumnFieldPicker';
import RowBuilderRequired from './required/RowBuilderRequired';
import { Header, Row } from '../RowElements';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import {
  TextSmallItalics,
  TextSmallBold,
} from '@components/elements/TextStyles';
import useFileImport from '../../useFileImport';
import TextButton from '@components/controls/TextButton';
import ForwardArrowIcon from '@components/icons/forwardArrow.icon';
const debug: boolean = process.env.REACT_APP_HOST_TYPE === 'dev';
const debugComponentName = 'AssignColumnsTableRequired';
interface ColumnField {
  label: string;
  required: boolean;
  matched_column: string | string[];
  unMatched?: boolean;
  multiple?: boolean;
  value: string;
}
interface AssignColumnsTableRequiredProps {
  columnFields: ColumnField[];
  setMatchedColumn: (field: string, columnName: string | string[]) => void;
  nextAvailable: boolean;
  setNextAvailable: (available: boolean) => void;
}
const AssignColumnsTableRequired = (props: AssignColumnsTableRequiredProps) => {
  const { columnFields, setMatchedColumn, nextAvailable, setNextAvailable } =
    props;
  const { height, width } = useWindowDimensions();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [activeColumn, setActiveColumn] = useState<number>(-1);
  const modalHeight = Math.min(height - 100, 400);
  const modalWidth = Math.min(width - 100, 800);
  const fieldPickerHeight = modalHeight - 150;
  const countRequired = columnFields.filter((c) => c.required).length;
  const countSet = columnFields.filter(
    (c) => c.required && c.matched_column !== ''
  ).length;
  const { context } = useFileImport();
  if (debug) console.log(debugComponentName + ' | columnFields ', columnFields);
  // ###########################################################################
  // Already Mapped Columns
  // ###########################################################################
  const [alreadyMappedColumns, setAlreadyMappedColumns] = useState<
    Array<string> | string
  >([]);
  const addAlreadyMappedColumn = (colName: string) => {
    setAlreadyMappedColumns((prev) => [...prev, colName]);
  };
  const removeAlreadyMappedColumn = (colName: string) => {
    setAlreadyMappedColumns((prev) => {
      if (Array.isArray(prev)) {
        // If `prev` is an array, use `filter` to remove the column
        return prev.filter((f) => f !== colName);
      } else if (typeof prev === 'string') {
        // If `prev` is a string and matches `colName`, return null or an empty string/array
        return prev === colName ? null : prev;
      }
      return prev; // In case it's null or something unexpected
    });
  };
  // confirm that everything is set okay in case columnFields already has prior mapping
  useEffect(() => {
    if (alreadyMappedColumns.length === 0) {
      const newAlreadyMappedColumns = columnFields
        .map((c) => {
          if (!c?.multiple) {
            if (c?.matched_column !== '') return c.matched_column;
          } else {
            if (!c?.unMatched && c?.matched_column.length)
              return c.matched_column;
          }
        })
        .filter((c) => c !== undefined)
        .flat();
      if (debug)
        console.log(
          debugComponentName + ' | alreadyMappedColumns',
          newAlreadyMappedColumns
        );
      setAlreadyMappedColumns(newAlreadyMappedColumns as string[]); // cast to string[] to ensure correct typing
    }
  }, [columnFields, alreadyMappedColumns.length]);
  // ###########################################################################
  // DeSelection Actions
  // ###########################################################################
  const handleColumnDeselect = (columnIdx) => {
    const columnName = columnFields[columnIdx].value;
    const matchedColumn = columnFields[columnIdx].matched_column;
    if (typeof matchedColumn === 'string') {
      removeAlreadyMappedColumn(matchedColumn);
    } else if (Array.isArray(matchedColumn)) {
      matchedColumn.forEach((col) => removeAlreadyMappedColumn(col));
    }
    if (debug)
      console.log(
        'FieldImportTable handleColumnDeselect',
        columnIdx,
        columnName
      );
    setMatchedColumn(columnName, '');
  };
  // ###########################################################################
  // Selection Actions
  // ###########################################################################
  const handleColumnSelected = (columnIdx) => {
    if (debug) console.log('handleColumnSelected');
    setActiveColumn(columnIdx);
    setModalOpen(true); // Opens ColumnFieldPicker
  };
  const handleColumnMapped = (colName) => {
    if (debug)
      console.log(
        'handleColumnMapped {activeColumn, colName, alreadyMappedColumns}',
        activeColumn,
        colName,
        alreadyMappedColumns
      );
    if (!alreadyMappedColumns.includes(colName))
      addAlreadyMappedColumn(colName);
    setMatchedColumn(columnFields[activeColumn].value, colName);
    setUnmapped(colName);
    setActiveColumn(-1);
    setModalOpen(false);
  };
  // handle multiple_columns && unMatched
  function setUnmapped(colName: string) {
    const cols = columnFields.filter((c) => c?.unMatched && c?.multiple);
    if (debug) console.log('unMatched ', cols);
    if (cols.length) {
      let unMappedColumns = context.columns
        .map((c) => c?.colName)
        .filter((v) => !alreadyMappedColumns.includes(v) && v !== colName);
      if (debug) console.log('unMappedColumns', unMappedColumns);
      setMatchedColumn(cols[0].value, unMappedColumns);
    }
  }
  useEffect(() => {
    setUnmapped('');
  }, [columnFields, alreadyMappedColumns]);
  // turn off the next button until all required fields are mapped
  useEffect(() => {
    if (nextAvailable && countSet !== countRequired) {
      setNextAvailable(false);
    }
    if (!nextAvailable && countSet === countRequired) {
      setNextAvailable(true);
    }
  }, [nextAvailable, countRequired, countSet]);
  if (debug) console.log('alreadyMappedColumns:', alreadyMappedColumns);
  const updateMessage =
    countSet +
    ' of ' +
    countRequired +
    ' required columns assigned [All must be matched to continue]';
  return (
    <Container>
      <UpdateContainer>{updateMessage}</UpdateContainer>
      <ScrollContainer>
        <Table width={width}>
          <tbody>
            <Row>
              <Header>
                <TextSmallBold color={'primary'}>
                  Dose Response Columns
                </TextSmallBold>
                <TextSmallItalics color={'accentSecondary'}>
                  (*-required)
                </TextSmallItalics>
              </Header>
              <Header>
                <TextSmallBold color={'primary'}>File Columns</TextSmallBold>
              </Header>
            </Row>
            <RowBuilderRequired
              columnFields={columnFields}
              onSelect={handleColumnSelected}
              onDeselect={handleColumnDeselect}
            />
          </tbody>
        </Table>
      </ScrollContainer>
      <StationaryModal
        title='Column Settings'
        modalOpen={modalOpen}
        onCloseClick={() => setModalOpen(false)}
        opacity={1}
        marginRight={25}
        width={modalWidth}
        height={modalHeight}
      >
        <ColumnFieldPicker
          activeColumn={activeColumn}
          columnFields={columnFields}
          onColumnPicked={handleColumnMapped}
          height={fieldPickerHeight}
          width={modalWidth}
          selectedColumns={alreadyMappedColumns}
          onClose={() => setModalOpen(false)}
        />
      </StationaryModal>
      <ButtonContainer>
        <TextButton
          text={'Done with Matching'}
          height={30}
          width={200}
          onClick={() => {
            if (nextAvailable) {
              context.setActiveStep((currStep) => currStep + 1);
            }
          }}
          icon={<ForwardArrowIcon />}
        />
      </ButtonContainer>
    </Container>
  );
};
export default AssignColumnsTableRequired;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const UpdateContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  font-size: ${(p) => p.theme.sizes.xsmall};

  font-style: italic;
  color: ${(p) => p.theme.palette.accentSecondary};
`;
const ScrollContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 330px);
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;
const Table = styled.table`
  table-layout: auto;
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;
