import React from 'react';
import styled from 'styled-components/macro';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export function getSelectOptions(options, optionKey): SingleSelectOptionT[] {
  if (!options) return [];
  return options.map((o) => ({ value: o[optionKey], label: o.label }));
}

export interface SingleSelectOptionT {
  value: string;
  label: string;
}

export interface SingleSelectPropsT {
  id: string;
  label: string;
  value: string;
  options: SingleSelectOptionT[];
  onChange: (string) => void;
  onClick?: () => void;
  width?: number;
  keepOpen?: boolean;
}

const debug = false;
const SingleSelect = (props: SingleSelectPropsT) => {
  if (debug) console.log('SingleSelect | props', props);
  return (
    <StyledTextField
      id={props.id}
      select
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      onClick={props?.onClick ? props.onClick : () => {}}
      width={props?.width ? props.width : 100}
    >
      {props?.options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </StyledTextField>
  );
};

export default SingleSelect;

const StyledTextField = styled(TextField)<{ width: number }>`
  display: flex;
  width: ${(p) => p.width}px;

  & .MuiOutlinedInput-root {
    border-radius: 5px;
  }

  & .MuiSelect-select {
    padding: 2px 8px;
    font-size: ${(p) => p.theme.sizes.xsmall};

    border-radius: 5px;
  }
`;
