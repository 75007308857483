import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { CompoundFieldT, updateField } from '@stores/fields';
import Accordion from '@components/elements/Accordion';
import DisplayDataField from './DisplayDataField';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import { TextSmall } from '@components/elements/TextStyles';
import MenuButton from '@components/elements/MenuButton';
import TrashIcon from '@components/icons/trash.icon';
import SaveIcon from '@components/icons/upload.icon';
import EditIcon from '@components/icons/edit.icon';
import Alert from '@components/elements/Alert';
import useCognito from '@as_core/account/useCognito';

interface Props {
  dataFieldId: string;
  dataFieldInfo: CompoundFieldT;
  updateOnSave?: () => void; // close new dialog if necessary
  updateOnEdit?: (v: string) => void; // force the list refresh -- temporary fix
  handleDelete?: (v: string) => void;
}

interface ItemProps {
  name: string;
  propId: string;
  dataFieldId: string;
  dataFieldInfo: CompoundFieldT;
  displayType: string;
  width: string;
  // eslint-disable-next-line
  handleChange: (props: any) => void;
}

const debug = false;
const DataFieldEditor = (props: Props) => {
  const { dataFieldId, dataFieldInfo } = props;
  if (debug)
    console.log(
      'DataFieldEditor | dataFieldId:',
      dataFieldId,
      'dataFieldsInfo',
      dataFieldInfo
    );
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  // DHR: Is edit mode is set to true as the component needs to be updated so that can edit and then save.
  const [isEditMode] = useState(true);
  const dispatch = useDispatch();
  const { getToken } = useCognito();

  const Item = ({
    name,
    propId,
    dataFieldId,
    dataFieldInfo,
    displayType,
    width,
    handleChange,
  }: ItemProps) => (
    <DataField width={width}>
      <FieldName>{name}</FieldName>
      <StyledDisplayDataField
        key={propId}
        fieldId={dataFieldId}
        fieldInfo={dataFieldInfo}
        propId={propId}
        displayType={displayType}
        handleChange={handleChange}
      />
    </DataField>
  );

  useEffect(() => {
    // DHR setIsEditMode(false);
    setIsDeleteAlertOpen(false);
  }, [dataFieldId]);

  const handleDeleteClick = () => {
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (debug)
      console.log(
        'DataFieldEditor | handleDeleteConfirm | dataFieldId',
        dataFieldId
      );
    props?.handleDelete?.(dataFieldInfo?._id);
  };

  const handleEditClick = async () => {
    if (isEditMode) {
      props?.updateOnSave?.();
    }
  };

  const handleChange = (changeProps: {
    propId: string;
    newValue: string | number;
    updateOnEdit: (val: string) => void;
  }) => {
    const { propId, newValue } = changeProps;
    if (debug) console.log('CHANGE', props);
    const newField = dataFieldInfo;

    if (newValue === 'true' || newValue === 'false') {
      _.setWith(newField, propId, JSON.parse(newValue), Object);
    } else if (newValue === '' && propId.startsWith('compoundTable')) {
      _.setWith(newField, propId, undefined, Object);
    } else {
      _.setWith(newField, propId, newValue, Object);
    }
    if (debug) console.log('CHANGE FINISH', newField);
    const token = getToken();
    dispatch(updateField({ token, field: newField }));
    changeProps?.updateOnEdit(newField.field_id);
  };
  // if (debug) console.log("DataFieldEditor | dataFieldInfo:", dataFieldInfo);
  if (debug) console.log('DataFieldEditor | isEditMore:', isEditMode);
  const deleteText = dataFieldInfo?._source.includes('system')
    ? 'Delete Changes'
    : 'Delete Field';

  return (
    <DataFieldEditorContainer>
      <FlexRow h_centered height={'50px'}>
        <FlexItem>
          <TextSmall>Active Editing Enabled</TextSmall>
        </FlexItem>
        {dataFieldInfo?._source.includes('user') ? (
          <MenuButton
            onClick={handleDeleteClick}
            isActive={isDeleteAlertOpen}
            tooltipPlacement={'bottom'}
            icon={<TrashIcon />}
            text={deleteText}
          />
        ) : (
          <></>
        )}
        <MenuButton
          onClick={handleEditClick}
          isActive={isEditMode}
          tooltipPlacement={'bottom'}
          icon={isEditMode ? <SaveIcon /> : <EditIcon />}
          text={isEditMode ? 'Save Changes' : 'Edit Field'}
        />
        {/*
        {isEditMode && (
            <buttons
                onClick={handleCancelClick}
                isActive={isEditMode}
                tooltipPlacement={'bottom'}
                icon={<CancelIcon />}
                text={'Cancel Changes'}
            />
        )} */}
      </FlexRow>
      <Accordion title={'General Parameters'} defaultExpanded={true}>
        <Row>
          <Item
            name='Short Name'
            propId='short_name'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'50%'}
            handleChange={handleChange}
          />
          <Item
            name='Category'
            propId='category'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'25%'}
            handleChange={handleChange}
          />
          <Item
            name='Renderer'
            propId='value_renderer'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='singleSelect'
            width={'20%'}
            handleChange={handleChange}
          />
        </Row>
        <Row>
          <Item
            name={`Long Name`}
            propId='long_name'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'75%'}
            handleChange={handleChange}
          />
          <Item
            name='Symbol'
            propId='symbol'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'25%'}
            handleChange={handleChange}
          />
        </Row>
      </Accordion>
      <Accordion title={'Compound Table Properties'}>
        <Row>
          <Item
            name='Resizable'
            propId='table.resizable'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='boolean'
            width={'25%'}
            handleChange={handleChange}
          />
          <Item
            name='Sortable'
            propId='table.sortable'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='boolean'
            width={'25%'}
            handleChange={handleChange}
          />
          <Item
            name='cfields'
            propId='cfields'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='boolean'
            width={'25%'}
            handleChange={handleChange}
          />
        </Row>
        <Row>
          <Item
            name='Align'
            propId='table.align'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='singleSelect'
            width={'25%'}
            handleChange={handleChange}
          />
          <Item
            name='Table Width'
            propId='table.width'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'25%'}
            handleChange={handleChange}
          />
          <Item
            name='Table Min Width'
            propId='table.minWidth'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'25%'}
            handleChange={handleChange}
          />
        </Row>
      </Accordion>
      <Accordion title={'Field Descriptions'}>
        <Row>
          <Item
            name='Short Description'
            propId='short_description'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'100%'}
            handleChange={handleChange}
          />
        </Row>
        <Row>
          <Item
            name='Options'
            propId='field_options'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'100%'}
            handleChange={handleChange}
          />
        </Row>
        <Row>
          <Item
            name='Long Description'
            propId='long_description'
            dataFieldInfo={dataFieldInfo}
            dataFieldId={dataFieldId}
            displayType='string'
            width={'100%'}
            handleChange={handleChange}
          />
        </Row>
      </Accordion>
      <Alert
        type={'deleteConfirm'}
        title={'Confirm Delete/Reset Field'}
        message={'Type DELETE below to confirm delete field'}
        alertOpen={isDeleteAlertOpen}
        onConfirm={handleDeleteConfirm}
        closeAlert={() => setIsDeleteAlertOpen(false)}
      />
    </DataFieldEditorContainer>
  );
};

export default DataFieldEditor;

const DataFieldEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
`;

const DataField = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.sizes.xsmall};

  width: ${(p) => p.width};
`;

const StyledDisplayDataField = styled(DisplayDataField)`
  display: flex;
  flex-direction: column;
  border-radius: ${(p) => p.theme.borderRadius};
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  padding: 2px;
  margin: 0 2px;
`;

const FieldName = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.default};
  color: ${(p) => p.theme.palette.titleSecondary};
  margin: 0 2px;
`;
