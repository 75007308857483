import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import CompoundBanner from './banner/CompoundBanner';
import CompoundTable from './view/CompoundTable';
import CompoundCompare from './view/CompoundCompare';
import CompoundHeatMap from './view/CompoundHeatMap';
import CompoundTiles from './view/CompoundTiles';
import { viewConfig } from './view/views.config';
import { viewToColumns } from './view/views.service';
import { checkDuplicatedRows } from './compounds.utils';

import Modal from '@components/Modal';
import Favorites from '@components/compounds/favorites/Favorites';
import { useSelector, useDispatch } from 'react-redux';
import { ResponseT } from '@utils/api/base';

import { FilterModal } from './filter/filter.modal';
import {
  addCompoundView,
  CompoundViewTypeT,
  removeCompoundView,
  updateCompoundView,
} from '@stores/compoundViews';
import { RootState } from '@src/store';
import { FieldSliceT, getFields as getFields3 } from '@stores/fields';
import { getCompoundViews } from '@stores/compoundViews';
import useCompounds from './useCompounds';
import useCompoundSearch, {
  ICompoundSearchData,
} from '@utils/api/useCompoundSearch';

import StationaryModal from '@components/elements/StationaryModal';
import ConfigDND from '@components/compounds/configDND/ConfigDND';
import Alert from '@components/elements/Alert';
import DataLoading from '@as_core/elements/DataLoading';
import useCognito from '@as_core/account/useCognito';
import useCompoundsNew from '@utils/useCompoundsNew';
import UploadIcon from '@components/icons/upload.icon';
import { FlexSpacer } from '@as_core/elements/flexStyles';
import ErrorMessages from '@as_core/elements/UserMessages';
import { MessageItem } from '@as_core/elements/UserMessages';
import {
  FilterExistsT,
  FilterSelectT,
  FilterSliderT,
  FilterStringT,
} from '@stores/filters';
import { CompoundT } from '@src/type';

const debug: string = ''; // '' || sort || filters || views || search

interface ICompoundView {
  matchId: string;
  libraryId: string;
  view: string;
}

const CompoundView = ({ matchId, libraryId, view }: ICompoundView) => {
  const {
    convertBaseTablePropsToParams,
    getCompounds,
    deleteCompound,
    getCompoundsFinite,
    getCompoundsFiltered,
    compoundIdsFromCompoundViews,
  } = useCompounds();
  const { getCompound } = useCompoundsNew();
  const compoundViews = useSelector((state: RootState) => state.compoundViews);

  const { getToken } = useCognito();
  const { getAll } = useCompoundSearch();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState([]);
  const [allSearchCompounds, setAllSearchCompounds] = useState<
    ICompoundSearchData[]
  >([]);
  const [sortBy, setSortBy] = useState();
  const [settingsModal, setSettingsModal] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [rowFilterKey, setRowFilterKey] = useState<string>('');
  const [noLoadMore, setNoLoadMore] = useState<boolean>(false);

  // filter related states
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { filters } = useSelector((state: RootState) => state.filters);

  const abortControllerRef = useRef(new AbortController());

  const { fields: allFields, fieldIds } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );

  const handleSearch = (text: string) => {
    setSearchTerm(text);
    // TODO -- think about other fields to add to API and UI
    if (text.length > 2) {
      const localSearchCompounds = [...allSearchCompounds];

      const matches = localSearchCompounds.filter((s) =>
        s?.compoundName?.toLowerCase().includes(text?.toLowerCase())
      );
      const results = matches.map((s) => s.compoundName);

      if (['all', 'search'].includes(debug)) {
        console.log(
          'CompoundView | handleSearch | matches:',
          matches,
          'results:',
          results
        );
      }
      setSearchResults(results);
    }
  };

  const handleSearchSelect = (compoundName) => {
    setSearchTerm('');

    if (['all', 'search'].includes(debug)) {
      console.log(
        'CompoundView | handleSearchSelect | compoundName:',
        compoundName
      );
    }

    const selectedCompound = allSearchCompounds.find(
      (c) => c?.compoundName === compoundName
    );

    if (selectedCompound !== undefined) {
      if (['all', 'search'].includes(debug)) {
        console.log(
          'CompoundView | handleSearchSelect | selectedCompound',
          selectedCompound
        );
      }

      const matchedInData = data.find((c) => c.uuid === selectedCompound.uuid);
      if (matchedInData !== undefined) {
        if (['all', 'search'].includes(debug)) {
          console.log(
            'CompoundView | handleSearchSelect | matchedInData:',
            matchedInData
          );
        }
        setPinnedData((previous) => [matchedInData, ...previous]);
      } else {
        if (['all', 'search'].includes(debug)) {
          console.log(
            'CompoundView | handleSearchSelect | not in data -- need to load:',
            selectedCompound.uuid
          );
        }

        getCompound(getToken(), selectedCompound.uuid, libraryId).then(
          (res) => {
            if (res === null) {
              console.error(
                'CompoundView getCompound failed for uuid',
                selectedCompound.uuid
              );
            } else {
              if (res.errors.length) {
                console.error(
                  'CompoundView getCompound failed for uuid',
                  selectedCompound.uuid,
                  'error:',
                  res.errors
                );
              } else {
                const newCompound = res.data;

                if (['all', 'search'].includes(debug)) {
                  console.log(
                    'CompoundView | handleSearchSelect | newCompound:',
                    newCompound
                  );
                }
                newCompound.searchKey = libraryId + '_' + newCompound.uuid;
                console.log('newCompound:', newCompound);
                setData((previous) => [...previous, newCompound]);
                setPinnedData((previous) => [newCompound, ...previous]);
              }
            }
          }
        );
      }
    }
    setSearchResults([]);
  };

  // handle the filter indicator
  if (filters.length && !isFilterActive) {
    setIsFilterActive(true);
  }
  if (!filters.length && isFilterActive) {
    setIsFilterActive(false);
  }

  if (['all', 'filters'].includes(debug)) {
    console.log(
      'CompoundView | isFilterActive',
      isFilterActive,
      ' filters',
      filters
    );
  }

  // Fetch Data
  // dataFields
  useEffect(() => {
    const token = getToken();
    dispatch(getFields3({ token }));
    dispatch(getCompoundViews(token));
  }, [dispatch]);
  // preload search fields
  useEffect(() => {
    getAll(getToken(), libraryId).then((res) => {
      if (res === null) {
        console.error('Error retrieving the compoundNames to search');
      } else {
        if (res?.errors.length) {
          console.error('Issue with getAll', res.errors);
        }
        setAllSearchCompounds(res.data);
      }
    });
  }, [libraryId]);

  if (['all', 'views'].includes(debug)) {
    console.log('compoundViews {matchId, views}', matchId, compoundViews);
  }

  // ###########################################################################
  // SET VIEW COLUMNS BASED ON VIEW SETTING (render-able: cfields flag)
  // ###########################################################################
  useEffect(() => {
    if (view !== 'compare') {
      // skip for sub-views
      if (view === 'table' && Object.hasOwn(compoundViews?.views, matchId)) {
        const userFieldIds = compoundViews.views[matchId]?.fieldIds || [];
        const localColumns = viewToColumns(userFieldIds, allFields);
        setColumns(localColumns);
        setRowFilterKey('');
      } else {
        let viewIndex = viewConfig.findIndex((v) => v.path === view);
        if (viewIndex === -1) viewIndex = 0;
        const localColumns = viewToColumns(
          viewConfig[viewIndex].columns,
          allFields
        );
        setColumns(localColumns);
        if (viewConfig[viewIndex]?.existsField) {
          const existsFieldId = viewConfig[viewIndex]?.existsField;
          const existsField = allFields[existsFieldId]?.data_key;
          setRowFilterKey(existsField);
        } else {
          setRowFilterKey('');
        }
      }
    }
  }, [allFields, compoundViews, view]);
  if (['all', 'views'].includes(debug)) {
    console.log('CompoundView | view:', view, 'columns:', columns);
  }

  // ###########################################################################
  // INFINITE LOADING
  // ###########################################################################
  const [showRows, setShowRows] = useState([]);
  const [totalCompounds, setTotalCompounds] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [loadedAll, setLoadedAll] = useState<boolean>(false);
  const zf_totalCompounds = 788; // TODO - Need to make this a dynamic call like totalCompounds -- confirm API

  const loadCompoundData = useCallback(
    async (props: Record<string, unknown>) => {
      setLoading(true);

      const params: Record<
        string,
        | string
        | number
        | (FilterSliderT | FilterStringT | FilterSelectT | FilterExistsT)[]
      > = {
        library: libraryId,

        // the problem with big loading time is that it's coming a lot of data,
        // my suggestion is that at the first time to load maximum 25, and then load more with limit 100

        // DHR: If we continue to see this -- we may need to increase the time delay between (e.g. debounce)
        // May need this as 50 to start -- as 25 immediately triggers a load more

        // After several attempts of finding a better solution,
        // I don't see it as a problem if it's triggering the load more immediately, since there is some data to be shown.
        // The real problem is that it's coming a lot of data from the backend and this is the valid solution at this point.

        limit: view === 'heatmap' ? 125 : 75,
        ...convertBaseTablePropsToParams(props),
      };
      // response to favorites
      if (
        compoundViews?.views[matchId]?.viewType === CompoundViewTypeT.Finite
      ) {
        setIsFavorite(true);
        params['filters'] = filters; // Not sure that we need this now -- as this is favorites
        await getCompoundsFinite(
          {},
          compoundViews.views[matchId].compoundIds
        ).then((res) => {
          setData(res.data);
          setLoadedAll(!res.continues);
          setLoading(false);
          if (res?.total) setTotalCompounds(res.total);
        });
      } else {
        if (filters.length) {
          await getCompoundsFiltered(filters, params).then((res) => {
            setData(res.data);
            setLoadedAll(!res?.continues);
            setLoading(false);
            if (res?.total) setTotalCompounds(res.total);
          });
        } else {
          await getCompounds(params).then((res) => {
            if (res?.data) {
              setData(res.data || []);
            }
            setLoadedAll(!res?.continues);
            setLoading(false);
            if (res?.total) setTotalCompounds(res.total);
          });
        }
      }
    },
    [libraryId, filters, matchId, convertBaseTablePropsToParams]
  );

  // first load
  useEffect(() => {
    loadCompoundData(sortBy);
  }, []);
  const loadMore = async () => {
    if (!loadedAll && noLoadMore === false) {
      setLoadingMore(true);
      let res: ResponseT<CompoundT[]>;

      abortControllerRef.current = new AbortController();
      const controller = abortControllerRef.current;

      const params: Record<
        string,
        | string
        | number
        | (FilterSliderT | FilterStringT | FilterSelectT | FilterExistsT)[]
      > = {
        library: libraryId,
        start: data.length,
        limit: 100,
        ...convertBaseTablePropsToParams(sortBy),
      };

      // Check if finite or infinite viewType
      if (compoundViews.views[matchId].viewType === CompoundViewTypeT.Finite) {
        params['library'] = libraryId;
        res = await getCompoundsFinite(
          params,
          compoundIdsFromCompoundViews(compoundViews, matchId),
          { signal: controller.signal }
        );
      } else {
        // if (debug) console.log('loadMore: {filters}', filters);
        if (filters.length) {
          res = await getCompoundsFiltered(filters, params, {
            signal: controller.signal,
          });
        } else {
          res = await getCompounds(params, { signal: controller.signal });
        }
      }
      // make sure don't reload same data twice
      const retrievedData = res?.data;
      const allReadyLoaded = data.map((r) => r.searchKey);
      const newData = retrievedData?.filter(
        (r) => !allReadyLoaded.includes(r.searchKey)
      );
      if (newData) {
        setData((previous) => [...previous, ...newData]);
      }
      setLoading(false);
      setLoadingMore(false);
      setLoadedAll(!res?.continues);
    } else {
      console.log('loaded all');
    }
  };

  const handleEndReached = () => {
    if (!loading && !loadingMore && !loadedAll) {
      loadMore();
    }
  };

  // trigger reload when the libraryId (e.g. aseda or user) or matchId (view -- some are limited)
  useEffect(() => {
    if (compoundViews?.viewIds?.length > 0 && loading === false)
      loadCompoundData(sortBy);
  }, [libraryId, matchId, filters]);

  // for specific views set the rows to only those based on filterKey
  useEffect(() => {
    if (rowFilterKey !== '') {
      const filtered = data.filter((r) => _.has(r, rowFilterKey));
      if (!loadedAll && !loading && !loadingMore && filtered.length < 50) {
        loadMore().then();
      }
      setShowRows(filtered);
      checkDuplicatedRows(filtered);
      //console.log('CompoundView | rowFilterKey:', rowFilterKey, ' data.length', data.length,
      //    'filtered.length:', filtered.length);
    } else {
      setShowRows(data);
    }

    return () => {
      if (loadingMore) {
        // This is for aborting the API request when the page is changed while is loading data
        // This setup is made only for getCompoundsFiltered, getCompoundsFinite and getCompounds
        // If we want to add more aborted functionality, we should follow the example below and the example from useCompounds.tsx
        abortControllerRef?.current?.abort();
        setLoadingMore(false);
      }
    };
  }, [data, rowFilterKey, filters]);

  // ###########################################################################
  // COMPOUND PINNING
  // ###########################################################################
  const [selected, setSelected] = useState<Array<string>>([]);
  const [pinnedData, setPinnedData] = useState<Array<CompoundT>>([]);

  const addToPinnedList = (newPins, rowKey) => {
    const idx = data.findIndex((x) => x?.searchKey === rowKey);
    if (idx === -1) return;
    newPins.push(data[idx]);
  };

  const handlePinSelect = () => {
    const localPinned = [];
    selected.forEach((rowKey) => {
      addToPinnedList(localPinned, rowKey);
    });
    setPinnedData((previous) => [...previous, ...localPinned]);
    setSelected([]);
  };

  // ###########################################################################
  // Modal
  // ###########################################################################
  const modalRef = useRef<{
    openModal: () => void;
    closeModal: () => void;
  }>();

  const openModal = () => {
    modalRef.current.openModal();
  };
  const closeModal = () => {
    modalRef.current.closeModal();
  };

  // ###########################################################################
  // Filter Modal
  // ###########################################################################
  const handleFilterModal = () => {
    setFilterModalOpen((previous) => !previous);
  };

  // ###########################################################################
  // Favorites
  // ###########################################################################
  const handleAddFavorite = (favorite) => {
    dispatch(addCompoundView(getToken(), favorite));
  };
  const handleDeleteFavorite = (favId) => {
    dispatch(removeCompoundView(getToken(), favId));
  };
  const handleSettings = () => {
    setSettingsModal(!settingsModal);
  };

  const handleAddAndSelect = (favItem) => {
    // selected array is searchKey(s) => 'libraryId' _ 'compoundId'
    const newFavItem = { ...favItem };

    const remove = libraryId + '_';
    const selectedClean = selected.map((s) => {
      return s.replace(remove, '');
    });
    newFavItem.compoundIds = [
      { libraryId: libraryId, compoundIds: selectedClean },
    ];
    newFavItem.compoundCount = selected.length;
    newFavItem.fieldIds = compoundViews.views[matchId]?.fieldIds || [];
    dispatch(addCompoundView(getToken(), newFavItem));
    setSelected([]);
    modalRef.current.closeModal();
  };

  const appendFavorite = (favId: string) => {
    let newFavItem = { ...compoundViews.views[favId] };
    let favIdsDict = newFavItem.compoundIds;
    let update = false;
    const index = favIdsDict.findIndex((item) => item.libraryId === libraryId);
    const remove = libraryId + '_';
    const selectedClean = selected.map((s) => {
      return s.replace(remove, '');
    });
    if (index === -1) {
      // new list
      update = true;
      favIdsDict = [
        ...favIdsDict,
        { libraryId: libraryId, compoundIds: selectedClean },
      ];
    } else {
      const toUpdate = selectedClean.filter(
        (item) => !favIdsDict[index].compoundIds.includes(item)
      );
      if (toUpdate.length) {
        update = true;
        favIdsDict = [
          ...favIdsDict.slice(index + 1),
          {
            libraryId: libraryId,
            compoundIds: [...favIdsDict[index].compoundIds, ...toUpdate],
          },
        ];
      }
    }
    // only update if there is a change
    if (update) {
      newFavItem = {
        ...newFavItem,
        compoundCount: favIdsDict.reduce(
          (total, obj) => total + obj.compoundIds.length,
          0
        ),
        compoundIds: favIdsDict,
      };
      dispatch(updateCompoundView(getToken(), newFavItem));
    }
    setSelected([]);
    modalRef.current.closeModal();
  };

  // ###########################################################################
  // Delete Compounds
  // ###########################################################################

  const handleDeleteCompounds = async () => {
    try {
      const selectedClean = selected.map((s) => {
        const parts = s.split('_');
        return parts[1];
      });
      const requests = selectedClean.map(async (id) => {
        const resp = await deleteCompound(id);
        return resp.data;
      });

      await Promise.all(requests).then((responses) => {
        const errors = responses.filter(
          (resp) => resp?.data?.errors?.length > 0
        );

        if (errors.length < 1) {
          setData((previous) =>
            previous.filter(
              (cmp) =>
                !selected.some(
                  (selectedUuid) => cmp?.searchKey === selectedUuid
                )
            )
          );
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  // ###########################################################################
  // Interactions
  // ###########################################################################
  const handleDoubleClick = (rowKey: string) => {
    navigate(`/compounds/detail/${rowKey}`);
  };

  const handleSort = (props) => {
    if (['all', 'sort'].includes(debug)) {
      console.log('SORT', props);
    }
    setSortBy(props);
  };

  const renderOverlay = React.useCallback(() => {
    if (loadingMore)
      return (
        <LoadingMoreLayer>
          <LoadingMoreText>Loading More</LoadingMoreText>
          <Loader small />
        </LoadingMoreLayer>
      );
    return null;
  }, [loadingMore]);

  // handle data reload or in-memory sort from changing sort key
  useEffect(() => {
    if (loadedAll) {
      // TODO: handle sort / filter inline as all data is loaded
      console.log(
        'need to implement sort on data object as all data is loaded -- no need to call API'
      );
    } else if (sortBy) {
      setLoading(true);
      loadCompoundData(sortBy);
    }
  }, [sortBy]);

  // handle data reload by changing filters
  useEffect(() => {
    if (filters.length > 0) {
      setLoading(true);
      loadCompoundData(sortBy);
    }
  }, [filters]);

  if (debug === 'all') {
    console.log(
      'CompoundView | # Compounds:',
      data.length,
      '# Compounds (show):',
      showRows.length,
      'pinnedData',
      pinnedData
    );
  }

  let loadingText =
    data.length <= totalCompounds
      ? 'loaded ' + data.length + ' of ' + (totalCompounds || 0)
      : 'loaded ' + totalCompounds + ' of ' + (totalCompounds || 0);
  if (loadedAll) {
    loadingText = 'loaded all ' + data.length;
  }
  if (filters.length) loadingText = loadingText + ' (filtered)';
  if (data.length !== showRows.length) {
    if (['zebrafish', 'heatmap'].includes(view)) {
      loadingText =
        'loaded ' + showRows.length + ' of ' + (zf_totalCompounds || 0);
    } else {
      loadingText = loadingText + ' [filtered view -> ' + showRows.length + ']';
    }
  }

  return (
    <CompoundViewContainer key={'CompoundViewContainer'}>
      <CompoundBanner
        view={view}
        library={libraryId}
        configId={matchId}
        handleSearchChange={handleSearch}
        searchTerm={searchTerm}
        searchResults={searchResults}
        handleSearchSelect={handleSearchSelect}
        handlePinSelect={handlePinSelect}
        handleAddToList={openModal}
        handleFilterModal={handleFilterModal}
        handleSettings={handleSettings}
        handleDeleteCompounds={() => setIsDeleteAlertOpen(true)}
        isFavorite={isFavorite}
        favoriteName={_.get(compoundViews.views[matchId], 'title', '')}
        filterActive={isFilterActive}
        libraryId={libraryId}
        loadingText={loadingText}
        setData={setData}
        setTotalCompounds={setTotalCompounds}
        setLoadedAll={setLoadedAll}
        setLoading={setLoading}
        setNoLoadMore={setNoLoadMore}
      />
      {!loading && data.length > 0 ? (
        view === 'tile' ? (
          <CompoundTiles
            data={data}
            allFields={allFields}
            selected={selected}
            setSelected={setSelected}
            pinnedData={pinnedData}
            setPinnedData={setPinnedData}
            handleEndReached={handleEndReached}
            handleRedirect={handleDoubleClick}
          />
        ) : view === 'heatmap' ? (
          <CompoundHeatMap
            data={showRows}
            pinnedData={pinnedData}
            allFields={allFields}
            loadingMore={loadingMore}
            handleEndReached={handleEndReached}
          />
        ) : view === 'compare' ? (
          <CompoundCompare
            selectedCompoundIds={selected}
            allFields={allFields}
            fieldIds={fieldIds}
            data={data}
          />
        ) : (
          <CompoundTable
            columns={columns}
            data={showRows}
            fixed={true}
            handleDoubleClick={handleDoubleClick}
            setSelected={setSelected}
            pinnedData={pinnedData}
            setPinnedData={setPinnedData}
            selectedRowKeys={selected}
            loading={loading}
            loadingMore={loadingMore}
            onEndReachedThreshold={1000}
            onEndReached={handleEndReached}
            onColumnSort={handleSort}
            sortBy={sortBy}
            libraryId={libraryId}
          />
        )
      ) : !loading && libraryId === 'user' ? (
        <ErrorMessages
          messages={[
            <MessageItem key='message-item-1' color='accentSecondary'>
              No available user-uploaded compounds.
            </MessageItem>,
            <MessageItem key='message-item-2'>
              Please upload data using the side menu <FlexSpacer width={5} />
              <UploadIcon />
            </MessageItem>,
          ]}
        />
      ) : !loading ? (
        <ErrorMessages
          messages={[
            <MessageItem key='message-item-3' color='accentSecondary'>
              Filter settings did not return any compounds - try adjusting
            </MessageItem>,
          ]}
        />
      ) : (
        <DataLoading />
      )}
      <StationaryModal
        title='Column Settings'
        modalOpen={settingsModal}
        onCloseClick={() => setSettingsModal(false)}
        opacity={0.95}
        width={900}
        height={450}
      >
        <ConfigDND configId={matchId} width={900} height={450} />
      </StationaryModal>
      {loadingMore ? renderOverlay() : null}
      <FilterModal
        modalOpen={filterModalOpen}
        setModalOpen={setFilterModalOpen}
        onSave={() => setFilterModalOpen(false)}
        onClose={() => setFilterModalOpen(false)}
        isFilterActive={isFilterActive}
        setIsFilterActive={setIsFilterActive}
      />
      <Modal ref={modalRef} handleClickAway={closeModal}>
        <Favorites
          title='Add to List'
          compoundViews={compoundViews}
          appendFavorite={appendFavorite}
          handleAddFavorite={handleAddFavorite}
          handleDeleteFavorite={handleDeleteFavorite}
          handleAddAndSelect={handleAddAndSelect}
        />
      </Modal>
      <Alert
        type={'deleteConfirm'}
        title={'Delete Compounds'}
        message={'Confirm delete by typing DELETE below to Enable Delete'}
        alertOpen={isDeleteAlertOpen}
        onConfirm={handleDeleteCompounds}
        closeAlert={() => setIsDeleteAlertOpen(false)}
      />
    </CompoundViewContainer>
  );
};

export default CompoundView;

const CompoundViewContainer = styled.div`
  height: calc(100vh - 50px);
  width: calc(100vw - 50px);
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  overflow-y: hidden;
  overflow-x: hidden; // handle in sub-views
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface StyledExtraProps {
  small?: boolean;
}

const Loader = styled.div<StyledExtraProps>`
  display: inline-block;
  border-radius: 100%;
  margin: 2px;
  border: ${(p) => p.theme.borders.default};
  width: ${(props) => (props.small ? 12 : 22)}px;
  height: ${(props) => (props.small ? 12 : 22)}px;
  animation: ${rotate} 0.75s linear infinite;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const LoadingMoreLayer = styled.div`
  pointer-events: none;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 15px;
  border: 2px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const LoadingMoreText = styled.span`
  color: ${(p) => p.theme.palette.titlePrimary};
  margin-right: 5px;
`;
