import styled from 'styled-components/macro';
import { getObjectValueByKey } from '@utils/misc';
import EditIcon from '@components/icons/edit.icon';
import MenuButton from '@components/controls/MenuButton';
import CopyToClipboard from '@as_core/controls/outputs/CopyToClipboard';
import OpenEmail from '@as_core/controls/outputs/OpenEmail';
import ViewIcon from '@as_core/icons/view.icon';
import React from 'react';
import {
  AppInfoT,
  UserEventsItemT,
  UserListItemT,
} from '@utils/useUserAppInfo';
import { RepositoryDetailsT } from '@subApps/account/repositoryManagement/useRepos';
import { EventSummaryT } from '@subApps/admin/components/UserEventsList';

// use for both tables and object details
export type FieldsTypeT = {
  value: string;
  label: string;
  type?: string;
  id?: { key: string; label: string };
  width?: number;
  allowCopy?: boolean;
  editType?: string;
  editCallback?: (v: string) => void;
  onChange?: (v: string) => void;
};

export function getObjectValue(
  object:
    | UserListItemT
    | RepositoryDetailsT
    | UserEventsItemT
    | EventSummaryT
    | AppInfoT,
  field: FieldsTypeT,
  handleActionClick?: (action: string, id: string) => void
): string | number {
  let value = getObjectValueByKey(object, field.value);
  if (value === '' && field.type !== 'action') return value;
  if (field?.type) {
    if (field.type === 'date') {
      const date = new Date(value);
      return date.toLocaleDateString();
    } else if (field.type === 'datetime') {
      const date = new Date(value);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    } else if (field.type === 'arrayJoin') {
      value = Array.isArray(value) ? value.join(', ') : value;
    } else if (field.type === 'arrayCount') {
      value = Array.isArray(value) ? value.length : 0;
    } else if (field.type === 'invitedUsers') {
      const users = value;
      if (!Array.isArray(users) || users.length === 0) {
        value = 'None';
      } else {
        value = (
          <ValueColumn>
            {users.map((i, index) => (
              <Item key={`user_item_${index}`}>
                {getObjectValueByKey(i, 'authEmail') +
                  ' (' +
                  getObjectValueByKey(i, 'accessType') +
                  ')'}
              </Item>
            ))}
          </ValueColumn>
        );
      }
    } else if (field.type === 'action') {
      const id = getObjectValueByKey(object, field.id.key);
      if (field.value === 'view') {
        value = (
          <MenuButton
            onClick={() => handleActionClick(field.value, id)}
            icon={<ViewIcon />}
            text={`View ${field.id.label} Details`}
            tooltipPlacement={'left'}
          />
        );
      } else {
        value = (
          <MenuButton
            onClick={() => handleActionClick(field.value, id)}
            icon={<EditIcon />}
            text={`Select to Edit ${field.id.label} Details`}
            tooltipPlacement={'right'}
          />
        );
      }
      return value;
    }
  }
  if (field?.editType) {
    value = (
      <ValueRow>
        <Item>{value}</Item>
        <MenuButton
          icon={<EditIcon />}
          text='Edit'
          onClick={() => field.editCallback && field.editCallback(value)}
        />
      </ValueRow>
    );
  }
  if (field?.allowCopy) {
    value = (
      <ValueRow>
        <Item>{value}</Item>
        <CopyToClipboard label='Copy value to clipboard' text={value} />
      </ValueRow>
    );
  }
  if (field.value === 'authEmail') {
    value = (
      <ValueRow>
        <Item>{value}</Item>
        <OpenEmail email={value} />
      </ValueRow>
    );
  }
  return value;
}

interface ObjectDetailsI {
  fields: FieldsTypeT[];
  object: UserListItemT | RepositoryDetailsT | AppInfoT;
}

const debug = false;
const ObjectDetails = (props: ObjectDetailsI) => {
  return (
    <ObjectDetailsContainer>
      {props.fields.map((field, i) => {
        if (debug) console.log(field);
        const value = getObjectValue(props.object, field);
        return value === '' ? null : (
          <ObjectInformation key={i + '_' + field.value}>
            <Label>{field.label}:</Label>
            <Value>{value}</Value>
          </ObjectInformation>
        );
      })}
    </ObjectDetailsContainer>
  );
};
export default ObjectDetails;

const ObjectDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ObjectInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  min-height: 28px;
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  margin-right: 5px;
  justify-content: flex-end;
  align-items: start;
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.textSecondary};
  text-transform: capitalize;
`;

const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
`;

const Item = styled(Value)``;

const ValueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;
