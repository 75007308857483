import styled from 'styled-components/macro';

export const RequestFormsContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.width};
  background: ${(p) => p.theme.palette.backgroundPrimary};
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  margin: 0 20px;
`;
RequestFormsContainer.defaultProps = {
  width: 'max-content'
}

export const RequestFormsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: ${(p) => p.theme.sizes.small};
  font-style: italic;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: ${(p) => p.theme.palette.accentSecondary};
  color: #000129;
`;

export const Row = styled.div<{
  width?: string;
  height?: string;
  border?: string;
  h_align?: string;
  v_align?: string;
  padding?: string;
}>`
  display: flex;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  flex-direction: row;
  align-items: ${(p) => p.v_align};
  justify-content: ${(p) => p.h_align};
  padding: ${(p) => p.padding};
  ${(p) =>
    p.border === 'top' &&
    `border-top: 1px solid ${p.theme.palette.accentPrimary}`};
`;
Row.defaultProps = {
  width: '100%',
  height: 'max-content',
  border: '',
  h_align: 'space-around',
  v_align: 'flex-start',
  padding: `0`,
};

export const Column = styled.div<{
  border?: string;
  h_align?: string;
  v_align?: string;
  width?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.width};
  align-items: ${(p) => p.h_align};
  justify-content: ${(p) => p.v_align};
  padding: ${(p) => p.padding};
  ${(p) =>
    p.border === 'top' &&
    `border-top: 1px solid ${p.theme.palette.accentPrimary}`};
`;
Column.defaultProps = {
  border: '',
  h_align: 'space-around',
  v_align: 'flex-start',
  width: 'max-content',
  padding: `0`,
};

export const Item = styled.div<{
  width?: string;
  height?: string;
  h_align?: string;
}>`
  display: flex;
  font-size: ${(p) => p.theme.sizes.xsmall};

  width: ${(p) => p.width};
  height: ${(p) => p.height};
  justify-content: ${(p) => p.h_align};
  padding: 5px;
`;
Item.defaultProps = {
  height: 'auto',
  width: '100%',
  h_align: 'flex-start',
};

export const Label = styled.div<{ width?: string; h_align?: string }>`
  display: flex;
  width: ${(p) => p.width};
  margin-right: 5px;
  justify-content: ${(p) => p.h_align};
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.accentSecondary};
`;
Label.defaultProps = {
  width: '125px',
  h_align: 'flex-end',
};

export const Title = styled(Label)<{color?: string, size?: string}>`
  display: flex;
  width: max-content;
  font-size: ${(p) => 
          p.size === 'xlarge' ? p.theme.sizes.xlarge : p.theme.sizes.large};
  padding: 10px;
  color: ${(p) => p.color === 'primary' ? 
          p.theme.palette.textPrimary : p.theme.palette.accentPrimary};
`;
Title.defaultProps = {
  size: 'large',
  color: 'accent',
}

export const Value = styled(Label)`
  display: flex;
  margin-right: 0;
  color: ${(p) => p.theme.palette.textPrimary};
`;
Value.defaultProps = {
  width: '250px',
  h_align: 'flex-start',
};

export const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

export const Spacer = styled.div<{ width?: number }>`
  display: flex;
  width: ${(p) => p.width}px;
`;
Spacer.defaultProps = {
  width: 10,
};

export const RequestFormTitle = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.textPrimary};
`;

export const DisplayField = styled.div`
  display: flex;
  flex-direction: row;
`;
