import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { FilterGenericI } from '../filter.types';
import SelectField from '@as_core/components/select/SelectField';
import InputField from '@as_core/components/inputs/InputField';

const debug = false;
const FilterString = ({ filterName, filter, handleUpdate }: FilterGenericI) => {
  if (debug) console.log('FilterString ', filterName, filter);
  const [newValue, setNewValue] = useState(filter.value);
  const [newComparator, setNewComparator] = useState(filter.comparator);

  // just update locally while typing
  const handleLocalUpdateValue = (v: string) => {
    if (debug) console.log('handleLocalUpdateValue', v);
    setNewValue(v);
  };
  const handleLocalUpdateComparator = (v) => {
    if (debug) console.log('handleLocalUpdateComparator', v);
    setNewComparator(v);
  };

  // check to update when lose focus by mouse leave
  const checkToUpdate = () => {
    if (debug) console.log('check to update');
    if (newValue !== filter.value) {
      if (debug) console.log('  pushing new value');
      handleUpdate('string', { uuid: filter.uuid, value: newValue });
    }
    if (newComparator !== filter.comparator) {
      if (debug) console.log('  pushing new comparator');
      handleUpdate('string', { uuid: filter.uuid, comparator: newComparator });
    }
  };

  return (
    <FilterStringItem>
      <Row>
        <Item>{filterName}</Item>
        <Item>
          <SelectField
            id='comparator'
            value={newComparator}
            options={[
              { value: 'contains', label: 'contains' },
              { value: 'does not contain', label: 'does not contain' },
            ]}
            onMouseLeave={checkToUpdate}
            onChange={(e) => {
              handleLocalUpdateComparator(e.target.value);
            }}
            size='small'
          />
        </Item>
        <Item>
          <InputField
            size='small'
            name={filter.key}
            value={newValue}
            placeholder={'placeholder'}
            label='placeholder'
            onBlur={checkToUpdate}
            onChange={(e) => {
              handleLocalUpdateValue(e.target.value);
            }}
          />
        </Item>
      </Row>
    </FilterStringItem>
  );
};

export default FilterString;

const FilterStringItem = styled.label`
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Item = styled.div`
  padding: 3px;
  display: flex;
`;
