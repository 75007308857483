import React from "react";
import styled from 'styled-components/macro';
import {getValueByPath} from "@utils/misc";
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import {RequestFormT} from "../hooks/useRequestForms";
import { Row, Label, Value, RequestFormTitle } from "../common/flexStyles";
const updatedFields: BasicTableFieldsT[] = [
  { value: 'created_at', label: 'Created', type: 'datetime' },
];

const trackingFields: BasicTableFieldsT[] = [
  { value: 'shippingProvider',       label: 'Shipping Provider',  type: 'string' },
  { value: 'shippingTrackingNumber', label: 'Tracking Number',    type: 'string' }
];

function getFieldValue(form: RequestFormT | {[key: string]: string}, item: BasicTableFieldsT) {
  const value = getValueByPath(form, item.value, '');
  if (value === '') return value;
  if (item.type === 'datetime') {
    const date = new Date(value);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return value;
}

interface PropsI {
  viewMode: string;
  form: RequestFormT;
}

const debug = false;
const ShippingFormDetails = (props: PropsI) => {
  const {viewMode, form} = props;
  if (debug) console.log('ShippingFormDetails:', form);
  if (form === undefined) return <></>;

  const info = JSON.parse(form.value);
  if (debug) console.log('ShippingFormDetails | {form.id, value}:', form.id, info);

  const displayValue = (form: RequestFormT, item:BasicTableFieldsT, index: number) => {
    return (
      <Row key={index} h_align={'flex-start'}>
        <Label>{item.label}:</Label>
        <Value width={'150px'}>{getFieldValue(form, item)}</Value>
      </Row>
    );
  };

  return (
    <ViewContainer>
      { viewMode !== 'user' ? <RequestFormTitle>Shipping Information:</RequestFormTitle> : null }
      <ReportContainer>
        {Object.keys(info) ? (
          trackingFields.map((item, index) => displayValue(info, item, index))
        ) : (
          <></>
        )}
        {Object.keys(form) ? (
          updatedFields.map((item, index) => displayValue(form, item, index))
        ) : (
          <></>
        )}
      </ReportContainer>
    </ViewContainer>
  );
};

export default ShippingFormDetails;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
`;


const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: max-content;
`;


