import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import useRepos, {
  RepositoryDetailsT,
} from '@subApps/account/repositoryManagement/useRepos';
import RepoDetail from './components/RepoDetail';
import RepoList from './components/RepoList';
import UserMessages, { MessageItem } from '@as_core/elements/UserMessages';

const debug = false;

const AdminRepoManagement = () => {
  const { user } = useContext(UserContext);
  const { getAllRepositories, getRepository } = useRepos();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [orgs, setOrgs] = useState<RepositoryDetailsT[]>([]);
  const [selectedRepoId, setSelectedRepoId] = useState<string | null>(null);
  const [selectedRepo, setSelectedRepo] = useState<RepositoryDetailsT | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  if (debug) console.log('AdminRepoManagement | user:', user);
  if (debug) console.log('AdminRepoManagement | orgs:', orgs);

  // HANDLERS
  const onSelect = (repoId: string) => {
    if (debug) console.log('AdminRepoManagement | onSelect repoId', repoId);
    setSelectedRepoId(repoId);
  };

  // DATA LOADING
  useEffect(() => {
    if (!loaded) {
      getAllRepositories()
        .then((repos) => {
          setOrgs(repos);
          setError(null);
        })
        .catch((err) => {
          console.error('Failed to fetch repositories:', err);
          setError('Failed to load repositories. Please try again later.');
        });
      setLoaded(true);
    }
  }, [loaded, getAllRepositories]);

  useEffect(() => {
    if (selectedRepoId) {
      getRepository(selectedRepoId)
        .then((repoDetails) => {
          if (debug) console.log('RESPONSE repoDetails', repoDetails);
          setSelectedRepo(repoDetails);
          setError(null);
        })
        .catch((err) => {
          console.error('Failed to fetch repository details:', err);
          setError(
            'Failed to load repository details. Please try again later.'
          );
          setSelectedRepo(null);
        });
    }
  }, [selectedRepoId, getRepository]);

  if (debug) console.log('AdminRepoManagement | orgs', orgs);

  return (
    <Container>
      {/* Display error messages if any */}
      {error && (
        <UserMessages
          key='error-message'
          messages={[
            <MessageItem key='error-message-item'>{error}</MessageItem>,
          ]}
        />
      )}

      {selectedRepoId ? (
        selectedRepo ? (
          <RepoDetail
            key={selectedRepoId}
            repo={selectedRepo}
            backToList={() => setSelectedRepoId(null)}
          />
        ) : (
          <UserMessages
            key='repo-detail-error'
            messages={[
              <MessageItem key='selected-repo-empty'>
                Selected Repository is empty -- issue with API?
              </MessageItem>,
            ]}
          />
        )
      ) : (
        <RepoList repos={orgs} handleSelect={onSelect} />
      )}
    </Container>
  );
};

export default AdminRepoManagement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
