import {UserT} from "@stores/UserContext";
import {RequestT} from "./useRequests";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {authClient as auth3RnDClient} from "@utils/api/base";
import {ResponseT, processAxiosResponseElement} from "@utils/api/utils";
import useCognito from "@as_core/account/useCognito";

// the api settings
export const Partners = {
  all: (token: string) => auth3RnDClient(token).get('/partners'),
  get: (token: string, vendorCode: string) => auth3RnDClient(token).get(`/partners?code=${vendorCode}`),
  create: (token: string, config: AxiosRequestConfig) => auth3RnDClient(token).post('/partners', config),
  update: (token: string, config: AxiosRequestConfig) => auth3RnDClient(token).patch('/partners', config),
  delete: (token: string, partnerId: string,) => auth3RnDClient(token).delete(`/partners/${partnerId}`)
};
export const Authorizations = {
  all: (token: string) => auth3RnDClient(token).get('/partners/authorizations'),
  get: (token: string, email: string) => auth3RnDClient(token).get(`/partners/authorizations?user_email=${email}`),
  create: (token: string, config: AxiosRequestConfig) => auth3RnDClient(token).post('/partners/authorizations', config),
  update: (token: string, config: AxiosRequestConfig) => auth3RnDClient(token).patch(`/partners/authorizations`, config),
  delete: (token: string, authorizationId: string,) => auth3RnDClient(token).delete(`/partners/authorizations/${authorizationId}`)
};


export type PartnerT = {
  id: string;
  code: string;
  name: string;
  keyContact?: string;
  // eslint-disable-next-line
  address?: any;
  created: string;
  updated?: string;
}

export type PartnerAuthorizationT = {
  id: string;
  authEmail: string;
  authorizations: string[];
  created: string;
  updated?: string;
}

export const VENDOR_MASK:{[key: string]: string} = {
  'AS_V_0001': 'AsedaSciences',
  'AS_V_0002': 'Tanguay Laboratories',
  'AS_V_0003': 'Klara Valko',
  'AS_V_0004': 'IndigoBridge',
}

export type VENDOR = '' | 'AS_V_0001' | 'AS_V_0002' | 'AS_V_0003' | 'AS_V_0004';

const catalogToVendor: {[key: string]: VENDOR | VENDOR[]} = {
  'AS-SYS-ACS12' : 'AS_V_0001',
  'AS-SYS-ACS12-ACD' : 'AS_V_0001',
  'AS-SCREEN-3RND': 'AS_V_0001',
  'AS-ZBE-ACT236': 'AS_V_0002',
  'AS-ZBE-ACT236-ACD': 'AS_V_0002',
  'AS-ZBE-DTX16-LT' : 'AS_V_0002',
  'AS-ZBE-DTX16-LT-ACD' : 'AS_V_0002',
  'AS-ZBE-DTX16': 'AS_V_0002',
  'AS-ZBE-DTX16-ACD': 'AS_V_0002',
  'AS-ZBE-DTX16-CTG': 'AS_V_0002',
  'AS-CQC-SST': 'AS_V_0004',
  'AS-CQC-SST-ACD': 'AS_V_0004',
  'AS-BMVK-KVC': 'AS_V_0003',
  'AS-BMVK-BMCH': ['AS_V_0003', 'AS_V_0004'],
  'AS-BMVK-BMCH-ACD': ['AS_V_0003', 'AS_V_0004'],
  '': '',
}

const RequestTypes = { // default is 'assay'
  'consulting': ['AS-BMVK-KVC', 'AS-ZBE-DTX16-CTG'],
  'subscription': ['AS-SCREEN-3RND'],
}


const debug = false;
const usePartners= () => {
  const { getToken } = useCognito();

  // create new user authorizations -- used by admin/maintenance
  const createPartner = async (params: { [key: string]: string | number }): Promise<PartnerT> => {
    const config: AxiosRequestConfig = params;
    let resp: AxiosResponse<ResponseT<PartnerT>> = await Partners.create(getToken(), config);
    const partner = processAxiosResponseElement('createPartner', resp);
    if (debug) console.log('createPartner: ', partner);
    return partner;
  }

  // get all defined partners -- used by admin/maintenance
  const getAllPartners = async (): Promise<PartnerT[]> => {
    let resp: AxiosResponse<ResponseT<PartnerT[]>> = await Partners.all(getToken());
    const partners = processAxiosResponseElement('getAllPartners', resp);
    if (debug) console.log('getAllPartners: ', partners);
    return partners;
  }

  // update user authorizations -- used by admin/maintenance
  const updatePartner = async (
    partnerId: string,
    updates: { [key: string]: string | number }
  ): Promise<PartnerT> => {
    let params = updates;
    params['id'] = partnerId;
    const config: AxiosRequestConfig = params;
    let resp: AxiosResponse<ResponseT<PartnerT>> = await Partners.update(getToken(), config);
    const partner = processAxiosResponseElement('updatePartner', resp);
    if (debug) console.log('updatePartner: ', partner);
    return partner;
  }

  // delete partner record -- used by admin/maintenance
  const deletePartner = async (
    partnerId: string
  ): Promise<void> => {
    let resp: AxiosResponse<ResponseT<PartnerAuthorizationT>> = await Partners.delete(getToken(), partnerId);
    const result = processAxiosResponseElement('deletePartner', resp);
    if (debug) console.log('deletePartner: ', result);
  }

  // create new user authorizations -- used by admin/maintenance
  const createPartnerAuthorization = async (params: { [key: string]: string | string[] }): Promise<PartnerAuthorizationT> => {
    const config: AxiosRequestConfig = params;
    let resp: AxiosResponse<ResponseT<PartnerAuthorizationT>> = await Authorizations.create(getToken(), config);
    const authorizations = processAxiosResponseElement('createAuthorization', resp);
    if (debug) console.log('createPartnerAuthorizations: ', authorizations);
    return authorizations;
  }

  // get all user authorizations -- used by admin/maintenance
  const getAllAuthorizations = async (): Promise<PartnerAuthorizationT[]> => {
    let resp: AxiosResponse<ResponseT<PartnerAuthorizationT[]>> = await Authorizations.all(getToken());
    const authorizations = processAxiosResponseElement('getAllAuthorizations', resp);
    if (debug) console.log('getAllAuthorizations: ', authorizations);
    return authorizations;
  }

  // get specific authorization
  const getPartnerAuthorizationByEmail = async (user: UserT): Promise<PartnerAuthorizationT> => {
    const partnerEmail: string = user?.authEmail ? user.authEmail : '';
    if (partnerEmail === '') return null;
    let resp: AxiosResponse<ResponseT<PartnerAuthorizationT>> = await Authorizations.get(getToken(), partnerEmail);
    const authorization = processAxiosResponseElement('getPartnerAuthorizationBy', resp);
    if (debug) console.log('getPartnerAuthorizationByEmail: ', authorization);
    return authorization;
  }

  // update user authorizations -- used by admin/maintenance
  const updatePartnerAuthorization = async (
    authorizationId: string,
    updates: { [key: string]: string | string[] }
  ): Promise<PartnerAuthorizationT> => {
    let params = updates;
    params['authorization_id'] = authorizationId;
    const config: AxiosRequestConfig = params;
    let resp: AxiosResponse<ResponseT<PartnerAuthorizationT>> = await Authorizations.update(getToken(), config);
    const authorization = processAxiosResponseElement('updateAuthorization', resp);
    if (debug) console.log('updatePartnerAuthorizations: ', authorization);
    return authorization;
  }

  // delete user authorizations -- used by admin/maintenance
  const deletePartnerAuthorization = async (
    authorizationId: string
  ): Promise<void> => {
    let resp: AxiosResponse<ResponseT<PartnerAuthorizationT>> = await Authorizations.delete(getToken(), authorizationId);
    const result = processAxiosResponseElement('deletePartnerAuthorization', resp);
    if (debug) console.log('deletePartnerAuthorizations: ', result);
  }

  const autoAssign = (catalogNumber: string): string => {
    if (!Object.hasOwn(catalogToVendor, catalogNumber)) {
      return '';
    }
    if (typeof (catalogToVendor[catalogNumber]) === 'string') {
      return catalogToVendor[catalogNumber] as string;
    } else {
      return '';
    }
  };

  const getDownloadFileAndPath = (partnerCode: string): { file: string, path: string } => {
    return { file: partnerCode + '.xlsx', path: '/documents/templates/'}
  }

  const getRequestTypeByCatNo = (cat_no: string): string => {
    let requestType = 'screen';
    Object.keys(RequestTypes).forEach((k: string) => {
      if (RequestTypes[k].includes(cat_no)) requestType = k;
    })
    return requestType;
  }

  const getVendorName = (vendorCode: string): string => {
    if (vendorCode === null || vendorCode === '' || !Object.hasOwn(VENDOR_MASK, vendorCode)) return vendorCode;
    return VENDOR_MASK[vendorCode];
  }

  const getVendorOptions = (request: RequestT): {value: string, label: string}[] => {
    const options = [];
    if (!request) return options;
    const catalogNumber = request?.cat_code ? request.cat_code : null;
    if (!catalogNumber) return options;
    if (!Object.hasOwn(catalogToVendor, catalogNumber)) {
      console.error(`Error: ${catalogNumber} does not exist in catalogToVendor`);
      return options;
    }
    const vendors = catalogToVendor[catalogNumber];
    if (debug) console.log('getVendorOptions | ', vendors);
    if (typeof vendors === 'string') {
      options.push({value: vendors, label: VENDOR_MASK[vendors]});
    } else {
      vendors.forEach((vendor) => {
        options.push({value: vendor, label: VENDOR_MASK[vendor]});
      });
    }
    return options;
  }

  return {
    createPartner,
    getAllPartners,
    updatePartner,
    deletePartner,
    autoAssign,
    getRequestTypeByCatNo,
    getVendorName,
    createPartnerAuthorization,
    getPartnerAuthorizationByEmail,
    getDownloadFileAndPath,
    getAllAuthorizations,
    updatePartnerAuthorization,
    deletePartnerAuthorization,
    getVendorOptions
  };
};

export default usePartners;
