import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components/macro';
import useCognito from '@as_core/account/useCognito';
import { Data } from '@utils/api';
import TextButton from '@components/controls/TextButton';
import { useNavigate } from 'react-router-dom';
import GraphIcon from '@components/icons/graph.icon';
import { createDrcPlotFromDataSet } from '@components/dataSets/dataSet.service';
import { UserContext } from '@stores/UserContext';
import usePlots from '../../../plotting/usePlots';
import { DataSetT } from '@components/dataSets/dataSet.types';
interface UploadDoseResponseProps {
  dataSet: DataSetT;
  handleNext: () => void;
  height?: number;
  width?: number;
}
export type EncodedDataSetT = Omit<DataSetT, 'dataFormatted' | 'data'> & {
  dataFormatted: Record<string, string | number | boolean | object>[] | string;
  data: string;
};
const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
const UploadDoseResponse = (props: UploadDoseResponseProps) => {
  const { dataSet, handleNext, height, width } = props;
  const [dataUploaded, setDataUploaded] = useState<boolean>(false);
  const [uuid, setUuid] = useState('');
  const [status, setStatus] = useState<string>('');
  if (debug) console.log('UploadDoseResponse');
  const { user } = useContext(UserContext);
  const { updatePlot } = usePlots();
  const { getToken } = useCognito();
  const navigate = useNavigate();
  const uploadDoseResponseData = async () => {
    if (!dataUploaded) {
      debug &&
        console.log(
          'UploadDoseResponse | uploadDoseResponseData | dataSet:',
          dataSet
        );
      const token = getToken();
      // create plot first so that we can embed the plotId in the dataset
      const drcPlotConfig = createDrcPlotFromDataSet(user.authId, dataSet);
      if (debug) console.log(drcPlotConfig);
      debug &&
        console.log(
          'UploadDoseResponse | uploadDoseResponseData | drcPlotConfig:',
          drcPlotConfig
        );
      let payload: EncodedDataSetT = {
        ...dataSet,
        dataFormatted: dataSet.dataFormatted ?? [],
        data: dataSet.data ?? '',
      };
      payload['data'] = encodeURIComponent(JSON.stringify(dataSet['data']));
      payload['dataFormatted'] = encodeURIComponent(
        JSON.stringify(dataSet['dataFormatted'])
      );
      if (debug) console.log('UploadDoseResponse | payload:', payload);
      setStatus('Uploading Data Set');
      // upload data
      await Data.create(token, payload).then(() => {
        setUuid(payload['uuid']);
      });
      setStatus('Uploading Dose Response Curve Plots');
      // upload plot
      await updatePlot(getToken(), drcPlotConfig).then(() => {});
      setStatus('Upload Complete');
    }
  };
  useEffect(() => {
    uploadDoseResponseData()
      .then(() => {
        setDataUploaded(true);
      })
      .catch((error) => console.error(error));
    handleNext();
  }, []);
  return (
    <Container height={height} width={width}>
      <ControlContainer>
        {!dataUploaded ? (
          <UploadedMessage>
            <Row>
              <Item>{status}</Item>
            </Row>
          </UploadedMessage>
        ) : (
          <UploadedMessage>
            <Row>
              <Item>Successfully Uploaded Data</Item>
            </Row>
            <Row>
              <TextButton
                text='Create Plot'
                onClick={() =>
                  navigate('/plots/create', { state: { dataId: uuid } })
                }
                icon={<GraphIcon size={28} />}
                tooltipPlacement='bottom'
                width={200}
                height={32}
                margin='5px'
              />
              <TextButton
                text={'Data List'}
                onClick={() => navigate('/data_import/list')}
                icon={<GraphIcon size={28} />}
                tooltipPlacement={'bottom'}
                width={200}
                height={32}
                margin={'5px'}
              />
            </Row>
          </UploadedMessage>
        )}
      </ControlContainer>
    </Container>
  );
};
export default UploadDoseResponse;
const Container = styled.div<{ height?: number; width?: number }>`
  display: block;
  position: relative;
  width: 100%;
`;
const ControlContainer = styled.div`
  width: 100%;
`;
const UploadedMessage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;
const Item = styled.div`
  font-size: ${(p) => p.theme.sizes.small};
  display: flex;
`;
