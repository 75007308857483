import React, { useContext } from 'react';
import styled from "styled-components/macro";
import { StyleContext } from '@theme/AppStyles';
import {mapCountsToPositions, positionT} from "../utils/processMaps";

const checkMark = '\u2713'
const notificationPositions: {[key: string]: positionT} = {
  'platemapAvailable' : { x: 25, y: 8},
  'compoundsShipped': { x: 193, y: 8},
  'compoundsReceived': { x: 361, y: 8},
  'assaysRunning': {x: 529, y: 8},
  'dataProcessing': {x: 529, y: 159},
  'dataQC': {x: 361, y: 159},
  'dataDelivered': {x: 193, y: 159},
  'complete': {x: 25, y:159},
  'Open' : { x: -10, y: 55},
  'Platemap Available' : { x: 25, y: 8},
  'Shipped': { x: 193, y: 8},
  'Received': { x: 361, y: 8},
  'Running': {x: 529, y: 8},
  'Loading': {x: 529, y: 159},
  'QC': {x: 361, y: 159},
  'Delivered': {x: 193, y: 159},
  'Complete': {x: 25, y:159}
}

type PropsT = {
  showCounts?: boolean;
  counts: {[key:string]: number}
}

const debug = false;
const AssayProcessMap = (props:PropsT) => {
  const {showCounts = true, counts} = props;
  const [style] = useContext(StyleContext);
  const image = (style.name === 'dark') ? '/img/Request_Process_Images/Assay_Process_dark.svg' : '/img/Request_Process_Images/Assay_Process_light.svg';
  // image details
  const width= 715.37;
  const height = 312.70;
  const scale = 1.0;

  const positions = mapCountsToPositions(counts, notificationPositions);
  if (debug) console.log('counts', counts, 'positions', positions);

  return (
    <MapContainer width={scale*width} height={scale*height}>
      { positions.map((p, index) => (
        <NotificationCountBubble key={`count_bubble_${index}`} x={p.x} y={p.y}>{ showCounts ? p.count : checkMark } </NotificationCountBubble>
      ))}
      <img src={image} alt={'process image map'}/>
    </MapContainer>
  )
}

export default AssayProcessMap;

const MapContainer = styled.div<{width: number, height: number}>`
  display: flex;
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;

const NotificationCountBubble = styled.div<{x:number, y:number}>`
  position: absolute;
  font-size: 16px;
  z-index: 1;
  top: ${(p)=>p.y}px;
  left: ${(p)=>p.x}px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.textPrimary };
  background-color:  ${(props) => props.theme.palette.accentPrimary };
  border: 2px solid ${(p)=>p.theme.palette.backgroundSecondary};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;