import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery } from '@tanstack/react-query';
import ErrorMessages, {MessageItem} from '@as_core/elements/UserMessages';
import { ListLoader } from '@as_core/elements/listStyles';
import useOrders from '@business/orders/useOrders';
import BasicTable from '@as_core/tables/BasicTable';
import ViewOrderDetails from './ViewOrderDetails';
import {OrderTableFields, getOrderTableRows} from '@subApps/orders/utils/orders';
import {Row} from '../common/flexStyles';
import {OrderT} from './types';

interface PropsI {
  orderType: string;
}

const debug = false;
const OrdersList = (props:PropsI) => {
  const {orderType} = props;
  if (debug) console.log('ordersList {orderType}', orderType);
  const { getOrders } = useOrders();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderView, setSelectedOrderView] = useState('success');

  // load the user's current open orders
  let { isPending, isError, error, data: orders } = useQuery({
    queryKey: ['orders', 'open'],
    queryFn: () => getOrders({in_process: orderType !== 'complete'})
  })
  if (isError) console.error(error.message);

  const filterOrders = (orderType: string) => {
    if (isPending) return [];
    return orderType === 'complete' ? orders.filter((o) => o.open === false) : orders.filter((o) => o.open);
  }

  const handleViewClick = (orderId: string) => {
    if (debug) console.log('handleViewClick ', orderId);
    const matched:OrderT = orders.find((o) => o.orderId === orderId);
    if (matched) {
      setSelectedOrder(matched);
      if (matched.status === 'canceled') {
        setSelectedOrderView('canceled');
      } else {
        setSelectedOrderView('success');
      }
    }
  };

  if (debug) console.log('ordersList | isPending: ', isPending, 'orders.length', isPending ? 'NA' : orders.length);
  const filteredOrders = filterOrders(orderType);

  return (
    <>
      { !isPending && filteredOrders.length ?
        <ListContainer>
          { selectedOrder !== null ?
            <ViewOrderDetails
              order={selectedOrder}
              orderMode={selectedOrderView}
              handleBack={()=>setSelectedOrder(null)}
              topBuffer={60}
            />
            :
            <BasicTable
              fields={OrderTableFields}
              rows={getOrderTableRows(filteredOrders, handleViewClick)}
              border={false}
            />
          }
        </ListContainer>
      : isPending ?
          <Row width={'100%'} v_align={'center'} h_align={'center'}>
            <ListLoader />
          </Row>
      :
          <ErrorMessages messages={[
            <MessageItem key='emi_no_orders'>You do not have any {orderType === 'complete' ? ' complete ' : ' open '} orders</MessageItem>
          ]}/>
      }
    </>
  );
};

export default OrdersList;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
