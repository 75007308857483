import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import DataLoading from "@as_core/elements/DataLoading";
import StationaryModal from "@as_core/elements/StationaryModal";
import {RequestT} from "../hooks/useRequests";
import useRequestForms from "../hooks/useRequestForms";
import RequestFormFilesTable from "./RequestFormFilesTable";
import {RequestFormTitle, Row} from '../common/flexStyles';
import RequestFormFilesUpload from "./RequestFormFilesUpload";
import UploadFileButton from '@as_core/controls/inputs/UploadFileButton';

const platemapUploadInfo:string = 'Please upload as many plate map files as needed to accompany the compounds '
  + 'plates you will ship for screening.';

interface PropsI {
  viewMode: string;
  formType: string;
  formName: string;
  request: RequestT;
  setNumberForms: (v:number) => void;
  allowEdit?: boolean;
  border?: boolean;
  height?: string;
}

const debug = false;
const RequestFormFiles = (props: PropsI) => {
  const {viewMode, formType, formName, request, setNumberForms,
    allowEdit=false, border=false, height='max-content'} = props;
  const queryClient = useQueryClient();
  const { getRequestFormsByRequestIdOfType }  = useRequestForms()
  const [areRequestFormsUpdate, setAreRequestFormsUpdate] = useState<boolean>(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState<boolean>(false);
  if (debug) console.log('RequestFormFiles | {viewMode, request.id, formType, formName, allowEdit}:',
      viewMode, request.id, formType, formName, allowEdit);

  // load forms for request.id
  const {isPending, error, data: requestForms} = useQuery({
    queryKey: ['requestForms', request.id, formType],
    queryFn: () =>getRequestFormsByRequestIdOfType(request.id, formType)
  });
  if (error) console.error('RequestFormFiles vis useQuery loading error: ',error.message);

  // update number of forms when requestForms are updated
  useEffect(() => {
    if (requestForms !== undefined) {
      setNumberForms(requestForms.length);
    }
  }, [requestForms]);

  // requestForms updated -- refresh
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['requestForms', request.id] }).then();
  }, [areRequestFormsUpdate]);

  const formsAlreadyUploaded = requestForms !== undefined && requestForms.length > 0;

  const getButtonLabel = () => {
    if (formsAlreadyUploaded) return 'Upload more ' + formName + ' file(s)';
    return 'Upload ' + formName + ' file(s)';
  }

  if (debug) console.log('RequestFormFiles | allowEdit:', allowEdit, ' request:', request.id, ' requestForms:', requestForms);

  return (
    <FormFilesContainer border={border}>
      { formType !== 'plate_map' ?
          <Row h_align={'flex-start'}>
            <RequestFormTitle>Uploaded {formName} File(s):</RequestFormTitle>
          </Row>
        :
          null
      }
      <Row padding={'0'}>
        { isPending ?
          <DataLoading />
        :
        <>
        { requestForms.length > 0 ?
          <ScrollBox height={height}>
            <RequestFormFilesTable
              viewMode={viewMode}
              allowEdit={allowEdit}
              formType={formType}
              formName={formName}
              requestForms = {requestForms}
              setUpdated={() => setAreRequestFormsUpdate(prev => !prev)}
            />
          </ScrollBox>
        :
          null
        }
      </>
      }
      </Row>
      { allowEdit ?
        <>
          <Row width="100%">
            <UploadFileButton
              label = {getButtonLabel()}
              height={32}
              width={270}
              onClick={()=> setIsFileUploadOpen(prevState => !prevState)}
              info={platemapUploadInfo}
              isMuted={formsAlreadyUploaded}
              isDisabled={isFileUploadOpen}
            />
          </Row>
          <StationaryModal
            title={'Upload Your ' + formName + ' File(s)'}
            opacity={0.95}
            modalOpen={isFileUploadOpen}
            height={400}
            onCloseClick={() => setIsFileUploadOpen(false)}
          >
            <RequestFormFilesUpload
              key={'request_user_update_file_upload'}
              formType={formType}
              formName={formName}
              request={request}
              requestForms={requestForms}
              setUpdated={() => setAreRequestFormsUpdate(prev => !prev)}
              handleClose={()=>setIsFileUploadOpen(false)}
            />
          </StationaryModal>
          </>
        : null
      }
    </FormFilesContainer>
  )
};

export default RequestFormFiles;

const FormFilesContainer = styled.div<{border?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 350px;
    border-radius: 15px;
    border: ${(p)=>p.border? '1px' : '0'} solid ${(p) => p.theme.palette.accentSecondary};
`;
FormFilesContainer.defaultProps = {
  border: false,
}

const ScrollBox = styled.div<{height: string}>`
  height: ${(p) => p.height};
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
  }

  &::-webkit-scrollbar-track {
      background: ${(p) => p.theme.palette.backgroundSecondary};
  }

  &::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.palette.accentPrimary};
      border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
      background: ${(p) => p.theme.palette.accentSecondary};
  }
`

