import React from 'react';
import styled from 'styled-components/macro';
import Spinner from '@components/elements/Spinner';

interface ButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  inProcess?: boolean;
  width?: number;
}

const Button = (props: ButtonProps) => {
  return (
    <StyledButton
      key={props.label}
      onClick={props.onClick}
      disabled={props.disabled}
      width={props?.width ? props.width : 200}
    >
      {props?.inProcess ? <Spinner /> : props.label}
    </StyledButton>
  );
};
export default Button;

const StyledButton = styled.div<{ disabled: boolean; width: number }>`
  width: ${(p) => p.width}px;
  height: 28px;
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    p.disabled ? p.theme.palette.textSecondary : p.theme.palette.accentPrimary};
  border: 1px solid
    ${(p) =>
      p.disabled ? p.theme.palette.textPrimary : p.theme.palette.textSecondary};
  border-radius: 10px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  &:hover {
    background-color: ${(p) =>
      p.disabled
        ? p.theme.palette.textSecondary
        : p.theme.palette.accentSecondary};
  }
`;
StyledButton.defaultProps = {
  disabled: false,
};
