import React from "react";
import styled from "styled-components/macro";
import DataSaveIcon from "@as_core/icons/dataSave.icon";
import useRequestForms, {RequestFormT} from "../hooks/useRequestForms";
import TextButton from '@as_core/controls/buttons/TextButton';

type PropsT = {
  requestForm: RequestFormT;
  height?: number;
  width?: number;
};

const debug = false;
const RequestFormFileDownload = (props:PropsT) => {
  const { requestForm, width=300, height=32 } = props;
  const { getRequestFormFile } = useRequestForms();

  const handleClick = async (requestForm: RequestFormT) => {
    if (debug) console.log('PlateMapFileDownload | handleClick', requestForm);
    const response = await getRequestFormFile(requestForm.id);
    if (debug) console.log('PlateMapFileDownload | handleClick | response', response);
    if (response) {
      const link = document.createElement('a');
      const url = URL.createObjectURL(response);
      console.log(url);
      link.href = url;
      link.download = requestForm.file_name;
      link.click();
    }
  }

  return(
    <Container>
      <TextButton
        label={`Download ${requestForm.file_name}`}
        icon={<DataSaveIcon />}
        width={width}
        height={height}
        onClick={()=>handleClick(requestForm)}
      />
    </Container>
  )
}

export default RequestFormFileDownload;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
`