import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { StyleContext } from '@theme/AppStyles';
import { UserContext } from '@stores/UserContext';
import PageContainer from '@as_core/pages/PageContainer';
import HomePageTiles from '@as_core/tiles/HomePageTiles';
import { FlexColumn } from '@as_core/elements/flexStyles';
import UserSubscriptionUpdates from '@business/subscriptions/UserSubscriptionUpdates';
import UserInvitations from '@subApps/account/userLoginAlerts/UserInvitations';
import { getUserSubscriptionType } from '@utils/useUserAppInfo';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgHome = () => {
  if (debug) console.log('PgHome');
  const [style] = useContext(StyleContext);
  const img_url = '/img/background_' + style.name + '.jpg';
  const { user, setUser } = useContext(UserContext);
  const subscriptionLevel = getUserSubscriptionType(user.appInfo);

  // Move the state update logic into a useEffect
  useEffect(() => {
    if (user.subApp !== '3RnD') {
      setUser((prev) => ({
        ...prev,
        subApp: '3RnD',
      }));
    }
  }, [user.subApp]);

  return (
    <PageContainer title='Welcome'>
      <HomeBackground img_url={img_url}>
        <FlexColumn>
          <UserInvitations />
          <UserSubscriptionUpdates />
          <HomePageTiles subscriptionLevel={subscriptionLevel} />
        </FlexColumn>
      </HomeBackground>
    </PageContainer>
  );
};

export const HomeBackground = styled.div<{ img_url: string }>`
  height: 100%;
  width: 100%;
  background-image: url(${(p) => p.img_url});
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

export default PgHome;
