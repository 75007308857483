import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import { DataSetT } from '@components/dataSets/dataSet.types';
import {
  PlotConfig,
  PlotBase,
  DrcBase,
  PlotData,
} from '@subApps/plotting/single-plot-view/plot.types';
import { CompoundDoseResponseT } from '@components/dataSets/dataSet.types';

import { getBasicPlotConfig } from '@plotting/plot-config-modal/build-plot-config';
interface ExcelWorkbook {
  workbook: XLSX.WorkBook;
  filename: string;
}
interface ExcelColumn {
  colName: string;
  colType: string;
  selected: boolean;
  field_id: string;
  data?: unknown[];
}
type ExcelRow = Record<string, string | number | boolean | object>[];
const parseExcelGeneric = (dataXLS: ExcelRow[]): Record<string, string | number | boolean | object>[] => {
  const [headers, ...rows] = dataXLS;
  return rows.filter(row => row && row.length > 0).map(row => {
    return headers.reduce((acc, header, index) => {
      acc[header as unknown as string] = row[index];
      return acc;
    }, {} as Record<string, string | number | boolean | object>);
  });
};
const parseExcelMapped = (dataXLS: ExcelRow[], columns: ExcelColumn[]): Record<string, string | number | boolean | object>[] => {
  return dataXLS.slice(1).filter(row => row && row.length > 0).map(row => {
    let newRow: Record<string, string | number | boolean | object> = {};
    columns.forEach((col, index) => {
      if (col.selected && col.field_id) {
        newRow[col.field_id] = row[index];
      }
    });
    return newRow;
  });
};
const getDrcPlotConfig = (
  userId: string,
  compounds: Array<string>,
  sources: Array<string>,
  dataset: PlotData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => {
  let plotConfig = getBasicPlotConfig(compounds, sources, dataset);
  plotConfig.userId = userId;
  plotConfig.name = 'Dose Response';
  return plotConfig;
};
export const createDrcPlotFromDataSet = (
  userId,
  dataSet: DataSetT
) => {
  console.log('createDrcPlotFromDataSet | dataSet', dataSet);
  let drcPlot: Record<string, string | number | boolean | object> = getDrcPlotConfig(
    userId,
    dataSet.compoundIds,
    dataSet.sourceIds,
    dataSet.data
  );
  console.log('createDrcPlotFromDataSet | drcPlot', drcPlot);
  drcPlot.name = 'Dose Response (' + dataSet.name + ')';
  drcPlot.dataFormatted = dataSet.dataFormatted.map((item) => ({
    ...item,
    data: (item.data || []) as Array<{ dose: unknown; response: unknown }>,
  })) as CompoundDoseResponseT[];
  drcPlot.data_uuid = dataSet.uuid;
  // scatter plotting
  drcPlot.config = {
    base: 'drc',
    authors: [userId],
    groupByColumn: 'sample',
    doseColumn: 'dose',
    responseColumn: 'response_%',
  };
  return drcPlot;
};
const debug = false;
export const defaultDataSet = (userId): DataSetT => {
  return {
    uuid: uuidv4(),
    createdBy: userId,
    name: '',
    type: 'Generic',
    compoundIds: [],
    sourceIds: [],
    data: [] as Array<{ dose: unknown; response: unknown }>,
    columns: [],
    nColumns: 0,
    nRows: 0,
    universeId: '',
  };
};
export const createDoseResponseDataFromExcel = (workbook: ExcelWorkbook, columns: ExcelColumn[]) => {
  let data: Record<string, string | number | boolean | object | []> = {};
  if (debug) console.log('createDoseResponseDataFromExcel | columns:', columns);
  columns.forEach((col) => {
    if (col.field_id !== null) {
      data[col.field_id] = col.data;
    }
  });
  if (debug) console.log('createDoseResponseDataFromExcel | data:', data);
  let doseResponse: Record<string, Record<string, Record<string, string | number | boolean | object>>> = {};
  Array.isArray(data['compound']) && data['compound'].forEach((value, index) => {
    if (value !== undefined) {
      const sampleId = data['sample'][index];
      const dose = data['dose'][index];
      const response = data['response_%'][index];
      let doseUnit = 'uM';
      if (data['dose_units'] && data['dose_units'][index]) {
        doseUnit = data['dose_units'][index];
      }
      if (!(value in doseResponse)) {
        doseResponse[value] = {};
      }
      if (!(sampleId in doseResponse[value])) {
        doseResponse[value][sampleId] = {
          compound_id: value,
          sample_id: sampleId,
          dose_units: doseUnit,
          response_type: '%',
          data: [] as Array<{ dose: unknown; response: unknown }>,
        };
      }
      if (!Array.isArray(doseResponse[value][sampleId]['data'])) {
        doseResponse[value][sampleId]['data'] = [];
      }
      (doseResponse[value][sampleId]['data'] as Array<{ dose: unknown; response: unknown }>).push({
        dose: dose,
        response: response,
      });
    }
  });
  // now set to array
  return Object.values(doseResponse).flatMap((sampleMap) =>
    Object.values(sampleMap).map((sample) => ({
      ...sample,
      data: sample.data || [],
    }))
  );
};
// take a standard JSON data table format and convert to CSV format
export const getCsvDataFromTableData = (data) => {
  let csvRows = [];
  csvRows.push(data.columns.join(','));
  data.rows.forEach((row) => {
    const values = data.columns.map((col) => row[col]);
    csvRows.push(values.join(','));
  });
  return csvRows.join('\n');
};
export function downloadCSV(csvData, filename) {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'data.csv';
  document.body.appendChild(a);
  a.click();
  // Cleanup: remove the dynamically created anchor tag and URL object
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
// extract the curve fit results to a standard JSON data table format
export const getDoseResponseResultsAsTableData = (dataSet) => {
  let dataMapping = [
    {
      parameters: [
        { column: 'IC50/EC50/ED50', value: 'ED50' },
        { column: 'Slope', value: 'slope' },
        { column: 'Lower Bound', value: 'lower' },
        { column: 'Upper Bound', value: 'upper' },
      ],
    },
    {
      statistics: [
        { column: 'Std. Error', value: 'Std. Error' },
        { column: 'Std. Error (upper)', value: 'Upper' },
        { column: 'Std. Error (lower)', value: 'Lower' },
      ],
    },
  ];
  // set the dataColumns
  let dataColumns = ['Compound Name', 'Sample Id', 'Fit Type'];
  dataMapping.forEach((set) => {
    Object.keys(set).forEach((type) => {
      set[type].forEach((item) => {
        if (!dataColumns.includes(item.column)) {
          dataColumns.push(item.column);
        }
      });
    });
  });
  if (debug)
    console.log(
      'getDoseResponseResultsAsTableData | dataColumns:',
      dataColumns
    );
  let dataRows = [];
  dataSet.dataFormatted.forEach((cpd) => {
    Object.keys(cpd.results).forEach((fit) => {
      let newRow = {
        'Compound Name': cpd.compound_id,
        'Sample Id': cpd.sample_id,
        'Fit Type': fit,
      };
      const resultsData = cpd.results[fit];
      dataMapping.forEach((set) => {
        Object.keys(set).forEach((type) => {
          set[type].forEach((item) => {
            newRow[item.column] = _.get(resultsData[type], item.value, '');
          });
        });
      });
      dataRows.push(newRow);
    });
  });
  if (debug)
    console.log('getDoseResponseResultsAsTableData | dataRows:', dataRows);
  return { columns: dataColumns, rows: dataRows };
};
const dataColumnsFromExcel = (columns: ExcelColumn[]) => {
  return columns.map((c) => ({
    label: c.colName,
    value: c.colName,
    type: c.colType,
  }));
};
export const dataMappedFromExcel = (workbook: ExcelWorkbook, columns: ExcelColumn[]) => {
  const sheetName = workbook.workbook.SheetNames[0];
  const dataXLS: ExcelRow[] = XLSX.utils.sheet_to_json(
    workbook.workbook.Sheets[sheetName],
    { header: 1 }
  );
  return parseExcelMapped(dataXLS, columns);
};
export const dataGenericFromExcel = (workbook: ExcelWorkbook) => {
  const sheetName = workbook.workbook.SheetNames[0];
  const dataXLS: ExcelRow[] = XLSX.utils.sheet_to_json(
    workbook.workbook.Sheets[sheetName],
    { header: 1 }
  );
  return parseExcelGeneric(dataXLS);
};
export const createGenericDataSetFromExcel = (
  userId,
  workbook: ExcelWorkbook,
  columns: ExcelColumn[]
): DataSetT => {
  let dataSet = defaultDataSet(userId);
  dataSet.columns = dataColumnsFromExcel(columns);
  dataSet.name = workbook.filename;
  dataSet.nColumns = dataSet.columns.length;
  dataSet.data = dataGenericFromExcel(workbook);
  dataSet.nRows = dataSet.data.length;
  return dataSet;
};
export const createDoseResponseDataSetFromExcel = (
  userId,
  workbook: ExcelWorkbook,
  columns: ExcelColumn[]
): DataSetT => {
  let dataSet = createGenericDataSetFromExcel(userId, workbook, columns);
  dataSet.type = 'Dose Response';
  dataSet.dataFormatted = createDoseResponseDataFromExcel(workbook, columns);
  dataSet.nColumns = dataSet.columns.length;
  dataSet.columns = [
    { label: 'Dose', value: 'dose', type: 'float' },
    { label: 'Response', value: 'response_%', type: 'float' },
    { label: 'Sample ID', value: 'sample', type: 'string' },
    { label: 'Compound ID', value: 'compound', type: 'string' },
  ];
  dataSet.data = dataMappedFromExcel(workbook, columns);
  dataSet.nRows = dataSet.data.length;
  return dataSet;
};
export const createTargetPanelDataSetFromExcel = (
  formatType,
  userId,
  workbook: ExcelWorkbook,
  columns: ExcelColumn[]
): DataSetT => {
  let dataSet = defaultDataSet(userId);
  if (debug)
    console.log('formatType', formatType, 'userId', userId, 'columns', columns);
  dataSet.name = workbook.filename;
  dataSet.type = 'Target Panel';
  let dataColumns = ['compound', 'smiles'];
  const data = dataMappedFromExcel(workbook, columns);
  if (formatType === 'narrow') {
    // need to combine on 'compound'
    let pivotedData = {};
    let targetColumns = [];
    data.forEach((row) => {
      const compound_id = row['compound'].toString(); // treat as string in case these are just serial #'s
      if (!Object.hasOwnProperty.call(pivotedData, compound_id)) {
        pivotedData[compound_id] = {
          compound: compound_id,
          smiles: row['smiles'],
        };
      }
      pivotedData[compound_id][row['target']] = row['assay'];
      if (!targetColumns.includes(row['target'])) targetColumns.push(row['target']);
    });
    if (debug) console.log('pivotedData', pivotedData);
    targetColumns.sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    dataColumns = dataColumns.concat(targetColumns);
    dataSet.data = Object.values(pivotedData);
  } else {
    dataSet.data = data;
    let targetColumns = [];
    dataSet.data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (!['compound', 'smiles'].includes(key) && !targetColumns.includes(key)) {
          targetColumns.push(key);
        }
      });
    });
    targetColumns.sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    dataColumns = dataColumns.concat(targetColumns);
  }
  let dataSetColumns = dataColumns.map((col) => ({
    label: col,
    value: col,
    type: ['compound', 'smiles'].includes(col) ? 'string' : 'float',
  }));
  dataSet.columns = dataSetColumns;
  dataSet.nColumns = dataColumns.length;
  dataSet.nRows = dataSet.data.length;
  return dataSet;
};






