import React, { useState } from 'react';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import ErrorMessages, {MessageItem} from '@as_core/elements/UserMessages';
import { RequestFormsContainer, RequestFormsHeader, Column, Row, HorizontalLine, Title } from '../common/flexStyles';
import {RequestT, RequestUpdateT} from '../hooks/useRequests';
import RequestFormFiles from '../files/RequestFormFiles';
import ShippingInfoDetails from './ShippingInfoDetails';
import useRequestStatus, { RequestStatusT } from '../hooks/useRequestStatus';
import PlateMapTemplate from './PlateMapTemplate';
import ToDoItem from '@as_core/elements/ToDoItem';
import { FlexColumn, FlexRow } from '@as_core/elements/flexStyles';
import styled from 'styled-components/macro';

const UserRequestInfo:string = 'For this request to be fulfilled by AsedaSciences and its partners '
 + 'you must perform the actions in the Steps To Complete list.'

function getNumberToComplete(status: RequestStatusT, steps: string[]) {
  let completed = 0
  steps.forEach(step => {
    if (Object.hasOwn(status.status, step) && status.status[step].complete) completed++;
  })
  return steps.length - completed;
}

function getStepsToCompleteLabel(numberStepsToComplete: number): string {
  if (numberStepsToComplete === 0) return 'All Steps Complete';
  if (numberStepsToComplete === 1) return '1 Step to Complete';
  return `${numberStepsToComplete} Steps to Complete`;
}

interface PropsI {
  viewMode: string;
  request: RequestT;
  status: RequestStatusT;
  allowEdit?: boolean;
  updateSelectedRequest: (id: string, updates: RequestUpdateT) => Promise<void>;
}

const debug = false;
const AssayRequestForms = (props: PropsI) => {
  const {viewMode, request, status, allowEdit=false, updateSelectedRequest} = props;
  const [isTrackingAvailable, setIsTrackingAvailable] = useState<boolean>(false);
  const [numberPlateMaps, setNumberPlateMaps] = useState<number>(0);
  const { isStageCompleted } = useRequestStatus();
  if (debug) console.log('AssayRequestForms | {viewMode, request.id, allowEdit, status}:',
      viewMode, request.id, allowEdit, status);

  function getHeader() {
    return viewMode === 'partners' ? 'Customer Supplied Information' : 'Customer Actions';
  }

  const numberStepsToComplete = getNumberToComplete(status, ['platemapTemplateDownloaded',
    'platemapAvailable', 'compoundsShipped']);
  if (debug) console.log('numberStepsToComplete', numberStepsToComplete);

  if (debug) console.log('AssayRequestForms | request.id:', request.id,
    'numberPlateMaps:', numberPlateMaps, 'status', status);

  return (
    <RequestFormsContainer>
      <RequestFormsHeader>{getHeader()} {viewMode === 'user' && request.status === 'Open' ?
          <HoverInfoText text={UserRequestInfo}/> : null }</RequestFormsHeader>
      { viewMode === 'user' ?
        <Column width={'100%'}>
          { request.assigned_to && request.assigned_to !== '' ?
          <CustomerActionsContainer>
            <FlexColumn width={'100%'}>
              <FlexRow width={'100%'} h_centered>
                <Title size={'large'} color={'primary'}>{getStepsToCompleteLabel(numberStepsToComplete)}</Title>
              </FlexRow>
              <ToDoItem
                label={'Download Plate Map'}
                completed={isStageCompleted(status,'platemapTemplateDownloaded')}
              >
                {!isStageCompleted(status,'compoundsShipped') ?
                  <PlateMapTemplate
                    request={request}
                    isMuted={isStageCompleted(status,'platemapTemplateDownloaded')}
                  />
                  : null
                }
              </ToDoItem>
            </FlexColumn>
            <FlexColumn width={'100%'}>
              <ToDoItem
              label={'Upload Your Plate Map(s)'}
              completed={isStageCompleted(status,'platemapAvailable') && numberPlateMaps > 0}
              >
                <RequestFormFiles
                  viewMode={viewMode}
                  formType='plate_map'
                  formName='Plate Map'
                  request={request}
                  setNumberForms={setNumberPlateMaps}
                  allowEdit={viewMode === 'user' && !isStageCompleted(status, 'compoundsShipped')}
                />
              </ToDoItem>
            </FlexColumn>
            <FlexColumn width={'100%'}>
              <ToDoItem
              label={'Confirm Shipper & Details'}
              completed={isStageCompleted(status,'compoundsShipped')}
              >
                <ShippingInfoDetails
                  viewMode={viewMode}
                  active={isStageCompleted(status, 'platemapAvailable') && allowEdit}
                  request={request}
                  updateSelectedRequest={updateSelectedRequest}
                  setIsTrackingAvailable={setIsTrackingAvailable}
                />
              </ToDoItem>
            </FlexColumn>
          </CustomerActionsContainer>
          :
          <Row>
            <ErrorMessages border={false} messages={[
              <MessageItem key='emi_no_action'>No action needed at this time.</MessageItem>,
              <MessageItem key='emi_processing'>Order still being processed to assign to laboratory.</MessageItem>
            ]}/>
          </Row>
          }
        </Column>
      :
        <Column width={'100%'} h_align={'center'}>
          <RequestFormFiles
            viewMode={viewMode}
            formType='plate_map'
            formName='Plate Map'
            request={request}
            setNumberForms={setNumberPlateMaps}
          />
          { isTrackingAvailable !== null && isTrackingAvailable ? <HorizontalLine /> : null }
          <ShippingInfoDetails
            viewMode={viewMode}
            active={isStageCompleted(status, 'shipping') && allowEdit}
            request={request}
            updateSelectedRequest={updateSelectedRequest}
            setIsTrackingAvailable={setIsTrackingAvailable}
          />
        </Column>
      }
    </RequestFormsContainer>
  );
};

export default AssayRequestForms;

const CustomerActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`
