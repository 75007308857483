import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@stores/UserContext';
import { OrderT } from '@subApps/orders/orders/types';
import useOrders, { OrderUpdatesT } from '@business/orders/useOrders';
import ViewOrderDetails from '@subApps/orders/orders/ViewOrderDetails';
import DataLoading from '@as_core/elements/DataLoading';
import useRequests, { RequestT } from '@subApps/orders/hooks/useRequests';
import usePartners from '@subApps/orders/hooks/usePartners';

const debug = false;
const OrdersStripe = () => {
  const { user } = useContext(UserContext);
  const params = new URLSearchParams(window.location.search);
  const { getOrder, updateOrder } = useOrders();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [numberRequests, setNumberRequests] = useState<number | null>(null);
  const canceled = params.has('canceled');
  const success = params.has('success');
  const order_id = params.get('order_id');
  const { createRequest } = useRequests();
  const { autoAssign } = usePartners();
  if (debug)
    console.log(
      'OrdersStripe | START | success:',
      success,
      'canceled:',
      canceled,
      'order_id:',
      order_id
    );

  // load order specified by Stripe return URL
  const {
    isPending,
    isError,
    error,
    data: order,
  } = useQuery({
    queryKey: ['order', order_id],
    queryFn: () => getOrder(order_id),
  });
  if (isError) console.error(error.message);

  // function to create all the requests related to the order line items
  const createRequests = async (newOrder: OrderT): Promise<RequestT[]> => {
    const promises = Object.keys(newOrder.order).map(
      async (cat_no: string, index): Promise<RequestT> => {
        // create the request - capture
        const lineItem = newOrder.order[cat_no];
        if (debug) console.log('OrdersStripe | key:', cat_no, order);
        const newRequest = {
          name: newOrder.name + ' request ' + index + 1,
          assigned_to: autoAssign(cat_no),
          user_id: user.authId,
          org_id: user.appInfo.repositories.current.uuid,
          order_id: newOrder.orderId,
          request_category: lineItem?.product?.metadata?.requestCategory,
          request_type:lineItem?.product?.metadata?.requestType,
          cat_code: cat_no,
          prod_name: lineItem.product.productName,
          quantity: lineItem.quantity,
          unit_value: lineItem?.product?.metadata?.unitSize,
          status: 'Open',
        };
        // handle the 3-month companion service
        if (cat_no === 'AS-SCREEN-3RND') {
          newRequest['status'] = 'Delivered';
        }
        if (debug) console.log('OrdersStripe | newRequest', newRequest);
        return createRequest(newRequest);
      }
    );
    const requests: RequestT[] = await Promise.all(promises);
    if (debug) console.log('requests ', requests);
    return requests;
  };

  const mutationRequests = useMutation({
    mutationFn: () => createRequests(order),
    onSuccess: (requests) => {
      if (debug)
        console.log(
          'OrdersStripe | mutation | OnSuccess | requests:',
          requests
        );
      if (requests.length !== Object.keys(order.order).length) {
        console.error(
          `For order ${order_id} - the set of requests created ${
            requests.length
          } does not match order line items ${Object.keys(order.order).length}`
        );
      }
      setInProcess(false);
      queryClient
        .invalidateQueries({ queryKey: ['requests', 'order', order_id] })
        .then();
    },
  });

  const mutationOrder = useMutation({
    mutationFn: (updates: OrderUpdatesT) => updateOrder(updates),
    onSuccess: () => {
      if (debug) console.log('OrdersStripe | mutationOrder | OnSuccess ');
      queryClient.invalidateQueries({ queryKey: ['order', order_id] }).then();
      queryClient.invalidateQueries({ queryKey: ['order', 'open'] }).then();
    },
  });

  // Step 1: Update order to Stripe success/failure
  useEffect(() => {
    if (order !== undefined) {
      const newStatus = success ? 'success' : 'canceled';
      console.log('OrdersStripe | START | success:', order.status, newStatus);
      if (order.status !== newStatus) {
        const updates = { orderId: order_id, status: newStatus, open: success };
        mutationOrder.mutate(updates);
      }
    }
  }, [isPending, order]);

  // Step 2: Create Requests for order -- make sure don't create multiple times in case user reloads data.
  useEffect(() => {
    if (order !== undefined && success) {
      // make sure only create once order loaded and success
      if (
        !inProcess &&
        numberRequests !== null &&
        numberRequests < Object.keys(order.order).length
      ) {
        console.log('creatingRequests', numberRequests);
        // TODO - Need to fix in API so that the api confirms there is not already a request for the order line item before creating
        setInProcess(true);
        mutationRequests.mutate();
      }
    }
  }, [order, numberRequests]);

  console.log(
    'OrdersStripe | RENDER | isPending',
    isPending,
    'order_id',
    order_id,
    'inProcess',
    inProcess,
    'numberRequests',
    numberRequests
  );

  return (
    <OrdersStripeContainer>
      {isPending ? (
        <DataLoading />
      ) : (
        <>
          <StatusMessageContainer>
            <StatusMessage>
              {success ? 'Successfully created order' : 'Order Canceled'}
            </StatusMessage>
          </StatusMessageContainer>
          {success && order !== undefined ? (
            <ViewOrderDetails
              order={order}
              orderMode={canceled ? 'canceled' : 'success'}
              handleBack={() => navigate('/orders/active')}
              topBuffer={150}
              setNumberRequests={setNumberRequests}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </OrdersStripeContainer>
  );
};

export default OrdersStripe;

const OrdersStripeContainer = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
`;

const StatusMessage = styled.div`
  font-size: ${(p) => p.theme.sizes.small};
  font-style: italic;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;
