import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import useCompounds from '@components/compounds/useCompounds';
import CompoundHeatMap from '@components/compounds/view/CompoundHeatMap';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import { FieldSliceT } from '@stores/fields';
import ToTableViewButton from '@pages/dashboard/ToTableViewButton';
import { useNavigate } from 'react-router-dom';

export type PropTypeHeatmap = {
  cellSize?: number;
};

const TitledHeatMapDemo2 = ({ cellSize = 20 }: PropTypeHeatmap) => {
  const [compoundData, setCompoundData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { getCompoundsFiltered } = useCompounds();
  const navigate = useNavigate();

  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );

  useEffect(() => {
    if (!dataLoaded) {
      const filters = [
        { type: 'logical', field: 'zf_mort', comparator: 'exists' },
      ];
      getCompoundsFiltered(filters, { library: 'aseda', limit: 8 }).then(
        (result) => {
          setCompoundData(result.data);
          setDataLoaded(true);
        }
      );
    }
  }, []);

  if (compoundData.length) {
    return (
      <DashboardHeatmapContainer>
        <ToTableViewButton
          text={'View full heatmap'}
          tooltip={'View all tested compounds in heatmap table'}
          tooltipPlacement={'bottom'}
          onClick={() => navigate('/compounds/aseda/heatmap?from=dashboard')}
        />
        <CompoundHeatMap
          data={compoundData}
          cellSize={cellSize}
          scroll={false}
          xScroll={false}
          pinnedData={[]}
          nameWidth={100}
          allFields={allFields}
          loadingMore={false}
        />
      </DashboardHeatmapContainer>
    );
  } else {
    return (
      <DashboardHeatmapContainer>
        <ErrorMessage>Error retrieving data</ErrorMessage>
      </DashboardHeatmapContainer>
    );
  }
};

export default TitledHeatMapDemo2;

const DashboardHeatmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: max-content;
  margin-left: -20px;
`;

const ErrorMessage = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.large};
  color: ${(p) => p.theme.palette.textSecondary};
`;
