import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}


const CartIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M21.7031 9.22929C21.439 8.89796 21.0428 8.70788 20.616 8.70788H16.7643L14.6756 3.92534C14.5461
          3.62882 14.2007 3.49331 13.9042 3.62288C13.6076 3.75238 13.4722 4.0978 13.6017 4.39437L15.4855
          8.70792H8.51449L10.3983 4.39437C10.5278 4.0978 10.3924 3.75241 10.0958 3.62288C9.7993 3.49331
          9.45387 3.62874 9.32438 3.92534L7.23574 8.70792H3.38403C2.95723 8.70792 2.56102 8.89796 2.29692
          9.22933C2.03766 9.55464 1.94199 9.97284 2.03446 10.3768L4.09137 19.3612C4.235 19.9885 4.78996
          20.4267 5.44094 20.4267H18.5591C19.21 20.4267 19.765 19.9885 19.9086 19.3612L21.9655 10.3768C22.058
          9.9728 21.9623 9.5546 21.7031 9.22929ZM18.5591 19.2548H5.44094C5.34145 19.2548 5.2543 19.1895
          5.23371 19.0996L3.1768 10.1153C3.16067 10.0448 3.18774 9.99175 3.21336 9.95968C3.23711 9.92984
          3.2909 9.8798 3.38403 9.8798H6.72399L6.57051 10.2312C6.44102 10.5278 6.57641 10.8732 6.87297
          11.0027C6.9493 11.0361 7.02883 11.0519 7.10715 11.0519C7.33301 11.0519 7.54824 10.9205 7.64442
          10.7003L8.00274 9.87988H15.9973L16.3557 10.7003C16.4518 10.9205 16.6671 11.0519 16.8929
          11.0519C16.9712 11.0519 17.0508 11.0361 17.1271 11.0027C17.4237 10.8732 17.5591 10.5278
          17.4296 10.2312L17.2761 9.8798H20.6161C20.7092 9.8798 20.763 9.92984 20.7867 9.95968C20.8123
          9.99179 20.8394 10.0448 20.8233 10.1153L18.7664 19.0996C18.7457 19.1895 18.6586 19.2548
          18.5591 19.2548Z" fill={color}/>
          <path d="M8.48438 12.4185C8.16078 12.4185 7.89844 12.6808 7.89844 13.0044V17.3013C7.89844 17.6249
          8.16078 17.8872 8.48438 17.8872C8.80797 17.8872 9.07031 17.6249 9.07031 17.3013V13.0044C9.07031
          12.6808 8.80801 12.4185 8.48438 12.4185Z" fill={colorHighlight}/>
          <path d="M12 12.4185C11.6764 12.4185 11.4141 12.6808 11.4141 13.0044V17.3013C11.4141 17.6249
          11.6764 17.8872 12 17.8872C12.3236 17.8872 12.5859 17.6249 12.5859 17.3013V13.0044C12.5859
          12.6808 12.3236 12.4185 12 12.4185Z" fill={colorHighlight}/>
          <path d="M15.5156 12.4185C15.192 12.4185 14.9297 12.6808 14.9297 13.0044V17.3013C14.9297
          17.6249 15.192 17.8872 15.5156 17.8872C15.8392 17.8872 16.1016 17.6249 16.1016
          17.3013V13.0044C16.1015 12.6808 15.8392 12.4185 15.5156 12.4185Z" fill={colorHighlight}/>
        </g>
      </svg>
    </>
  )
}

export default CartIcon;