import React from 'react';
import EditIcon from "@as_core/icons/edit.icon";
import MenuButton from "@as_core/elements/MenuButton";
import { Row, Column } from '../common/flexStyles';
import RequestDetailsTile from '../common/RequestDetailsTile';
import RequestProcessDetails from '../common/RequestProcessDetails';
import AssayRequestForms from '../assay/AssayRequestForms';
import ConsultingRequestForms from '../consulting/ConsultingRequestForms';
import AssayDataForms from './AssayDataForms';
import useRequests, { RequestT, RequestUpdateT } from '../hooks/useRequests';
import {UserEmailDictionaryT} from '../hooks/useUserEmails';
import useRequestStatus from '../hooks/useRequestStatus';
import {useQuery} from "@tanstack/react-query";

interface PropsI {
  allowEdit?: boolean;
  request: RequestT;
  userEmails: UserEmailDictionaryT;
  handleRequestUpdate: (id: string, updates: RequestUpdateT) => Promise<void>;
  handleEdit: () => void;
  handleBack?: () => void;
}

const debug = false;
const RequestDetails = (props: PropsI) => {
  const {request, allowEdit, userEmails, handleRequestUpdate, handleEdit} = props;
  const { getRequestType } = useRequests();
  const { getRequestStatus } = useRequestStatus();
  const requestType = getRequestType(request).category;

  // load the current status
  let { isError, error, data: status } = useQuery({
    queryKey: ['requestStatus', request.id],
    queryFn: () => getRequestStatus(request),
  })
  if (isError) console.error(error.message);

  if (debug) console.log('partners/RequestDetails | {request.id, requestType, status}:',
      request.id, requestType, status);

  return (
    <>
      <Row v_align={'flex-start'}>
        <Column width={'420px'}>
          { allowEdit && (
            <Row>
              <MenuButton
                onClick={() => handleEdit()}
                icon={<EditIcon/>}
                text={'Update Request'}
                tooltipPlacement={'bottom'}
              />
            </Row>
          )}
          <RequestDetailsTile
            viewMode={'partners'}
            request={request}
            userEmails={userEmails}
          />
          { requestType === 'Subscription' ?
            <></>
            : requestType === 'Consulting' ?
            <ConsultingRequestForms
              viewMode='partners'
              request={request}
              status={status}
              updateSelectedRequest={handleRequestUpdate}
            />
            :
            <>
              <AssayRequestForms
                viewMode='partners'
                request={request}
                status={status}
                updateSelectedRequest={handleRequestUpdate}
              />
              <AssayDataForms
                viewMode='partners'
                request={request}
              />
            </>
          }
        </Column>
        { requestType === 'Subscription' ? <></>
        :
        <Column width={'720px'}>
          <RequestProcessDetails
          view={'partners'}
          request={request}
          />
        </Column>
        }
      </Row>
    </>
  );
};

export default RequestDetails;
